import { Component, OnInit, Inject, Input, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ToastyService } from 'ng2-toasty';
import { BackendApiService } from 'src/app/services/backend-api/backend-api.service';
import { UtilService } from 'src/app/services/util/util.service';

import * as XLSX from 'xlsx';
import { element } from 'protractor';
import { query } from '@angular/animations';
import { AlertConfirmationComponent } from '../../alert-confirmation/alert-confirmation.component';
import moment from 'moment';

type AOA = any[][];

@Component({
  selector: 'app-realignment-data',
  templateUrl: './realignment-data.component.html',
  styleUrls: ['./realignment-data.component.scss']
})
export class RealignmentDataComponent implements OnInit, AfterViewInit {

  tableData;
  selectedTable;
  selectedRegion;
  ipn;
  selectedFileName;
  listCriteria;
  listData;
  fileData;
  file: File;

  criteriaValues = {
    "selectedCategory": "Tous",
    "filterCategory": [], "displayGroupingForm": false, "groupingSelectedList": [], "searchText": undefined,
    "filterPrivateCategory": [], "mnuOpen": false, "selectedGroupItemValue": undefined, "selectedCharacterList": undefined, "blnGroupingEdit": undefined,
    "categoryList": [], "displayCriteriaList": [], "criteriaList": [], "criteriaListOringinal": [], "blnMoveRight": false, "GCOName": undefined,
    "splitChar": "§", "lang": 'fr', "selectedItemValue": undefined, "selectedItemType4Color": undefined, "selectedItemType": undefined, "selectedItemSize": undefined
  }

  todo = ['Get to work', 'Pick up groceries', 'Go home', 'Fall asleep'];
  selectedCriteriaListItem: any;
  selectedDataListItem: any;
  uploadData: AOA;
  newData: any;
  selectedFile: string;
  saveData: any;

  constructor(public dialogRef: MatDialogRef<RealignmentDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, readonly apiService: BackendApiService, readonly utilService: UtilService,
    readonly toastyService: ToastyService, public dialog: MatDialog) { }

  ngOnInit() {
    this.selectedRegion = this.data.region;
    this.ipn = this.data.ipn;
    this.getTableDetails();
    // this.listCriteria =this.data.criterialist;
    // console.log("checkcriteria",this.listCriteria);
    // this.listData = this.data.datalist;
    // console.log("checkdata",this.listData);
    this.listCriteria = [];
    this.listData = [];
    this.criteriaValues.lang = sessionStorage.getItem("selectedLang");
  }

  ngAfterViewInit() {

  }

  getFavoriteRegion() {
    if (this.selectedRegion === "Europe") {
      return "PIL";
    } else {
      return "PIM";
    }
  }

  getLangIndex(lang) {
    if (lang === 'en') {
      return 1;
    } else {
      return 0;
    }
  }

  dropCriteria(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  dropData(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  getTableDetails() {
    this.tableData = undefined;

    const queryParam = { "input": `MAJ@${this.ipn}@@@0@11@`, "region": this.getFavoriteRegion() };
    this.apiService.getChoiceTableDetails(queryParam).subscribe(res => {
      if (res.lists) {
        if (res.noOfLists > 0) {
          this.getTableNamesList(res);
        }
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
        console.log("error", error.message);
      });
  }

  getTableNamesList(res) {
    const queryParam1 = { "input": `TABGEN@${this.ipn}@@@0@${res.noOfLists}@`, "region": this.getFavoriteRegion() };
    this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
      let tabData = [];
      tabData = res1.lists;
      if (tabData) {
        this.tableData = [];
        let i = 0;
        for (const item of tabData) {
          const td = res.lists.filter(a => String(a.table).toLocaleUpperCase() === String(item).toLocaleUpperCase());
          if (td.length > 0) {
            this.tableData.push({ "name": String(item).toLocaleUpperCase(), "fileRecvdDate": td[0].fileRecvdDate, "fileUpdtdDate": td[0].fileUpdtdDate })
          }
          i = i + 1;
        }
        this.tableData = this.tableData.sort();
        this.selectedTable = this.tableData[0].name;
        this.onSelectedTable();
        console.log("tableData", this.tableData)
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
        console.log("error", error.message);
      });
  }

  onSelectedTable() {
    this.getFilterCategoryDetails();
    this.getPrivateFilterCategoryDetails();
    setTimeout(() => {
      this.criteriaValues.selectedCategory = this.utilService.getTranslateName(`COMMON.ALL`);
    }, 0);

    this.loadCriteriaListDetails();
  }

  onFileSelected(event) {
    this.selectedFileName = undefined;
    if (event.target.files.length > 0) {
      this.selectedFileName = event.target.files[0].name;
      console.log(event.target.files[0].name);
      this.file = event.target.files[0];
      // this.selectedFile = this.selectedFileName + '&' + new Date().getTime();
      const CurrentDate = moment().format("DDMMYYHHmmss");
      this.selectedFile = this.selectedFileName + '&' + CurrentDate;
      console.log("select", this.selectedFile);


      /* wire up file reader */
      const target: DataTransfer = <DataTransfer>(event.target);
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        this.uploadData = undefined;
        this.uploadData = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
        console.log("excel", this.uploadData);
      };
      reader.readAsBinaryString(target.files[0]);
    }
  }

  openHelpFile() {
    window.open('https://grouperenault.sharepoint.com/sites/Doc_Pilotage/en-US/Pages/Aide%20en%20ligne/maj_libelle.aspx', '_blank');
  }

  clickClose(): void {
    this.dialogRef.close();
  }

  getFilterCategoryDetails() {
    this.criteriaValues.filterCategory = [];
    const queryParam = { "input": `FILTRE@system@${this.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(res => {
      if (res.noObjects > 0) {
        const queryParam1 = { "input": `FILTRE@system@${this.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
          this.criteriaValues.filterCategory = res1.lists;
          this.criteriaValues.filterCategory = this.criteriaValues.filterCategory.sort(this.utilService.sortArray);
        },
          error => {
            this.toastyService.error(this.utilService.getTranslateName(`ERROR.TECHNICAL_ERROR`));
            console.log("error", error.message);
          });
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TECHNICAL_ERROR`));
        console.log("error", error.message);
      });
  }

  getPrivateFilterCategoryDetails() {
    this.criteriaValues.filterPrivateCategory = undefined;
    const queryParam = { "input": `FILTRE@${this.ipn}@${this.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(res => {
      if (res.noObjects > 0) {
        const queryParam1 = { "input": `FILTRE@${this.ipn}@${this.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
          this.criteriaValues.filterPrivateCategory = res1.lists;
          this.criteriaValues.filterPrivateCategory = this.criteriaValues.filterPrivateCategory.sort(this.utilService.sortArray)
        },
          error => {
            this.toastyService.error(this.utilService.getTranslateName(`ERROR.TECHNICAL_ERROR`));
            console.log("error", error.message);
          });
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TECHNICAL_ERROR`));
        console.log("error", error.message);
      });

  }

  onCategoryChange(val, type) {
    this.criteriaValues.selectedCategory = val;
    if (type === 'private') {
      this.getSearchCategory(this.ipn);
    } else {
      this.getSearchCategory('system');
    }
  }


  getSearchCategory(ipnValue) {
    this.criteriaValues.categoryList = [];
    this.criteriaValues.displayCriteriaList = [];
    this.criteriaValues.criteriaList = [];
    if (this.criteriaValues.selectedCategory === 'Tous' || this.criteriaValues.selectedCategory === 'All') {
      for (const item of this.criteriaValues.criteriaListOringinal) {
        // if (typeof (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]) !== 'undefined') {
        //   this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)])
        //   this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)])
        // }

        if (typeof (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]) === 'undefined') {
          item.longLabel = `${item.longLabel}${this.criteriaValues.splitChar}${item.longLabel}`;

          // if (this.isShowHiddenList) {
          //   this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
          //   this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
          // } else {
          // if (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)][0] !== '_') {
          this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
          this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
          // }
          // }

          // this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
          // this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
        } else {
          // this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
          // this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);

          // if (this.isShowHiddenList) {
          //   this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
          //   this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
          // } else {
          // if (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)][0] !== '_') {
          this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
          this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
          // }
          // }
        }

      }
      this.criteriaValues.displayCriteriaList = this.criteriaValues.displayCriteriaList.sort(this.utilService.sortArray);
    } else {
      const queryParam = { "input": `FILTRE@${ipnValue}@${this.selectedTable}@${this.criteriaValues.selectedCategory}@`, "region": this.getFavoriteRegion() };
      this.apiService.getObjectGet(queryParam).subscribe(res => {

        const dataRes = res.data.split('@');
        for (let i = 0; i < dataRes.length; i = i + 4) {
          if (i > 0) {
            this.criteriaValues.categoryList.push(dataRes[i - 1]);
          }
        }

        for (const iterator of this.criteriaValues.categoryList) {
          const filterVal = this.criteriaValues.criteriaListOringinal.filter(a => a.GCOName.toUpperCase() === iterator.substring(0, iterator.length - 2).toUpperCase() && a.typeOfField == iterator.slice(-1));
          for (const item of filterVal) {
            // if (typeof (iterator.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]) !== 'undefined') {
            //   this.criteriaValues.displayCriteriaList.push(iterator.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
            //   this.criteriaValues.criteriaList.push(iterator.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
            // }

            if (typeof (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]) === 'undefined') {
              item.longLabel = `${item.longLabel}${this.criteriaValues.splitChar}${item.longLabel}`;

              // if (this.isShowHiddenList) {
              //   this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              //   this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              // } else {
              // if (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)][0] !== '_') {
              this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              // }
              // }

              // this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              // this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
            } else {
              // this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              // this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);

              // if (this.isShowHiddenList) {
              //   this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              //   this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              // } else {
              // if (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)][0] !== '_') {
              this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              // }
              // }
            }


          }
        }
        this.criteriaValues.displayCriteriaList = this.criteriaValues.displayCriteriaList.sort(this.utilService.sortArray);

      },
        error => {
          this.toastyService.error(this.utilService.getTranslateName(`ERROR.SEARCH_LIST`));
          console.log("error", error.message);
        });
    }
  }


  loadCriteriaListDetails() {
    this.criteriaValues.displayCriteriaList = [];
    this.criteriaValues.criteriaList = [];
    this.criteriaValues.criteriaListOringinal = [];
    const queryParam = { "input": `CHAMP ETENDU@${this.ipn}@${this.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(res => {
      if (res.noObjects > 0) {
        const queryParam1 = { "input": `CHAMP ETENDU@${this.ipn}@${this.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
          this.criteriaValues.criteriaListOringinal = res1.lists;



          if (this.data.criterialist) {
            for (const item of this.data.criterialist) {
              const criteriaFilterVal = this.criteriaValues.criteriaListOringinal.filter(a => a.longLabel.split(this.criteriaValues.splitChar, 2).length > 1 ? a.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)] === item : a.longLabel === item)[0];
              // // console.log("criteriaFilterVal", criteriaFilterVal);
              // this.criteriaValues.selectedItemValue = criteriaFilterVal.longLabel.split(this.criteriaValues.splitChar, 2).length > 1 ? criteriaFilterVal.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)] : criteriaFilterVal.longLabel;
              // this.criteriaValues.selectedItemSize = criteriaFilterVal.size;
              // this.criteriaValues.selectedItemType4Color = this.criteriaValues.criteriaListOringinal.filter(a => a.longLabel.split(this.criteriaValues.splitChar, 2).length > 1 ? a.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)] === item : a.longLabel === item)[0].typeOfField;

              this.listCriteria.push({
                name: criteriaFilterVal.longLabel.split(this.criteriaValues.splitChar, 2).length > 1 ? criteriaFilterVal.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)] : criteriaFilterVal.longLabel,
                size: criteriaFilterVal.size,
                type: criteriaFilterVal.typeOfField,
                GCOName: criteriaFilterVal.GCOName
              });
            }
          }

          if (this.data.datalist) {
            for (const item of this.data.datalist) {
              const criteriaFilterVal = this.criteriaValues.criteriaListOringinal.filter(a => a.longLabel.split(this.criteriaValues.splitChar, 2).length > 1 ? a.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)] === item : a.longLabel === item)[0];
              // // console.log("criteriaFilterVal", criteriaFilterVal);
              // this.criteriaValues.selectedItemValue = criteriaFilterVal.longLabel.split(this.criteriaValues.splitChar, 2).length > 1 ? criteriaFilterVal.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)] : criteriaFilterVal.longLabel;
              // this.criteriaValues.selectedItemSize = criteriaFilterVal.size;
              // this.criteriaValues.selectedItemType4Color = this.criteriaValues.criteriaListOringinal.filter(a => a.longLabel.split(this.criteriaValues.splitChar, 2).length > 1 ? a.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)] === item : a.longLabel === item)[0].typeOfField;

              this.listData.push({
                name: criteriaFilterVal.longLabel.split(this.criteriaValues.splitChar, 2).length > 1 ? criteriaFilterVal.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)] : criteriaFilterVal.longLabel,
                size: criteriaFilterVal.size,
                type: criteriaFilterVal.typeOfField,
                GCOName: criteriaFilterVal.GCOName
              });
            }
          }



          for (const item of this.criteriaValues.criteriaListOringinal) {
            if (typeof (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]) === 'undefined') {
              item.longLabel = `${item.longLabel}${this.criteriaValues.splitChar}${item.longLabel}`;

              // if (this.isShowHiddenList) {
              //   this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              //   this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              // } else {
              // if (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)][0] !== '_') {
              if (item.GCOName === "I" || item.GCOName === "A_Chassis" || item.GCOName === "A_Ident") {
              } else {
                this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
                this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              }
              // }
              // }

              // this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              // this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
            } else {
              // this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              // this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);

              // if (this.isShowHiddenList) {
              //   this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              //   this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              // } else {
              // if (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)][0] !== '_') {
              // if (item.GCOName === "A_Chassis") {
              //   debugger
              // }
              if (item.GCOName === "I" || item.GCOName === "A_Chassis" || item.GCOName === "A_Ident") {
              } else {
                this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
                this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              }
              // }
              // }
            }
          }
          this.criteriaValues.displayCriteriaList = this.criteriaValues.displayCriteriaList.sort(this.utilService.sortArray);
        },
          error => {
            this.toastyService.error(this.utilService.getTranslateName(`ERROR.TECHNICAL_ERROR`));
            console.log("error", error.message);
          });
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TECHNICAL_ERROR`));
        console.log("error", error.message);
      });
  }

  clickCriteriaList(item) {
    // debugger
    const criteriaFilterVal = this.criteriaValues.criteriaListOringinal.filter(a => a.longLabel.split(this.criteriaValues.splitChar, 2).length > 1 ? a.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)] === item : a.longLabel === item)[0];
    // console.log("criteriaFilterVal", criteriaFilterVal);
    this.criteriaValues.selectedItemValue = criteriaFilterVal.longLabel.split(this.criteriaValues.splitChar, 2).length > 1 ? criteriaFilterVal.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)] : criteriaFilterVal.longLabel;
    this.criteriaValues.selectedItemSize = criteriaFilterVal.size;
    this.criteriaValues.selectedItemType = criteriaFilterVal.typeOfField;
    this.criteriaValues.GCOName = criteriaFilterVal.GCOName;
    this.criteriaValues.selectedItemType4Color = this.criteriaValues.criteriaListOringinal.filter(a => a.longLabel.split(this.criteriaValues.splitChar, 2).length > 1 ? a.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)] === item : a.longLabel === item)[0].typeOfField;
  }

  onAddCriteria() {
    if (this.criteriaValues.selectedItemValue) {
      this.listCriteria.push({
        name: this.criteriaValues.selectedItemValue,
        size: this.criteriaValues.selectedItemSize,
        type: this.criteriaValues.selectedItemType,
        GCOName: this.criteriaValues.GCOName
      });
      this.criteriaValues.selectedItemValue = undefined;
      console.log("this.listCriteria", this.listCriteria)
    }
  }

  onAddData() {
    if (this.criteriaValues.selectedItemValue) {
      this.listData.push({
        name: this.criteriaValues.selectedItemValue,
        size: this.criteriaValues.selectedItemSize,
        type: this.criteriaValues.selectedItemType,
        GCOName: this.criteriaValues.GCOName
      });
      this.criteriaValues.selectedItemValue = undefined;
      console.log("this.listData", this.listData)
    }
  }

  onSelectCriteria(item) {
    console.log("select criteria", item);
    this.selectedCriteriaListItem = item;
  }

  onSelectData(item) {
    console.log("select data", item);
    this.selectedDataListItem = item;
  }

  onDelCriteria() {
    if (this.selectedCriteriaListItem) {
      for (let index = 0; index < this.listCriteria.length; index++) {
        const element = this.listCriteria[index];
        if (element === this.selectedCriteriaListItem) {
          this.listCriteria.splice(index, 1)
        }
      }
      this.selectedCriteriaListItem = undefined;
    }
  }

  onDelData() {
    if (this.selectedDataListItem) {
      for (let index = 0; index < this.listData.length; index++) {
        const element = this.listData[index];
        if (element === this.selectedDataListItem) {
          this.listData.splice(index, 1)
        }
      }
      this.selectedDataListItem = undefined;
    }
  }

  searchFilter() {
    this.criteriaValues.displayCriteriaList = this.criteriaValues.criteriaList.filter(a => a.toUpperCase().includes(this.criteriaValues.searchText.toUpperCase()));
    this.criteriaValues.displayCriteriaList = this.criteriaValues.displayCriteriaList.sort(this.utilService.sortArray);
  }

  checkColumnLength() {
    for (let index = 0; index < this.uploadData.length; index++) {
      const element = this.uploadData[index];
      if (element.length !== (this.listCriteria.length + this.listData.length)) {
        return true;
      }
      // return true;
    }
  }
  alertPopup(msg) {
    const dialogRef = this.dialog.open(AlertConfirmationComponent, {
      disableClose: true,
      width: '550px',
      data: { 'display': msg }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {

        if (this.selectedFileName.length > 20) {

          this.toastyService.warning("The file name is more than 20 characters");
          return;
        }

        for (var i = 0; i < this.selectedFileName.length; i++) {
          console.log("check", this.selectedFileName[i]);
          if (this.selectedFileName[i] == " ") {
            this.toastyService.warning("Please change the file name spaces are not allowed")
            return;
          }
        }

        if (this.checkColumnLength()) {

          this.toastyService.warning("The length of one criteria is not the same accoring to the request");
          return;
        }

        else {
          let blnErr = false;
          for (let index = 0; index < this.uploadData.length; index++) {
            blnErr = false;
            const element = this.uploadData[index];
            console.log("element", element)
            for (let i = 0; i < element.length; i++) {
              const innerElement = element[i];
              if (i < this.listCriteria.length) {
                console.log("innerElement", innerElement, this.listCriteria[i])
                if (innerElement.toString().length !== this.listCriteria[i].size && this.listCriteria[i].size !== 0) {
                  blnErr = true;
                }

              }
              else {
                if (innerElement.toString().length !== this.listData[i - this.listCriteria.length].size && this.listData[i - this.listCriteria.length].size !== 0) {
                  blnErr = true;
                }
              }
            }
            if (blnErr) {
              this.toastyService.warning("The number of criteria/data on the text file is not the same accoring to the request");
              return;
              // break;
            }
          }
          if (!blnErr) {
            let concatCriteria = "";
            let concatData = "";
            let totalCriteriaSize = 0;
            let sLisCriGCO = "";
            let sLisDataGCO = "";
            let rsChaine = "";
            for (let index = 0; index < this.listCriteria.length; index++) {
              if (index === 0) {
                concatCriteria = this.listCriteria[index].name;
                totalCriteriaSize = totalCriteriaSize + this.listCriteria[index].size;
                if (this.listCriteria[index].type === 3) {
                  sLisCriGCO = sLisCriGCO + this.listCriteria[index].GCOName + "@8@";
                } else {
                  sLisCriGCO = sLisCriGCO + this.listCriteria[index].GCOName + "@" + this.listCriteria[index].size + "@";
                }

                if (this.listCriteria[index].type === 3) {
                  rsChaine = rsChaine + this.listCriteria[index].type + "@9@1@" + this.listCriteria[index].GCOName + "@0@?@0@amj@0@";
                } else if (this.listCriteria[index].type === 4) {
                  rsChaine = rsChaine + this.listCriteria[index].type + "@0@1@" + this.listCriteria[index].GCOName + "@0@?@0@?@0@";
                } else {
                  rsChaine = rsChaine + "1" + "@1@1@" + this.listCriteria[index].GCOName + "@0@?@0@?@0@";
                }
              } else {
                concatCriteria = concatCriteria + "@" + this.listCriteria[index].name;
                totalCriteriaSize = totalCriteriaSize + this.listCriteria[index].size;
                if (this.listCriteria[index].type === 3) {
                  sLisCriGCO = sLisCriGCO + this.listCriteria[index].GCOName + "@8@";
                } else {
                  sLisCriGCO = sLisCriGCO + this.listCriteria[index].GCOName + "@" + this.listCriteria[index].size + "@";
                }


                if (this.listCriteria[index].type === 3) {
                  rsChaine = rsChaine + this.listCriteria[index].type + "@9@1@" + this.listCriteria[index].GCOName + "@0@?@0@amj@0@";
                } else if (this.listCriteria[index].type === 4) {
                  rsChaine = rsChaine + this.listCriteria[index].type + "@0@1@" + this.listCriteria[index].GCOName + "@0@?@0@?@0@";
                } else {
                  rsChaine = rsChaine + "1" + "@1@1@" + this.listCriteria[index].GCOName + "@0@?@0@?@0@";
                }
              }
            }
            for (let index = 0; index < this.listData.length; index++) {
              if (index === 0) {
                concatData = this.listData[index].name;
                if (this.listData[index].type === 3) {
                  sLisDataGCO = sLisDataGCO + this.listData[index].GCOName + "@8@";
                } else {
                  sLisDataGCO = sLisDataGCO + this.listData[index].GCOName + "@" + this.listData[index].size + "@";
                }
              } else {
                concatData = concatData + "@" + this.listData[index].name;
                if (this.listData[index].type === 3) {
                  sLisDataGCO = sLisDataGCO + this.listData[index].GCOName + "@8@";
                } else {
                  sLisDataGCO = sLisDataGCO + this.listData[index].GCOName + "@" + this.listData[index].size + "@";
                }
              }
            }
            let formData = new FormData();
            formData.append("file", this.file);
            this.apiService.uploadFile(formData).subscribe(res => {
              this.fileData = res;
              console.log("uploadfileRes", res);

              const queryParam2 = {
                "inputLists": [
                  `FileName@${res.fileName}`,
                  `IPN@${this.ipn}`,
                  `Table@${this.selectedTable}`,
                  "Action@UPLOAD_FILE",
                  "FileType@REA_DATA"
                ],
                // "input": "REALIGNMENT - Download log file",
                "region": this.getFavoriteRegion()
              }

              this.apiService.writingLogs(queryParam2).subscribe(res => {
                this.fileData = res;
                console.log("writingLogs", res);
                if (res.status === 200) {
                  let queryParam = { "input": `REALIGNEMENT@system@rea@${this.selectedTable}§${this.selectedFile}§C§p@${this.selectedTable}@CRITERE@${concatCriteria}@DATA@${concatData}@FIN_DATA@`, "region": this.getFavoriteRegion() };
                  this.apiService.saveObject(queryParam).subscribe(res1 => {
                    this.newData = res1;
                    console.log("newdata", this.newData);
                  },

                    error => {
                      console.log("newdata", error);
                    })

                  let queryParam1 = {
                    "input": `REALIGNEMENT@system@rea@${this.selectedTable}§${this.selectedFile}§S@Table@${this.selectedTable}@
Taille de la clef@${totalCriteriaSize}
Nombre de critere@${this.listCriteria.length}
Nombre de data@${this.listData.length}
CRITERE_GCO@${sLisCriGCO}
DATA_GCO@${sLisDataGCO}
Clause§>I<TEMP@__1__@${this.selectedTable}@__1__.AFF@__1__@${this.selectedTable}@__1__.AFF@${this.listCriteria.length + 1}@0@0@0@0@?@0@?@0@${rsChaine}`, "region": this.getFavoriteRegion()
                  };
                  console.log("queryParam1", queryParam1);
                  this.apiService.saveObject(queryParam1).subscribe(res2 => {
                    this.saveData = res2;
                    this.dialogRef.close(true);
                    console.log("savedata", this.saveData);
                  },

                    error => {
                      console.log("savedata", error);
                    })
                }
              },

                error => {
                  console.log("writingLogs", error);

                })
            },

              error => {
                console.log("uploadfileRes", error);

              })





          }

          else {
            this.toastyService.warning("No data found");
            return;
          }



        }


      }
    })
  }



  async onSend() {

    if (this.uploadData) {

      if (this.listCriteria.length == 0) {
        this.toastyService.warning("You may choose at least a criteria");
        return;
      }

      if (this.listData.length == 0) {
        this.toastyService.warning("You may choose at least a data");
        return;
      }

      this.alertPopup("ATTENTION. you will send a request of realignment on the server. Are you sure you want to carry out this action?");

      // if(this.selectedFileName.length > 20){

      //   this.toastyService.warning("The file name is more than 20 characters");
      //   return; 
      //   }


      // for(var i=0; i<this.selectedFileName.length; i++){
      //   console.log("check",this.selectedFileName[i]);
      //   if(this.selectedFileName[i] ==  " "){
      //     this.toastyService.warning("Please change the file name spaces are not allowed")
      //     return;
      //   }
      // }

      // if (await this.checkColumnLength()) {
      //   // this.alertPopup("ATTENTION. you will send a request of realignment on the server. Are you sure you want to carry out this action?");
      //   this.toastyService.warning("The length of one criteria is not the same accoring to the request");
      //   return;
      // } 

      //   else {
      //     let blnErr = false;
      //     for (let index = 0; index < this.uploadData.length; index++) {
      //       blnErr = false;
      //       const element = this.uploadData[index];
      //       console.log("element", element)
      //       for (let i = 0; i < element.length; i++) {
      //         const innerElement = element[i];
      //         if (i < this.listCriteria.length) {
      //           console.log("innerElement", innerElement, this.listCriteria[i])
      //           if (innerElement.toString().length !== this.listCriteria[i].size && this.listCriteria[i].size !== 0) {
      //             blnErr = true;
      //           }

      //         } else {
      //           if (innerElement.toString().length !== this.listData[i - this.listCriteria.length].size && this.listData[i - this.listCriteria.length].size !== 0) {
      //             blnErr = true;
      //           }
      //         }
      //       }
      //       if (blnErr) {
      //         this.toastyService.warning("The number of criteria/data on the text file is not the same accoring to the request");
      //         return;
      //         break;
      //       }
      //     }
      //     if (!blnErr) {

      //       // this.toastyService.success("file is good");
      //       // this.alertPopup("ATTENTION. you will send a request of realignment on the server. Are you sure you want to carry out this action?");

      //       let formData = new FormData();
      //       formData.append("file", this.file);
      //       this.apiService.uploadFile(formData).subscribe(res => {
      //         this.fileData = res;
      //         console.log("uploadfileRes", res)
      //       },
      //         error => {
      //           console.log(error);
      //         })
      //     }

      //   }
      // } else {
      //   this.toastyService.warning("No data found");
      //   return;
      // }
    }
  }

  changeValue() {
    console.log("tab", this.selectedTable.name);
    return this.selectedTable.name;
  }
}
