import { Injectable } from '@angular/core';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor() { }

  getAllRequestData() {
    let requestStoresData = [];
    const req1 = sessionStorage.getItem("request1");
    const req2 = sessionStorage.getItem("request2");
    const req3 = sessionStorage.getItem("request3");

    if (req1 !== 'undefined' && req1 !== null) {
      requestStoresData.push(JSON.parse(req1));
    }

    if (req2 !== 'undefined' && req2 !== null) {
      requestStoresData.push(JSON.parse(req2));
    }

    if (req3 !== 'undefined' && req3 !== null) {
      requestStoresData.push(JSON.parse(req3));
    }

    return requestStoresData;
  }

  getSelectedLang() {
    let selectedLang;
    const lang = sessionStorage.getItem("selectedLang");
    if (selectedLang !== 'undefined' && selectedLang !== null) {
      selectedLang = lang;
    }
    return selectedLang;
  }

  addLang(lang) {
    sessionStorage.setItem("selectedLang", lang);
  }

  getRegionMsg() {
    let selectedRegion = {};
    const europe = sessionStorage.getItem("europeRegion");
    const mercosur = sessionStorage.getItem("mercosurRegion");
    if (selectedRegion !== 'undefined' && selectedRegion !== null) {
      selectedRegion = {
        'europe': europe,
        'mercosur': mercosur
      };
    }
    return selectedRegion;
  }

  addRegionMsg(europeBool, mercosurBool) {
    sessionStorage.setItem("europeRegion", europeBool);
    sessionStorage.setItem("mercosurRegion", mercosurBool);
  }

  addRequestData(regionName, tableName, requestStoresData) {
    let reqId = [1, 2, 3];

    for (const item of reqId) {
      if (requestStoresData.filter(a => a.id === item).length < 1) {
        const reqData = {
          "id": item, "region": regionName, "table": tableName, 'lang': this.getSelectedLang(), 'characterList': {
            'characterListType1': { 'group0': [] },
            'characterListType2': { 'group0': [] },
            'characterListType3': { 'group0': [] }
          }
        };
        // const reqData = { "id": item, "region": regionName, "table": tableName, 'lang': this.getSelectedLang(), 'characterList': {} };

        sessionStorage.setItem("request" + item, JSON.stringify(reqData));
        break;
      }
    }
  }

  updateReqDataLang(lang, requestID) {
    let req: any = JSON.parse(sessionStorage.getItem('request' + (requestID + 1)));
    req['lang'] = lang;
    sessionStorage.setItem("request" + (requestID + 1), JSON.stringify(req));
  }

  removeRequestData(requestID) {
    sessionStorage.setItem("request" + requestID, undefined);
    const reqData = this.getAllRequestData();
    for (let index = 0; index < reqData.length; index++) {
      const element = reqData[index];
      if (index < element.id - 1) {
        element.id = index + 1;
        sessionStorage.setItem("request" + element.id, JSON.stringify(element));
        sessionStorage.setItem("request" + (index + 2), undefined);
      }
    }
  }

  addRequestReGroupment(value, requestID) {
    let reGroupment: any = JSON.parse(sessionStorage.getItem('request' + (requestID + 1)));
    reGroupment['regroupment'] = value;
    sessionStorage.setItem("request" + (requestID + 1), JSON.stringify(reGroupment));
  }

  addPopulationRequest(data, requestID) {
    let populationList: any = JSON.parse(sessionStorage.getItem('request' + (requestID + 1)));
    populationList['populationList'] = data;
    sessionStorage.setItem("request" + (requestID + 1), JSON.stringify(populationList));
  }

  addRequestVuePrivateIPNData(value, requestID) {
    let vue: any = JSON.parse(sessionStorage.getItem('request' + (requestID + 1)));
    vue['vuePrivateIPNData'] = value;
    sessionStorage.setItem("request" + (requestID + 1), JSON.stringify(vue));
  }

  addRequestVueAffichStatus(value, requestID) {
    let vue: any = JSON.parse(sessionStorage.getItem('request' + (requestID + 1)));
    vue['vueAffichStatus'] = value;
    sessionStorage.setItem("request" + (requestID + 1), JSON.stringify(vue));
  }

  addRequestVue(value, requestID) {
    let vue: any = JSON.parse(sessionStorage.getItem('request' + (requestID + 1)));
    vue['vue'] = value;
    sessionStorage.setItem("request" + (requestID + 1), JSON.stringify(vue));
  }

  addRequestCharacter(value, requestID, type) {
    let character: any = JSON.parse(sessionStorage.getItem('request' + (requestID + 1)));
    character['characterList']['characterListType' + type] = value;
    sessionStorage.setItem("request" + (requestID + 1), JSON.stringify(character));
  }

  setTabName(val, requestID) {
    let request: any = JSON.parse(sessionStorage.getItem('request' + (requestID + 1)));
    request['queryName'] = val;
    sessionStorage.setItem("request" + (requestID + 1), JSON.stringify(request));
  }

  selectedQuery(val, requestID) {
    let request: any = JSON.parse(sessionStorage.getItem('request' + (requestID + 1)));
    request['selectedQuery'] = val;
    sessionStorage.setItem("request" + (requestID + 1), JSON.stringify(request));
  }

  duplicateSelectedQuery(val, requestID) {
    let request: any = JSON.parse(sessionStorage.getItem('request' + (requestID + 1)));
    request['duplicateSelectedQuery'] = val;
    sessionStorage.setItem("request" + (requestID + 1), JSON.stringify(request));
  }

  roleBasedQuery(val, requestID) {
    let request: any = JSON.parse(sessionStorage.getItem('request' + (requestID + 1)));
    request['roleBasedQuery'] = val;
    sessionStorage.setItem("request" + (requestID + 1), JSON.stringify(request));
  }
}
