import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-character-grouping-list',
  templateUrl: './character-grouping-list.component.html',
  styleUrls: ['./character-grouping-list.component.scss']
})
export class characterGroupingList implements OnChanges {
  @Input() selectedListItem: string;
  @Input() editValue: string[] = [];
  @Output() setCharacterGrouping = new EventEmitter();
  wording: string;
  selectedItem: string;
  selectedItemIndex;
  groupingText: string[] = [];
  isSubmitted: boolean = false;
  constructor() { }
  ngOnChanges() {
    if (this.editValue && this.editValue.hasOwnProperty('value')) {
      this.editValue = this.editValue['value'].split('ou');
      this.groupingText = this.editValue.map(item => item.replace(/\[/g, '').replace(/\]/g, ''));
    }
    this.enableButton();
  }

  moveDown() {
    if (this.wording) { // && !this.groupingText.includes(this.wording.toLocaleUpperCase())) {
      this.groupingText.push(this.wording.toLocaleUpperCase());
      this.isSubmitted = true;
      this.wording = '';
    }
  }
  moveUp() {
    // this.groupingText = this.groupingText.filter(item => this.selectedItem !== item);
    if (this.selectedItemIndex >= 0) {
      this.groupingText.splice(this.selectedItemIndex, 1);
      this.wording = this.selectedItem;
      this.enableButton();
    }
  }

  enableButton() {
    if (this.groupingText.length === 0) {
      this.isSubmitted = false;
    } else {
      this.isSubmitted = true;
    }
    this.selectedItem = undefined;
    this.selectedItemIndex = undefined;
  }

  checkSelectedListItem(value, idx) {
    this.selectedItem = value;
    this.selectedItemIndex = idx;
  }

  submit() {
    let value = {
      value: this.groupingText,
      displayGroupingForm: false
    };
    this.setCharacterGrouping.emit(value);
  }

  cancel() {
    let value = {
      value: '',
      displayGroupingForm: false
    };
    this.setCharacterGrouping.emit(value);
  }
}