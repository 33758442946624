import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ToastyModule } from 'ng2-toasty';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DatePipe } from '@angular/common';
import { MatomoModule } from 'ngx-matomo';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AppMaterialModule } from './material-module';
import { AppRoutingModule } from './app-routing.module';
import { TableModule } from './component/table/table.module';
import { QueryScreenModule } from './component/query/query-screen.module';
import { InterceptService } from './services/intercept/intercept.service';
import { LoaderService } from './services/loader/loader.service';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { LoadSpinnerComponent } from './load-spinner/load-spinner.component';
import { HomeComponent } from './component/home/home.component';
import { AlertConfirmationComponent } from './component/alert-confirmation/alert-confirmation.component';
import { AuthentificationModule } from './core/authentification/authentification.module';
import { AlertMessageComponent } from './component/query/alert-message/alert-message.component';
import { LabelManagementComponent } from './component/label-management/label-management.component';
import { UserMessageComponent } from './component/user-message/user-message.component';
import { GroupFormattingComponent } from './component/group-formatting/group-formatting.component';
import { GroupFormattingConditionComponent } from './component/group-formatting-condition/group-formatting-condition.component';
import { GfcCharacterListComponent } from './component/gfc-character-list/gfc-character-list.component';
import { UserMessageSendinstantlyComponent } from './component/user-message/user-message-sendinstantly/user-message-sendinstantly.component';
import { ListConnectionComponent } from './list-connection/list-connection.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { RealignmentMainComponent } from './component/realignment-main/realignment-main.component';
import { RealignmentDataComponent } from './component/realignment-main/realignment-data/realignment-data.component';
import { ScrollTrackerDirective } from './component/query/column-segment-three/scroll-tracker.directive';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    LoadSpinnerComponent,
    HomeComponent,
    AlertConfirmationComponent,
    AlertMessageComponent,
    LabelManagementComponent,
    UserMessageComponent,
    GroupFormattingComponent,
    GroupFormattingConditionComponent,
    GfcCharacterListComponent,
    UserMessageSendinstantlyComponent,
    ListConnectionComponent,
    RealignmentMainComponent,
    RealignmentDataComponent,
    ScrollTrackerDirective
  ],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatomoModule,
    AppMaterialModule,
    AuthentificationModule,
    OAuthModule.forRoot(),
    ToastyModule.forRoot(),
    AppRoutingModule,
    TableModule,
    QueryScreenModule,
    NgxPaginationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })

  ],
  providers: [
    DatePipe,
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    }, LoaderService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [AlertConfirmationComponent, AlertMessageComponent, GroupFormattingConditionComponent, UserMessageSendinstantlyComponent, RealignmentDataComponent]
})
export class AppModule { }