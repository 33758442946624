import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { BackendApiService } from '../../../../services/backend-api/backend-api.service';
import { UtilService } from '../../../../services/util/util.service';

@Component({
  selector: 'app-character-list',
  templateUrl: './character-list.component.html',
  styleUrls: ['./character-list.component.scss']
})

export class CharacterListComponent implements OnChanges {
  @Input() selectedInputValue: string;
  @Input() editValue: any;
  @Output() setCharacterData = new EventEmitter();
  positionValue: string;
  characterListInputValue: string;
  characterValue: string;
  danListTableValues: string;
  danListOriginalTableValues: Array<string>;
  selectedDanList: Array<string> = [];
  listSearch: string;
  characterList = [{ name: 'EQUAL', value: '=' },
  { name: 'DANLIST', value: 'Dans liste' },
  { name: 'SUPERIOR', value: '>=' },
  { name: 'INFERIOR', value: '<=' },
  { name: 'CONTENT', value: 'Cont' },
  { name: 'POSITION', value: 'position' },
  { name: 'DIFFERENT', value: '<>' }];
  blockedFields = ['Delai RAF MADP Previsionnelle§Leadtime To be done before MADP _ Arrival at Dealer',
    'Identifiant partenaire§Identification Partner',
    'Invariant BCV§Invariant BCV',
    'Delai RAF MADP Previsionnelle§Leadtime To be done before MADP _ Arrival at Dealer',
    'Numero commande§Customer Order Number',
    'Numero Chassis§VIN',
    'Numero de Batterie§Battery Number',
    'Numero de Reception Communautaire§Numero de Reception Communautaire',
    'Numero de Reception Nationale§Numero de Reception Nationale',
    'Numero de sequence entree FF§Sequence number at the end of flexibility',
    'Numero de serie§Serial number',
    'Numero fabrication§Manufacturing Number',
    'Numero Identification§Identification Number',
    'Numero Moteur§Engine Number',
    'Options Zone Etendue§Options Extended Zone',
    'Ordre commercial initial§Commercial Order _ Initial',
    'Ordre commercial§Commercial Order',
    'TVV complet§Full TVV',
    'VIS provisoire§Provisional VIS']

  constructor(readonly apiService: BackendApiService,
    readonly utilService: UtilService) { }

  ngOnChanges() {
    if (this.editValue && this.editValue.hasOwnProperty('format')) {
      if (this.editValue.format === 'position') {
        this.characterValue = this.editValue.format;
        this.editValue = this.editValue.value.split(' ');
        // this.characterListInputValue = this.editValue[2];
        // this.positionValue = this.editValue[1].match(/\((.*)\)/)[1].replace('=', '');
        this.characterListInputValue = this.editValue[1];
        this.positionValue = this.editValue[0].match(/\((.*)\)/)[0].replace('=', '').replace("(", "").replace(")", "");
      } else if (this.editValue.format === 'Dans liste') {
        this.characterValue = this.editValue.format;
        // this.selectedDanList = this.editValue.value.substr(1, this.editValue.value.length - 1).split('/'); //this.editValue.value.split('/');
        // if (this.editValue.value.split('/')[0] !== "") {
        //   this.selectedDanList = this.editValue.value.trim().split('/');
        // } else {
        this.selectedDanList = this.editValue.value.split('/');
        if (this.selectedDanList[0].substring(0, 2) === "  " && this.selectedDanList[0].length > this.editValue.size) {
          this.selectedDanList[0] = this.selectedDanList[0].replace("  ", "");
        }
        if (this.selectedDanList[0].substring(0, 1) === " " && this.selectedDanList[0].length > this.editValue.size) {
          this.selectedDanList[0] = this.selectedDanList[0].substring(1, this.selectedDanList[0].length);

          // for (let index = 0; index < this.selectedDanList.length; index++) {
          //   const element = this.selectedDanList[index];

          //   for (let index1 = element.length; index1 < this.editValue.size; index1++) {
          //     this.selectedDanList[index] = this.selectedDanList[index] + " ";
          //   }
          // }
        }
        // }
        this.eventChange();
      } else {
        this.characterValue = this.editValue.format;
        this.editValue = this.editValue.value.split(this.editValue.format);
        this.characterListInputValue = this.editValue[1].substr(1, this.editValue[1].length - 1);
      }
    }
  }

  maxLengthCheck(object) {
    object.target.value = Math.abs(object.target.value);
    if (object.target.value.length > object.target.maxLength)
      object.target.value = object.target.value.slice(0, object.target.maxLength)
  }

  eventChange() {
    if (this.characterValue === 'Dans liste' && !this.isDanList()) {
      const queryParam = { "input": `DICO@${this.selectedInputValue['ipn']}@${this.selectedInputValue['selectedTable']}@${this.selectedInputValue['longLabel']}@`, "region": this.getFavoriteRegion() };
      this.apiService.getObjectSize(queryParam).subscribe(res => {
        if (res.noObjects > 0) {
          const queryParam1 = { "input": `DICO@${this.selectedInputValue['ipn']}@${this.selectedInputValue['selectedTable']}@${this.selectedInputValue['longLabel']}@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
          this.apiService.getChoiceTableDetails(queryParam1).subscribe(objList => {
            this.danListTableValues = objList;
            this.danListTableValues['lists'] = this.danListTableValues['lists'].sort(this.utilService.sortArraySpecial);
            this.danListOriginalTableValues = objList.lists.sort(this.utilService.sortArraySpecial);
            this.selectedDanList = [];
            for (let item of this.editValue.value.split('/')) {
              if (item.substring(0, 1) === " " && item.length > this.editValue.size) {
                item = item.replace(" ", "");
              }
              if (item.substring(0, 1) !== " " || item.substring(0, 1) !== "") {
                if (this.danListTableValues['lists'].filter(a => a === item).length > 0) {
                  this.selectedDanList.push(item);
                } else {
                  if (item.substring(0, 1) === " ") {
                    this.selectedDanList.push(item);
                  }
                }
              }
            }
          })
        }
      })
    }
  }

  isDanList() {
    let list = [];
    this.blockedFields.forEach(el => {
      list.push(el.toLowerCase());
    })
    if (list.includes(this.selectedInputValue['longLabel'].toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }

  getFavoriteRegion() {
    if (this.selectedInputValue['region'] === "Europe") {
      return "PIL";
    } else {
      return "PIM";
    }
  }

  searchCharacter() {
    this.danListTableValues['lists'] = this.danListOriginalTableValues.filter(a => a.toUpperCase().includes(this.listSearch.toUpperCase()))
  }

  isSelected(value: string): boolean {

    /**** SONAR : Remove this conditional structure or edit its code blocks so that they're not all the same. ****/
    // // return this.selectedDanList.indexOf(value) >= 0;
    // if (value.trim() === '') {
    //   return this.selectedDanList.filter(x => x.trim() === value.trim()).length > 0;
    // } else {
    //   // return this.selectedDanList.indexOf(value) >= 0;
    //   return this.selectedDanList.filter(x => x.trim() === value.trim()).length > 0;
    // }

    return this.selectedDanList.filter(x => x.trim() === value.trim()).length > 0;
  }

  onChange(value: string, checked: boolean) {
    if (checked) {
      this.selectedDanList.push(value);
      // console.log("before", this.selectedDanList)
      // debugger
      const sorter = this.utilService.MySort(' ?*!@_.()#^&%-=+01234567989abcdefghijklmnopqrstuvwxyz');
      this.selectedDanList = this.selectedDanList.sort(this.utilService.sortArraySpecial);
      // console.log("after", this.selectedDanList)
    } else {
      let index = this.selectedDanList.indexOf(value);
      this.selectedDanList.splice(index, 1);
    }
  }

  restrictSpecialCharacter(event) {
    return event.charCode !== 64 && event.charCode !== 40 && event.charCode !== 41;
  }
  onOk() {
    let value;
    if (this.selectedDanList.length > 0 && this.characterValue === 'Dans liste') {
      value = {
        'value': this.selectedDanList,
        'formatType': this.characterValue
      }
    } else {
      value = {
        'format': this.characterValue === 'position' ? `(${this.positionValue})=` : this.characterValue,
        'value': this.characterListInputValue,
        'formatType': this.characterValue,
        displayGroupingForm: false
      }
    }
    this.setCharacterData.emit(value);
    this.selectedDanList = []
  }

  onCancel() {
    let value = {
      value: '',
      formatType: '',
      displayGroupingForm: false
    };
    this.setCharacterData.emit(value);
  }
}