import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as pkg from '../../../../../package.json';


@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent {
  version;
  constructor(
    public dialogRef: MatDialogRef<AlertMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.version = pkg.version;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  navigateUrlPage(url) {
    window.open(url, '_blank');
  }

  chkLinkIndex(index) {
    return this.data.linkIndex.indexOf(index) !== -1 ? true : false;
  }
}