import { Component, Input, AfterViewInit, EventEmitter, ViewChild, HostListener, ElementRef, Renderer2, Output, OnInit, DoCheck, OnChanges } from '@angular/core';
import { BackendApiService } from '../../../services/backend-api/backend-api.service';
import { MatDialog, MatTable } from '@angular/material';
import { ToastyService } from 'ng2-toasty';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from '../../../services/util/util.service';
import { NgxPaginationModule, PaginatePipe } from 'ngx-pagination';
import { AlertMessageComponent } from '../alert-message/alert-message.component';
import { AlertConfirmationComponent } from '../../alert-confirmation/alert-confirmation.component';
import 'moment/min/locales.min';
import moment from 'moment';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';


@Component({
  selector: 'column-segment-three',
  templateUrl: './column-segment-three.component.html',
  styleUrls: ['./column-segment-three.component.scss']
})

export class ColumnSegmentThreeComponent implements AfterViewInit, OnChanges {
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;
  @Input() ipn;
  @Input() region;
  @Input() selectedTable;
  @Input() lang;
  @Input() executeQuery;
  @Input() detailExecute;
  @Input() vueList;
  @Input() characterList;
  @Input() groupList;
  @Input() detailCharacterList;
  @Input() detailGroupList;
  @Input() detailVueList;
  @Input() executeBtn;
  @Input() vueTypeSelect;
  @Input() tcm;
  @Input() queryName;
  @Input() isTTB;
  @Input() previewData;
  @Output() blnData = new EventEmitter();
  @Output() totalRowsData = new EventEmitter();
  @Output() resultColumns = new EventEmitter();
  @Output() outputGetEvent = new EventEmitter();
  @Output() currentResultPage = new EventEmitter();
  @Output() emitSecondPageData = new EventEmitter();
  @Output() blnExecErr = new EventEmitter();

  @ViewChild(MatTable, { read: ElementRef, static: true }) matTableRef: ElementRef;

  selectedTab: string = "global";
  executedData = [];
  pagination: number = 0;
  debounce: boolean = true;
  splitChar = "§";
  totalRows: number;
  popRows: number;
  quantity: number;
  // step: number = 0;
  displayedColumns: any[] = [];
  columns = [];
  pressed = false;
  execute = true;
  currentResizeIndex: number;
  selectedRow;
  startX: number;
  startWidth: number;
  detailRowNo: number;
  secondDetailRowNo: number;
  currentPage: number = 1;
  isResizingRight: boolean;
  commonCriteria = [];
  initialCriteria = [];
  semiClearCurrent = [];
  semiClearInitial = [];
  resizableMousemove: () => void;
  resizableMouseup: () => void;

  num = 0;

  p: number;
  currentPages = 0;
  itemsPerPage = 500;

  collection = [];
  nextColumnDisplayNo: number = 0;
  displayedData = [];

  constructor(readonly apiService: BackendApiService, private renderer: Renderer2, readonly toastyService: ToastyService,
    readonly translateService: TranslateService, readonly utilService: UtilService, public dialog: MatDialog) {
    for (let i = 1; i <= 100; i++) {
      let Obj = { 'name': `Employee Name ${i}`, 'code': `EMP00 ${i}` }
      this.collection.push(Obj);
    }
  }

  ngAfterViewInit() {
    // if (this.scrollFrame) {
    //   this.scrollContainer = this.scrollFrame.nativeElement;
    // }
    // this.loadData(this.pagination);
  }

  ngOnChanges() {
    this.p = 1;
    if (this.executeBtn) {
      this.detailRowNo = undefined;
      this.secondDetailRowNo = undefined;
      this.resetValues();
      this.loadData(0);
      this.currentPage = 1;
      this.currentResultPage.emit(this.currentPage);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.matTableRef) {
      this.setTableResize(this.matTableRef.nativeElement.clientWidth);
    }
  }

  getLangIndex(lang) {
    if (lang === 'en') {
      return 1;
    } else {
      return 0;
    }
  }

  setDisplayedColumns() {
    // debugger
    this.displayedData = [];

    if (this.columns.length > 60) {
      const remainingLength = Math.min(20, this.executedData.length - this.displayedData.length);
      this.displayedData.push(...this.executedData.slice(this.displayedData.length, this.displayedData.length + remainingLength));


      const remainingLength1 = Math.min(20, this.columns.length - this.displayedColumns.length);
      this.displayedColumns.push(...this.columns.slice(this.displayedColumns.length, this.displayedColumns.length + remainingLength1));
    } else {
      this.displayedData = this.executedData;
      this.displayedColumns = this.columns;
    }
    // // this.columns.forEach((column, index) => {
    // //   column.index = index;
    // //   this.displayedColumns[index] = column.field;
    // // });
    // if (this.columns.length < 20) {
    //   for (let index = 0; index < this.columns.length; index++) {
    //     const element = this.columns[index];
    //     this.displayedColumns.push(element);
    //   }
    // } else {
    //   for (let index = 0; index < 20; index++) {
    //     const element = this.columns[index];
    //     this.displayedColumns.push(element);
    //   }
    // }
  }

  setTableResize(tableWidth: number) {
    let totWidth = 0;
    this.columns.forEach((column) => {
      totWidth += column.width;
    });
    const scale = (tableWidth - 5) / totWidth;
    this.columns.forEach((column) => {
      column.width *= scale;
      this.setColumnWidth(column);
    });
  }


  onResizeColumn(event: any, index: number) {
    this.checkResizing(event, index);
    this.currentResizeIndex = index;
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = event.target.clientWidth;
    event.preventDefault();
    this.mouseMove(index);
  }

  private checkResizing(event, index) {
    const cellData = this.getCellData(index);
    if ((index === 0) || (Math.abs(event.pageX - cellData.right) < cellData.width / 2 && index !== this.columns.length - 1)) {
      this.isResizingRight = true;
    } else {
      this.isResizingRight = false;
    }
  }

  private getCellData(index: number) {
    if (this.matTableRef) {
      const headerRow = this.matTableRef.nativeElement.children[0];
      const cell = headerRow.children[0].children[index];
      return cell.getBoundingClientRect();
    }
  }

  mouseMove(index: number) {
    this.resizableMousemove = this.renderer.listen('document', 'mousemove', (event) => {
      if (this.pressed && event.buttons) {
        const dx = (this.isResizingRight) ? (event.pageX - this.startX) : (-event.pageX + this.startX);
        const width = this.startWidth + dx;
        if (this.currentResizeIndex === index && width > 50) {
          this.setColumnWidthChanges(index, width);
        }
      }
    });
    this.resizableMouseup = this.renderer.listen('document', 'mouseup', (event) => {
      if (this.pressed) {
        this.pressed = false;
        this.currentResizeIndex = -1;
        this.resizableMousemove();
        this.resizableMouseup();
      }
    });
  }

  setColumnWidthChanges(index: number, width: number) {
    const orgWidth = this.columns[index].width;
    const dx = width - orgWidth;
    if (dx !== 0) {
      const j = (this.isResizingRight) ? index + 1 : index - 1;
      const newWidth = this.columns[j].width - dx;
      if (newWidth > 50) {
        this.columns[index].width = width;
        this.setColumnWidth(this.columns[index]);
        this.columns[j].width = newWidth;
        this.setColumnWidth(this.columns[j]);
      }
    }
  }

  moveRightColumn() {
    this.nextColumnDisplayNo = this.displayedColumns.length;
    if (this.displayedColumns.length + 20 > this.columns.length) {
      for (let index = this.displayedColumns.length; index < this.columns.length; index++) {
        const element = this.columns[index];
        this.displayedColumns.push(element);
      }
    } else {
      const tvalue = this.displayedColumns.length + 20;
      for (let index = this.displayedColumns.length; index < tvalue; index++) {
        const element = this.columns[index];
        this.displayedColumns.push(element);
      }
    }
  }

  setColumnWidth(column: any) {
    const columnEls = Array.from(document.getElementsByClassName('mat-column-' + column.field));
    columnEls.forEach((el: HTMLDivElement) => {
      el.style.width = column.width + 'px';
    });
  }

  getRecord(row) {
    // for (let i = 0; i < this.executedData.length; i++) {
    //     for (let j = 0; j < this.columns.length; j++) {
    //       if (this.executedData[i][j].C_1 === row[0].C_1) {
    //       this.selectedRow = this.executedData[i][j];

    //     }
    //   }
    // }
    this.selectedRow = row;
    let rowNo, qte;
    for (const [key, value] of Object.entries(row)) {
      if (Object.keys(value)[0] === "C_2") {
        qte = { "Qte": Object.values(value)[0] };
      }
      if (Object.keys(value)[0] === "C_1") {
        rowNo = { "RowNum": Object.values(value)[0] };
      }
    }
    if (this.currentPage === 1) {
      this.quantity = parseInt(qte.Qte);
      this.detailRowNo = undefined;
      this.secondDetailRowNo = undefined;
      this.detailRowNo = rowNo !== undefined ? rowNo.RowNum === 0 ? 0 : rowNo.RowNum - 1 : 0;
    }
    if (this.currentPage === 2) {
      this.secondDetailRowNo = undefined;
      this.secondDetailRowNo = rowNo !== undefined ? rowNo.RowNum === 0 ? 0 : rowNo.RowNum - 1 : 0;
    }
  }

  loadData(pagination) {
    let get;
    this.num = 0;

    if (pagination === 0) {
      // // get = `GET@${this.ipn}@${this.selectedTable}@un100002@${pagination === 0 ? 0 : pagination}@${pagination === 0 ? 0 : 500}@1@`;
    //  console.log("condition ",(this.selectedTable==='PDFV', '&&', this.isTTB));     
      get = `GET@${this.ipn}@${this.selectedTable}@un100002@${pagination === 0 ? 0 : pagination}@${this.selectedTable==='PDFV' && this.isTTB ? 1:pagination === 0 ? 0 : pagination}@1@`;
      // console.log(get);
    } else {
      // if (this.executedData.length >= this.totalRows) {
      //   return;
      // }
      if ((this.p - 1) * 500 >= this.totalRows) {
        return;
      }
      // // get = `GET@${this.ipn}@${this.selectedTable}@un100002@${this.executedData.length}@${pagination === 0 ? 0 : 500}@1@`;
      get = `GET@${this.ipn}@${this.selectedTable}@un100002@${(this.p - 1) * 500}@${pagination === 0 ? 0 : pagination}@1@`;
    }
    this.outputGetEvent.emit(get);
    const timeStamp = Date.now();
    const randomNumber = Math.floor(100000 + Math.random() * 900000);
    const queryParam = { "inputLists": `${timeStamp}-${randomNumber}\n${this.executeQuery}\n${get}`.split('\n'), "region": this.getFavoriteRegion() };
    this.num = 0;
    this.loadDataExecution(queryParam, pagination, `${timeStamp}-${randomNumber}`);


    // console.log("traceQuery", this.executeQuery, this.previewData, moment.utc().format("HH:mm:ss"));
    for (const pItem of this.previewData.population) {
      // console.log('objects', Object.keys(pItem), Object.keys(pItem)[0], Object.values(pItem), Object.values(pItem)[0], this.translateService.instant(`NEW_QUERY.POPULATION.${Object.keys(pItem)}`))
    }
  }

  getObjectKey(item) {
    return Object.keys(item);
  }

  getObjectValue(item) {
    return Object.values(item);
  }

  getQueryTrace(outputTotalRows, outputPops) {

    if (sessionStorage.getItem('trace') !== "") {
      const tCount = sessionStorage.getItem('traceCount');
      sessionStorage.setItem('traceCount', (Number(tCount) + 3).toString());
    }
    let sVal;
    const traceCount = sessionStorage.getItem('traceCount');
    if (Number(traceCount) > 2) {
      sVal = this.executeQuery.replaceAll(this.ipn, "__1__").replaceAll('un100000', 'un10000' + (Number(traceCount) - 2).toString()).replaceAll('un100001', 'un10000' + (Number(traceCount) - 1).toString()).replaceAll('un100002', 'un10000' + (Number(traceCount)).toString()).split("\n");
    } else {
      sVal = this.executeQuery.replaceAll(this.ipn, "__1__").split("\n");
    }
    // console.log("sVal", sVal);
    this.utilService.setTrace(`#H ${moment.utc().format("HH:mm:ss")}`);
    this.utilService.setTrace(`>A< svc_objsave`);
    this.utilService.setTrace(`>I< ${sVal[0]}`);
    this.utilService.setTrace(`>O<`);
    this.utilService.setTrace(`#H ${moment.utc().format("HH:mm:ss")}`);
    // this.utilService.setTrace(`#H ${moment.utc().format("HH:mm:ss")}`);
    // // this.utilService.setTrace(`>A< ACTUALISE  >I<  ${this.ipn} >O< @1@`);
    // if (this.previewData.population.length > 0) {
    //   for (const pItem of this.previewData.population) {
    //     //Date TCM _ Offline date
    //     const cDate = new Date();
    //     if (Object.keys(pItem)[0] === 'TCM') {
    //       if (Object.values(pItem).includes("3")) {
    //         const rDate = new Date(cDate.setMonth(cDate.getMonth() - 3))
    //         // this.utilService.setTrace(`# B T ${rDate.toLocaleDateString('en-GB')}<=${this.translateService.instant(`NEW_QUERY.POPULATION.${Object.keys(pItem)}`)}(days)`);
    //         // this.utilService.setTrace(`>A< ACTUALISE  >I<  ${this.ipn} >O< @1@`);
    //         this.utilService.setTrace(`>A< svc_objsave`);
    //         this.utilService.setTrace(`>I< ${sVal[0]}`);
    //         this.utilService.setTrace(`>O<`);
    //         this.utilService.setTrace(`#H ${moment.utc().format("HH:mm:ss")}`);
    //       } else if (Object.values(pItem).includes("6")) {
    //         const rDate = new Date(cDate.setMonth(cDate.getMonth() - 6))
    //         // this.utilService.setTrace(`# B T ${rDate.toLocaleDateString('en-GB')}<=${this.translateService.instant(`NEW_QUERY.POPULATION.${Object.keys(pItem)}`)}(days)`);
    //         // this.utilService.setTrace(`>A< ACTUALISE  >I<  ${this.ipn} >O< @1@`);
    //         this.utilService.setTrace(`>A< svc_objsave`);
    //         this.utilService.setTrace(`>I< ${sVal[0]}`);
    //         this.utilService.setTrace(`>O<`);
    //         this.utilService.setTrace(`#H ${moment.utc().format("HH:mm:ss")}`);
    //       } else if (Object.values(pItem).includes("9")) {
    //         const rDate = new Date(cDate.setMonth(cDate.getMonth() - 9))
    //         // this.utilService.setTrace(`# B T ${rDate.toLocaleDateString('en-GB')}<=${this.translateService.instant(`NEW_QUERY.POPULATION.${Object.keys(pItem)}`)}(days)`);
    //         // this.utilService.setTrace(`>A< ACTUALISE  >I<  ${this.ipn} >O< @1@`);
    //         this.utilService.setTrace(`>A< svc_objsave`);
    //         this.utilService.setTrace(`>I< ${sVal[0]}`);
    //         this.utilService.setTrace(`>O<`);
    //         this.utilService.setTrace(`#H ${moment.utc().format("HH:mm:ss")}`);
    //       } else {
    //         const rDate = new Date(cDate.setMonth(cDate.getMonth() - 12))
    //         // this.utilService.setTrace(`# B T ${rDate.toLocaleDateString('en-GB')}<=${this.translateService.instant(`NEW_QUERY.POPULATION.${Object.keys(pItem)}`)}(days)`);
    //         // this.utilService.setTrace(`>A< ACTUALISE  >I<  ${this.ipn} >O< @1@`);
    //         this.utilService.setTrace(`>A< svc_objsave`);
    //         this.utilService.setTrace(`>I< ${sVal[0]}`);
    //         this.utilService.setTrace(`>O<`);
    //         this.utilService.setTrace(`#H ${moment.utc().format("HH:mm:ss")}`);
    //       }
    //     } else {
    //       // this.utilService.setTrace(`# B T ${this.translateService.instant(`NEW_QUERY.POPULATION.${Object.keys(pItem)}`)}=[${Object.values(pItem)}]`);
    //     }
    //   }
    // }

    if (this.previewData.characterList) {
      // this.utilService.setTrace(`>A< ACTUALISE  >I<  ${this.ipn} >O< @1@`);
      this.utilService.setTrace(`>A< svc_xeq`);
      this.utilService.setTrace(`>I< ${sVal[1]}`);
      this.utilService.setTrace(`>O< un100000@${outputPops}@`.replace('un100000', 'un10000' + (Number(traceCount) - 2).toString()));
      this.utilService.setTrace(`#CPreReq.Executer`);
      this.utilService.setTrace(`#Condition:`);
      for (let index = 1; index < 4; index++) {
        const element = this.previewData.characterList['characterListType' + index];
        let indexCount = 0;
        for (const key of Object.keys(element)) {
          for (const item of element[key]) {
            if (indexCount === 0) {
              this.utilService.setTrace(`# B ${item.sauf ? 'S' : 'T'} ${item.name}=(${item.value})`);
            } else {
              this.utilService.setTrace(`#  ${item.sauf ? 'S' : 'T'} ${item.name}=(${item.value})`);
            }
            // console.log('item', item);
            indexCount = indexCount + 1;
          }
        }
        if (index === 3) {
          // this.utilService.setTrace(`>A< ACTUALISE  >I<  ${this.ipn} >O< @1@`);
          this.utilService.setTrace(`#H ${moment.utc().format("HH:mm:ss")}`);
          this.utilService.setTrace(`>A< svc_objsave`);
          this.utilService.setTrace(`>I< ${sVal[2]}`);
          this.utilService.setTrace(`>O<`);
          // this.utilService.setTrace(`>A< ACTUALISE  >I<  ${this.ipn} >O< @1@`);
          this.utilService.setTrace(`#H ${moment.utc().format("HH:mm:ss")}`);
          this.utilService.setTrace(`>A< svc_xeq`);
          this.utilService.setTrace(`>I< ${sVal[3]}`);
          this.utilService.setTrace(`>O< un100001@${outputTotalRows}@`.replace('un100001', 'un10000' + (Number(traceCount) - 1).toString()));
          this.utilService.setTrace(`#CReq.Executer`);
          this.utilService.setTrace(`#Condition:`);
          this.utilService.setTrace(`#H ${moment.utc().format("HH:mm:ss")}`);
          // this.utilService.setTrace(`>A< ACTUALISE  >I<  ${this.ipn} >O< @1@`);
          this.utilService.setTrace(`>A< svc_objsave`);
          this.utilService.setTrace(`>I< ${sVal[4]}`);
          this.utilService.setTrace(`>O<`);
          this.utilService.setTrace(`#Groupby:`);
        }
      }
    }

    if (this.previewData.group) {
      for (let index = 0; index < this.previewData.group.length; index++) {
        const element = this.previewData.group[index];
        this.utilService.setTrace(`# ${element.name}`);
        if (index === this.previewData.group.length - 1) {
          // this.utilService.setTrace(`>A< ACTUALISE  >I<  ${this.ipn} >O< @1@`);
          this.utilService.setTrace(`#H ${moment.utc().format("HH:mm:ss")}`);
          this.utilService.setTrace(`>A< svc_objsave`);
          this.utilService.setTrace(`>I< ${sVal[5]}`);
          this.utilService.setTrace(`>O<`);
          this.utilService.setTrace(`#H ${moment.utc().format("HH:mm:ss")}`);
          // this.utilService.setTrace(`>A< ACTUALISE  >I<  ${this.ipn} >O< @1@`);
          this.utilService.setTrace(`>A< svc_xeq`);
          this.utilService.setTrace(`>I< ${sVal[6]}`);
          // this.utilService.setTrace(`>A< ACTUALISE  >I<  ${this.ipn} >O< @1@`);
        }
      }

    }
  }

  loadDataExecution(queryParamValue, pagination, randonValue) {
    let queryParam = queryParamValue;
    const traceCount = sessionStorage.getItem('traceCount');
    if (this.num > 0) {
      queryParam = { "inputLists": `${randonValue}`.split('\n'), "region": this.getFavoriteRegion() };
    }
    // this.utilService.setTrace('results-lines');
    // this.utilService.setTrace(queryParam);
    // console.log("this.executeQuery", this.executeQuery);
    this.apiService.getExecuteData(queryParam).subscribe(res => {
      // console.log("res", res)
      let outputHeaders;
      if (res.headers) {

        this.getQueryTrace(res.totalNoOfRows, res.popRows);
        // console.log('resssss', res);
        for (let index = 1; index < res.headers.length; index++) {
          const element = res.headers[index];
          if (index === 1) {
            outputHeaders = `${element.columnLabel}@${element.maxCharColNo}`;
          } else if (index === res.headers.length - 1) {
            this.utilService.setTrace(`>O< un100002@${res.totalNoOfRows}@${res.noOfOutColumns}@${outputHeaders}@${element.columnLabel}@${element.maxCharColNo}@@`.replace('un100002', 'un10000' + (Number(traceCount)).toString()));
            this.utilService.setTrace(`#H ${moment.utc().format("HH:mm:ss")}`);
          } else {
            outputHeaders = `${outputHeaders}@${element.columnLabel}@${element.maxCharColNo}`;
          }
        }
        if (res.errors && res.errors[0].errorCode) {
          if (res.errors && (res.errors[0].errorCode === 502 || res.errors[0].errorCode === 504) && this.num < 30) {
            this.loadDataExecution(queryParam, pagination, randonValue);
          } else {
            // console.log("error", res.errors, res.errors[0].errorMessage)
            this.blnExecErr.emit(true);
            const dialogRef = this.dialog.open(AlertConfirmationComponent, {
              disableClose: true,
              width: '550px',
              data: { 'display': res.errors[0].errorMessage, isAlert: 'true', type: "group" }
            });

            dialogRef.afterClosed().subscribe(result => {
              if (result === true) {
              }
            })
          }
          // this.toastyService.warning(this.translateService.instant(`ERROR.TECHNICAL_ERROR_EXECUTE`));
        } else if (res.popRows === 0) {
          this.toastyService.warning(this.translateService.instant(`ERROR.POP_EMPTY`));
        } else if (res.totalNoOfRows === 0) {
          this.toastyService.warning(this.translateService.instant(`ERROR.NO_ELEMENT`));
        } else {
          this.executedData = [];
          this.changeOutput(res, res.headers, res.queryResults, pagination);
        }
      }
    },
      error => {
        this.num = this.num + 1;
        // console.log("error error", error, error.status);
        if (error)
          if (error.status === 502 || error.status === 504) {
            // console.log(this.num, res.errors[0]);
            if (this.num < 30) {
              this.loadDataExecution(queryParam, pagination, randonValue);
            }
          }
      });
  }

  changeOutput(res, headers, output, pagination) {
    let titlelabel, dataLabel;
    // debugger
    if (pagination === 0 && headers !== null && headers.length > 0) {
      this.columns = [];
      headers.forEach(item => {
        let id = item.id;
        if (item.columnLabel) {
          if (item.columnLabel.includes(this.splitChar)) {
            titlelabel = item.columnLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)];
          } else {
            titlelabel = item.columnLabel
          }
          let data = {
            'field': titlelabel,
            'id': item.id,
            'width': titlelabel === 'RowNum' ? 0 : titlelabel.length + 100
          };
          /*** SONAR : Remove this conditional structure or edit its code blocks so that they're not all the same. ****/ 
          // if (this.isTTB) {
          //   // if (id !== `C_${this.detailGroupList.length + 1}`) {
          //   this.columns.push(data);
          //   // }
          // } else {
          //   this.columns.push(data);
          // }
          this.columns.push(data);
        }
      });
      this.totalRows = res.totalNoOfRows;
      this.popRows = res.popRows;
    }
    if (output.length > 0) {
      let dataExecutedData = [];
      output.forEach(item => {
        let obj = {}, objArr = [];
        for (let key in item) {

          // if (key.includes(this.splitChar)) {
          //   dataLabel = key.split(this.splitChar, 2)[this.getLangIndex(this.lang)];
          // } else {
          //   dataLabel = key;
          // }

          /*** SONAR : Remove this conditional structure or edit its code blocks so that they're not all the same. ****/ 
          // if (this.isTTB) {
          //   // if (key !== `C_${this.detailGroupList.length + 1}`) {
          //   objArr.push({ [key]: item[key] });
          //   // }
          // } else {
          //   objArr.push({ [key]: item[key] });
          // }
          objArr.push({ [key]: item[key] });
        }
        obj = Object.assign({}, objArr);
        // this.executedData = this.executedData.concat(obj);
        dataExecutedData = dataExecutedData.concat(obj);
        this.blnData.emit(dataExecutedData.length > 0 ? true : false);
        this.totalRowsData.emit(this.totalRows);
        this.resultColumns.emit(this.columns);
      })

      // console.log("this.executedData-before", this.executedData)
      this.executedData = dataExecutedData;
      // console.log("this.executedData-after", this.executedData)
    }
    if (this.currentPage === 2) {
      // if (this.totalRows > res.noOfLines) {
      this.totalRows = res.noOfLines;
      // }
      this.selectedRow = this.executedData.filter(el => {
        if (el[0].RowNum === this.secondDetailRowNo + 1) {
          return el;
        }
      })[0];
    } else {
      this.selectedRow = this.executedData.filter(el => {
        if (el[0].RowNum === this.detailRowNo + 1) {
          return el;
        }
      })[0];
    }
    // this.setDisplayedColumns();
    if (this.matTableRef) {
      this.setTableResize(this.matTableRef.nativeElement.clientWidth);
    }
    this.debounce = true;
  }

  getColumnValue(item) {
    setTimeout(() => {
      this.displayedColumns.forEach(val => {
        return item[val]
      });
    }, 0);
  }

  getFavoriteRegion() {
    if (this.region === "Europe") {
      return "PIL";
    } else {
      return "PIM";
    }
  }

  scrolled(event: any): void {
    if (!this.checkForEqualArray()) {
      const threshold = 150;
      const position = document.getElementById('scrollFrame').scrollTop + document.getElementById('scrollFrame').offsetHeight;
      const height = document.getElementById('scrollFrame').scrollHeight
      if (position > height - threshold && this.debounce) {
        this.debounce = false;
        this.pagination = 500 + this.pagination;
        if (this.pagination <= this.totalRows) {
          this.toastyService.warning(this.translateService.instant(`ERROR.CLOSE_EXECUTE`));
        }
      }
    } else {
      const threshold = 150;
      const position = document.getElementById('scrollFrame').scrollTop + document.getElementById('scrollFrame').offsetHeight;
      const height = document.getElementById('scrollFrame').scrollHeight
      if (position > height - threshold && this.debounce) {
        this.debounce = false;
        this.pagination = 500 + this.pagination;
        if (this.pagination <= this.totalRows) {
          if (this.currentPage === 1) {
            this.loadData(this.pagination);
          } else if (this.currentPage === 2) {
            this.executeFirstDetailData(this.selectedTab, this.pagination, event);
          }
        }
      }
    }
  }

  clickPagination(p) {
    // console.log("ppppppppp", p);
    this.p = p;
    if (!this.checkForEqualArray()) {
      // const threshold = 150;
      // const position = document.getElementById('scrollFrame').scrollTop + document.getElementById('scrollFrame').offsetHeight;
      // const height = document.getElementById('scrollFrame').scrollHeight
      // if (position > height - threshold && this.debounce) {
      //   this.debounce = false;
      //   this.pagination = 500 + this.pagination;
      //   if (this.pagination  <= this.totalRows) {
      //     this.toastyService.warning(this.translateService.instant(`ERROR.CLOSE_EXECUTE`));
      //   }
      // }
      if ((this.p - 1) * 500 <= this.totalRows) {
        this.pagination = 500;
        this.toastyService.warning(this.translateService.instant(`ERROR.CLOSE_EXECUTE`));
      }
    } else {
      // const threshold = 150;
      // const position = document.getElementById('scrollFrame').scrollTop + document.getElementById('scrollFrame').offsetHeight;
      // const height = document.getElementById('scrollFrame').scrollHeight
      // if (position > height - threshold && this.debounce) {
      //   this.debounce = false;
      //   this.pagination = 500 + this.pagination;
      //   if (this.pagination <= this.totalRows) {
      //     if (this.currentPage === 1) {
      //       this.loadData(this.pagination);
      //     } else if (this.currentPage === 2) {
      //       this.executeFirstDetailData(this.selectedTab, this.pagination, event);
      //     }
      //   }
      // }

      if ((this.p - 1) * 500 <= this.totalRows) {
        if (this.p === 0 || this.p === 1) {
          this.pagination = 0;
        } else {
          this.pagination = 500;
          if (this.p * 500 > this.totalRows) {
            this.pagination = this.totalRows - ((this.p - 1) * 500);
          }
        }
        // this.pagination = 500 + this.pagination;

        if (this.currentPage === 1) {
          this.loadData(this.pagination);
        } else if (this.currentPage === 2) {
          // console.log("this.pagination", this.pagination)
          this.executeFirstDetailData(this.selectedTab, this.pagination, event);
        }
      }
    }
  }

  getSelectedTab(item, list, event) {
    this.p = 1;
    if (!this.checkForEqualArray()) {
      this.toastyService.warning(this.translateService.instant(`ERROR.CLOSE_EXECUTE`));
    } else {
      this.selectedTab = item;
      // this.quantity = item.Qte;
      if (this.selectedRow === undefined && item !== 'global') {
        this.toastyService.warning(this.translateService.instant(`ERROR.SELECT_ROWS`));
      } else {
        this.resetValues();
        if (list === 'vehicle-list') {
          this.currentPage = 2;
          this.executeFirstDetailData(item, 0, event);
        } else if (list === 'vehicle-detail') {
          this.currentPage = 3;
          this.executeSecondDetailPage(item);
        } else if (list === 'vehicle') {
          this.currentPage = 1;
          this.selectedTab = 'global';
          this.loadData(0);
        }
        this.currentResultPage.emit(this.currentPage);
      }
    }
  }

  // getCharacterLength(field) {
  // matTooltip="{{getCharacterLength(data[i][column.field]) ? data[i][column.field] : ''}}" [ngClass]="{'textOverflow' : getCharacterLength(data[i][column.field])}"
  //   if (field.length > 30) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  executeFirstDetailData(item, pagination, event) {
    this.num = 0;
    event.stopPropagation();
    let get = `GET@${this.ipn}@${this.selectedTable}@un100002@0@1@0@`;
    let queryFill = `FIL@${this.ipn}@${this.selectedTable}@un100002@${this.detailRowNo}@${this.ipn}.AFF@`;
    // let detailGet = `GET@${this.ipn}@${this.selectedTable}@un100003@${this.executedData.length}@${pagination === 0 ? 0 : 500}@1@`
    let detailGet = `GET@${this.ipn}@${this.selectedTable}@un100003@${(this.p - 1) * 500}@${pagination === 0 ? 0 : pagination}@1@`
    const timeStamp = Date.now();
    const randomNumber = Math.floor(100000 + Math.random() * 900000);
    let queryParam = { "inputLists": `${timeStamp}-${randomNumber}\n${this.executeQuery}\n${get}${this.detailExecute}\n${queryFill}\n${detailGet}`.split('\n'), "region": this.getFavoriteRegion() };
    this.outputGetEvent.emit(`${get}${this.detailExecute}\n${queryFill}\n${detailGet}`);
    this.num = 0;
    this.executeFirstDetailDataExecution(queryParam, item, `${timeStamp}-${randomNumber}`);
  }

  executeFirstDetailDataExecution(queryParamValue, item, randonValue) {
    let queryParam = queryParamValue;
    if (this.num > 0) {
      queryParam = { "inputLists": `${randonValue}`.split('\n'), "region": this.getFavoriteRegion() };
    }
    // this.utilService.setTrace('results-vehicle-lists');
    // this.utilService.setTrace(queryParam);
    this.apiService.getDetailExecuteData(queryParam).subscribe(res => {
      if (res.errors && res.errors[0].errorCode) {
        if ((res.errors[0].errorCode === 502 || res.errors[0].errorCode === 504) && this.num < 30) {
          this.executeFirstDetailDataExecution(queryParamValue, item, randonValue);
        } else {
          // console.log("error", res.errors, res.errors[0].errorMessage)
          this.blnExecErr.emit(true);
          const dialogRef = this.dialog.open(AlertConfirmationComponent, {
            disableClose: true,
            width: '550px',
            data: { 'display': res.errors[0].errorMessage, isAlert: 'true', type: "group" }
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
            }
          })
        }
      } else {
        this.executedData = [];
        this.changeOutput(res, res.headers, res.vehicleLists, 0);
        this.currentPage = 2;
        this.selectedTab = item;
      }
    },
      error => {
        this.num = this.num + 1;
        if (error)
          if (error.status === 502 || error.status === 504) {
            // console.log(this.num, res.errors[0]);
            if (this.num < 30) {
              this.executeFirstDetailDataExecution(queryParamValue, item, randonValue);
            }
          }
      });
  }

  executeSecondDetailPage(item) {
    this.num = 0;
    let detailQueryParam = this.getDataBasedOnTable(this.secondDetailRowNo);
    let queryFill = `FIL@${this.ipn}@${this.selectedTable}@un100002@${this.detailRowNo}@${this.ipn}.AFF@`;
    let detailGet = `GET@${this.ipn}@${this.selectedTable}@un100003@0@0@0@`;
    const timeStamp = Date.now();
    const randomNumber = Math.floor(100000 + Math.random() * 900000);
    let queryParam = { "inputLists": `${timeStamp}-${randomNumber}\n${this.executeQuery}${this.detailExecute}\n${queryFill}\n${detailGet}\n${detailQueryParam}`.split('\n'), "region": this.getFavoriteRegion() };
    this.outputGetEvent.emit(`${this.detailExecute.replace('\n', '')}\n${queryFill}\n${detailGet}\n${detailQueryParam}`);
    this.num = 0;
    this.executeSecondDetailPageExecution(queryParam, item, `${timeStamp}-${randomNumber}`);
  }

  executeSecondDetailPageExecution(queryParamValue, item, randonValue) {
    let queryParam = queryParamValue;
    if (this.num > 0) {
      queryParam = { "inputLists": `${randonValue}`.split('\n'), "region": this.getFavoriteRegion() };
    }
    this.num = this.num + 1;
    // this.utilService.setTrace('results-vehicle-details');
    // this.utilService.setTrace(queryParam);
    this.apiService.getVehicleDetailExecuteData(queryParam).subscribe(res => {
      if (res.errors && res.errors[0].errorCode) {
        if (res.errors && (res.errors[0].errorCode === 502 || res.errors[0].errorCode === 504) && this.num < 30) {
          this.executeSecondDetailPageExecution(queryParam, item, randonValue);
        } else {
          // console.log("error", res.errors, res.errors[0].errorMessage)
          this.blnExecErr.emit(true);
          const dialogRef = this.dialog.open(AlertConfirmationComponent, {
            disableClose: true,
            width: '550px',
            data: { 'display': res.errors[0].errorMessage, isAlert: 'true', type: "group" }
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
            }
          })
        }
      } else {
        this.commonCriteria = this.changeData(res, res.vehicleDetails[0], 0);
        this.initialCriteria = this.changeData(res, res.vehicleDetails[1], 1);
        this.semiClearCurrent = this.changeData(res, res.vehicleDetails[2], 2);
        this.semiClearInitial = this.changeData(res, res.vehicleDetails[3], 3);
        let data = {
          'commonCriteria': this.commonCriteria,
          'initialCriteria': this.initialCriteria,
          'semiClearCurrent': this.semiClearCurrent,
          'semiClearInitial': this.semiClearCurrent
        }
        this.emitSecondPageData.emit(data);
        // this.makeColorChanges();
        this.currentPage = 3;
        this.selectedTab = item;
      }
    },
      error => {
        this.num = this.num + 1;
        if (error)
          if (error.status === 502 || error.status === 504) {
            // console.log(this.num, res.errors[0]);
            if (this.num < 30) {
              this.executeSecondDetailPageExecution(queryParam, item, randonValue);
            }
          }
      });
  }

  changeData(res, array, num) {
    let perChunk = 10;
    let result = [];
    if (array.length) {
      result = array.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk)

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []
        }
        let data = {
          'name': item,
          'isEqual': false
        }
        let arr;
        if (num === 0) {
          arr = res.vehicleDetails[1];
        } else if (num === 1) {
          arr = res.vehicleDetails[0];
        } else if (num === 2) {
          arr = res.vehicleDetails[3];
        } else {
          arr = res.vehicleDetails[2]
        }
        let changedData = this.makeColorChanges(data, arr);
        resultArray[chunkIndex].push(changedData);

        return resultArray
      }, []);
    }
    return result;
  }

  makeColorChanges(data, arr) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === data.name) {
        return data;
      }
    }
    data.isEqual = true;
    return data;
  }

  getDataBasedOnTable(rowNo) {
    let queryParam;
    if (this.selectedTable === 'BCV' || this.selectedTable === 'VEH') {
      queryParam = `VEH@${this.ipn}@${this.selectedTable}@un100003@${rowNo}@I_DefIndus@`;
      queryParam += "\n";
      queryParam += `VEH@${this.ipn}@${this.selectedTable}@un100003@${rowNo}@I_DefIndusIni@`;
      queryParam += "\n";
      queryParam += `VEH@${this.ipn}@${this.selectedTable}@un100003@${rowNo}@C_DivCom@`;
      queryParam += "\n";
      queryParam += `VEH@${this.ipn}@${this.selectedTable}@un100003@${rowNo}@C_DivComIni@`;
    } else if (this.selectedTable === 'CDE') {
      queryParam = `VEH@${this.ipn}@${this.selectedTable}@un100003@${rowNo}@I_OptionSCR@`
    } else if (this.selectedTable === 'CARNETS' || this.selectedTable === 'PDFV' || this.selectedTable === 'PDFT') {
      queryParam = `VEH@${this.ipn}@${this.selectedTable}@un100003@${rowNo}@I_DefIndus@`;
    }
    return queryParam;
  }

  // nextPage(type) {
  //   if (!this.checkForEqualArray()) {
  //     this.toastyService.warning(this.translateService.instant(`ERROR.CLOSE_EXECUTE`));
  //   } else {
  //     this.resetValues();
  //     if (type === 'vehicle-list') {
  //       this.currentPage = 2;
  //       this.executeFirstDetailData(0);
  //     } else if (type === 'vehicle-detail') {
  //       this.currentPage = 3;
  //       let row = this.selectedRow !== undefined ? this.selectedRow.RowNum === 0 ? 0 : this.selectedRow.RowNum - 1 : 0;
  //       this.executeSecondDetailPage(row);
  //     }
  //   }
  // }

  // prevPage(type) {
  //   if (!this.checkForEqualArray()) {
  //     this.toastyService.warning(this.translateService.instant(`ERROR.CLOSE_EXECUTE`));
  //   } else {
  //     this.resetValues();
  //     if (type === 'vehicle-list') {
  //       this.currentPage = 2;
  //       this.executeFirstDetailData(0);
  //     } else if (type === 'vehicle') {
  //       this.currentPage = 1;
  //       this.loadData(0);
  //     }
  //   }
  // }

  moveRight() {
    if (!this.checkForEqualArray()) {
      this.toastyService.warning(this.translateService.instant(`ERROR.CLOSE_EXECUTE`));
    } else {
      let noVal = this.secondDetailRowNo + 1;
      if (noVal >= this.quantity) {
        this.toastyService.warning(this.translateService.instant(`ERROR.LAST_VEHICLE`));
      } else {
        this.secondDetailRowNo = this.secondDetailRowNo + 1 >= this.quantity ? this.quantity : this.secondDetailRowNo + 1;
        this.executeSecondDetailPage(this.selectedTab);
      }
    }
  }

  moveLeft() {
    if (!this.checkForEqualArray()) {
      this.toastyService.warning(this.translateService.instant(`ERROR.CLOSE_EXECUTE`));
    } else {
      let noVal = this.secondDetailRowNo - 1;
      if (noVal < 0) {
        this.toastyService.warning(this.translateService.instant(`ERROR.FIRST_VEHICLE`));
      } else {
        this.secondDetailRowNo = this.secondDetailRowNo - 1 < 0 ? 0 : this.secondDetailRowNo - 1;
        this.executeSecondDetailPage(this.selectedTab);
      }
    }
  }

  // setStep(index: number) {
  //   this.step = index;
  // }

  resetValues() {
    this.columns = [];
    this.executedData = [];
    this.displayedColumns = [];
  }

  checkForEqualArray() {
    this.execute = true;
    for (let i = 0; i <= Object.keys(this.characterList.characterListType1).length - 1; i++) {
      if (!this.checkArrayEqual(this.detailCharacterList.characterListType1['group' + i], this.characterList.characterListType1['group' + i])) {
        this.execute = false;
      }
    }
    for (let i = 0; i <= Object.keys(this.characterList.characterListType2).length - 1; i++) {
      if (!this.checkArrayEqual(this.detailCharacterList.characterListType2['group' + i], this.characterList.characterListType2['group' + i])) {
        this.execute = false;
      }
    }
    for (let i = 0; i <= Object.keys(this.characterList.characterListType3).length - 1; i++) {
      if (!this.checkArrayEqual(this.detailCharacterList.characterListType3['group' + i], this.characterList.characterListType3['group' + i])) {
        this.execute = false;
      }
    }
    if (!this.checkArrayEqual(this.detailGroupList, this.groupList)) {
      this.execute = false;
    }
    if (!this.checkArrayEqual(this.detailVueList, this.vueList)) {
      this.execute = false;
    }
    return this.execute;
  }

  checkArrayEqual(a1, a2) {
    const objectsEqual = (o1, o2) =>
      typeof o1 === 'object' && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length
        && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
        : o1 === o2;

    const arraysEqual = a1 !== undefined ? a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx])) : false;

    return arraysEqual;
  }

  onScrollingFinished() {
    // this.loadDataPagination("test");
  }

  loadDataPagination() {
    if (this.columns.length > 60) {
      const position = document.getElementById('scrollFrame').scrollTop + document.getElementById('scrollFrame').offsetHeight;
      const height = document.getElementById('scrollFrame').scrollHeight;
      const xposition = document.getElementById('scrollFrame').scrollLeft + document.getElementById('scrollFrame').offsetWidth;
      const xwidth = document.getElementById('scrollFrame').scrollWidth;
      console.log("asdasdasdasd", position, height, xposition, xwidth);
      if (height <= position) {
        // if (position >= height) {
        //   if (this.displayedData.length >= this.executedData.length) {
        //     return false;
        //   }
        const remainingLength = Math.min(20, this.executedData.length - this.displayedData.length);
        this.displayedData.push(...this.executedData.slice(this.displayedData.length, this.displayedData.length + remainingLength));
        return true;
        // }
      }
      if (xwidth <= xposition) {
        // if (position >= height) {
        //   if (this.displayedData.length >= this.executedData.length) {
        //     return false;
        //   }
        const remainingLength = Math.min(20, this.columns.length - this.displayedColumns.length);
        this.displayedColumns.push(...this.columns.slice(this.displayedColumns.length, this.displayedColumns.length + remainingLength));
        return true;
        // }
      }
    }
  }

  // @HostListener('scroll', ['$event'])
  // scrollHandler(event) {
  //   console.debug("Scroll Event");
  // }
}
