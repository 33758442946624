
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthentificationComponent } from './authentification.component';
import { UnauthorizedAccessComponent } from './unauthorized-access.component';
import { AppMaterialModule } from '../../material-module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    AppMaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // useFactory: HttpLoaderFactory,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  declarations: [AuthentificationComponent, UnauthorizedAccessComponent],
  exports: [AuthentificationComponent],
  entryComponents: [AuthentificationComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthentificationModule { }
