import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-flag-grouping',
  templateUrl: './flag-grouping.component.html',
  styleUrls: ['./flag-grouping.component.scss']
})
export class FlagGroupingComponent implements OnInit {
  @Input() selectedListItem: string;
  @Input() sommeSelected: boolean;
  @Output() setSomme = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }


  submit() {
    const value = {
      value: this.sommeSelected,
      // value: this.groupingText.length ? this.groupingText : this.criteria,
      blnCancel: false
    };
    this.setSomme.emit(value);
  }

  cancel() {
    const value = {
      value: this.sommeSelected,
      // value: this.groupingText.length ? this.groupingText : this.criteria,
      blnCancel: true
    };
    this.setSomme.emit(value);
  }

}
