import { Injectable, Inject } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { finalize, mergeMap, delay, retryWhen } from "rxjs/operators";
import { LoaderService } from '../loader/loader.service';
import { AlertConfirmationComponent } from 'src/app/component/alert-confirmation/alert-confirmation.component';
import { UtilService } from '../util/util.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptService implements HttpInterceptor {

  constructor(private router: Router,
    private oauthService: OAuthService, readonly loaderService: LoaderService, readonly utilService: UtilService) {
  }

  /**
   * Intercept request to handle error cases
   * @param request
   * @param next
   */
  private loaderCount: number = 0;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // request.

    /**
     * continues request execution
     */
    //request = request.clone({ url: `${this.baseUrl}/${request.url}` });
    // request = request.clone({
    //   setHeaders: {
    //     Authorization: `Bearer ${this.oidcSecurityService.getToken()}`
    //   }
    // });

    if (this.loaderCount == 0) {
      this.loaderService.show();
    }
    this.loaderCount++;
    return next.handle(request).pipe(
      // finalize(() => {
      //   this.loaderCount--;
      //   if (this.loaderCount == 0) {
      //     this.loaderService.hide();
      //   }

      // })
      retryWhen((error) => {
        return error.pipe(
          mergeMap((error, index) => {
            // console.log("interceptor error", error, error.error.errors[0].errorMessage);
            if(error.error.errors){
            if (error.error.errors[0].errorMessage === "The received access_token is expired.") {

              // const lastURL = localStorage.getItem("lURL");
              // if (!lastURL) {
              //   const claims = this.oauthService.getIdentityClaims();
              //   // console.log("this.difference(new Date(claims['exp'] * 1000), new Date()) < 28", this.difference(new Date(claims['exp'] * 1000), new Date()) < 28, new Date(claims['exp'] * 1000), new Date())
              //   // if (this.difference(new Date(claims['exp'] * 1000), new Date()) < 28) {
              //   if (new Date() > new Date(claims['exp'] * 1000)) {
              //     // console.log("datediff", this.difference(new Date(claims['exp'] * 1000), new Date()));
              //     const store = window.sessionStorage;
              //     Object.keys(store).forEach((key) => {
              //       localStorage[key] = store.getItem(key);
              //     });
              sessionStorage.setItem("lURL", window.location.pathname);
              this.utilService.setExpired(true);
              alert(this.utilService.getTranslateName(`COMMON.sessionexp`));
              // const dialogRef = this.dialog.open(AlertConfirmationComponent, {
              //   disableClose: true,
              //   width: '550px',
              //   data: { 'display': this.utilService.getTranslateName(`COMMON.sessionexp`), 'isAlert': true, 'isText': false }
              // });

              // dialogRef.afterClosed().subscribe(result => {
              //   // this.oauthService.logOut();

              this.router.navigate([""]);
              // window.location.reload();
              // })
              // }
              // }

            }
          }
            throw error;
          })
        )
      }
      ),

      finalize(() => {
        this.loaderCount--;
        if (this.loaderCount == 0) {
          this.loaderService.hide();
        }

      })

    );


    return next.handle(request).pipe(catchError((error, caught) => {
      // send the code error received from back end to translation service in a toast
      if (error.status === 401 || error.status === 403) {
        if (request.url !== '/api/v1/user') {
          this.router.navigate(['']);
        } else {
          return throwError(error);
        }
      } else if (request.responseType === 'json' && error.error instanceof Array) {
        this.handleJsonError(error);
      } else {
        this.handleError(error);
      }
      return of(error);

    }) as any);
  }

  /**
   * manage errors
   * @param err
   * @returns {any}
   */
  private handleError(error: HttpErrorResponse): Observable<any> {

    // // handle error and rethrow
    // const toastOptions: ToastOptions = {
    //   title: error.name,
    //   msg: error.message,
    //   showClose: true,
    //   timeout: 5000
    // };

    // this.toastyService.error(toastOptions);

    throw error;
  }

  /**
   * manage errors
   * @param err
   * @returns {any}
   */
  private handleJsonError(error: HttpErrorResponse): Observable<any> {
    // handle error and rethrow
    // const toastOptions: ToastOptions = {
    //   title: this.translatePipe.transform(error.error[0].code),
    //   msg: this.translatePipe.transform(error.error[0].code + '_DSC', error.error[0].variables),
    //   showClose: true,
    //   timeout: 5000
    // };

    // this.toastyService.error(toastOptions);

    throw error;
  }

}
