import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// var moment = require('moment-timezone');
import 'moment/min/locales.min';
import  moment from 'moment';

@Component({
    selector: 'app-preview-dialog',
    templateUrl: './preview-dialog.component.html',
    styleUrls: ['./preview-dialog.component.scss'],
  })
  export class PreviewDialogComponent {
    previewData;
    constructor(
      public dialogRef: MatDialogRef<PreviewDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) {
        this.previewData = data.value;
      }

    copyInputMessage() {
      var elm = document.getElementById('dialog_content');    
      if(window.getSelection) {    
        var selection = window.getSelection();
        var range = document.createRange();
        range.selectNodeContents(elm);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("Copy");
      }
    }

    getLocalTime() {
      return moment.utc().format("DD-MM-YYYY HH:mm:ss");
    }

    characterListTypeStatus(num) {
      if (this.previewData.characterList && this.previewData.characterList['characterListType' + num]) {
        for (let key in this.previewData.characterList['characterListType' + num]) {
          if (this.previewData.characterList['characterListType' + num][key].length > 0) {
            return true;
          }
        }
      }
      return false;
    }

    // keyPress(event) {
    //   alert(event);
    //   console.log('eeeeeee', event.key);
    // }
    characterListTypeSeprateStatus(num) {
      if (num < 3) {
        if (num === 1) {
          if (this.previewData.characterList['characterListType2']['group0'].length > 0 || this.previewData.characterList['characterListType3']['group0'].length > 0) {
            return true;
          }
        } else {
          if (this.previewData.characterList['characterListType' + (num + 1)]['group0'].length > 0) {
            return true;
          }
        }
      }
      return false;
    }

    getObjectKey(item) {
      return Object.keys(item);
    }

    getObjectValue(item) {
      return Object.values(item);
    }
  
    closePanel(): void {
      this.dialogRef.close();
    }
  
  }