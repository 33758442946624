import { Directive, OnInit, Renderer2, Input, ElementRef } from "@angular/core";

@Directive({
    selector: "[resizeColumn]"
})
export class ResizeColumnDirective implements OnInit {
    @Input("resizeColumn") resizable: boolean;

    @Input() index: number;

    private startX: number;

    private startWidth: number;

    private column: HTMLElement;

    private table: HTMLElement;

    private pressed: boolean;

    private columnWidthDetails = [];

    private tableOffsetWidth: number;

    private colIndex: number
    tableStartOffsetWidth: number;

    constructor(private renderer: Renderer2, private el: ElementRef) {
        this.column = this.el.nativeElement;
    }

    ngOnInit() {
        if (this.resizable) {
            if (this.column['cellIndex'] !== 0) {
                const row = this.renderer.parentNode(this.column);
                const thead = this.renderer.parentNode(row);
                this.table = this.renderer.parentNode(thead);

                this.tableStartOffsetWidth = this.table.offsetWidth;

                const resizer = this.renderer.createElement("th");
                this.renderer.addClass(resizer, "resize-holder");
                this.renderer.appendChild(this.column, resizer);
                this.renderer.listen(resizer, "mousedown", this.onMouseDown);
                this.renderer.listen(this.table, "mousemove", this.onMouseMove);
                this.renderer.listen("document", "mouseup", this.onMouseUp);
            }
        }
    }

    onMouseDown = (event: MouseEvent) => {
        this.pressed = true;
        this.startX = event.pageX;
        this.startWidth = this.column.offsetWidth;
        this.tableOffsetWidth = this.table.offsetWidth;
        this.colIndex = this.column['cellIndex'];
        const offset = 100;


        for (let index = 0; index < 100; index++) {
            // const element = array[index];

        }

        if (this.pressed && event.buttons) {
            this.renderer.addClass(this.table, "resizing");
            // Calculate width of column
            let width =
                this.startWidth + (event.pageX - this.startX - offset);


            // const tableCells = Array.from(this.table.querySelectorAll(".mat-row")).map(
            //     (row: any) => row.querySelectorAll(".mat-cell").item(this.index)
            // );

            // // Set table header width
            // if (this.column['cellIndex'] !== 0) {
            //     this.renderer.setStyle(this.column, "width", `${width}px`);
            // }

            // // // Set table cells width
            // // for (const cell of tableCells) {
            // //     if (cell.cellIndex !== 0) {
            // //         this.renderer.setStyle(cell, "width", `${width}px`);
            // //     }
            // // }
        }
    };

    onMouseMove = (event: MouseEvent) => {
        const offset = 100;
        if (this.pressed && event.buttons) {
            this.renderer.addClass(this.column, "resizing");

            // Calculate width of column
            let width =
                this.startWidth + (event.pageX - this.startX);

            // const tableCells = Array.from(this.table.querySelectorAll(".mat-row")).map(
            //     (row: any) => row.querySelectorAll(".mat-cell").item(this.index)
            // );

            // Set table header width
            if (this.column['cellIndex'] === this.colIndex) {
                this.renderer.setStyle(this.column, "width", `${width}px`);
                // console.log("this.column.offsetWidth < width", this.startWidth, width, this.column['cellIndex'])
                if (this.startWidth < width) {
                    this.renderer.setStyle(this.table, "width", `${this.tableOffsetWidth + (width - this.startWidth)}px`);
                } else if (this.tableStartOffsetWidth < this.tableOffsetWidth - (this.startWidth - width)) {
                    this.renderer.setStyle(this.table, "width", `${this.tableOffsetWidth - (this.startWidth - width)}px`);
                }
            }

            // // Set table cells width
            // for (const cell of tableCells) {
            //     if (cell.cellIndex !== 0) {
            //         this.renderer.setStyle(cell, "width", `${width}px`);
            //     }
            // }
        }
    };

    onMouseUp = (event: MouseEvent) => {
        if (this.pressed) {
            this.pressed = false;
            this.renderer.removeClass(this.table, "resizing");
        }
    };
}
