import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from '../../app-routing.module';
import { AppMaterialModule } from '../../material-module';
import { QueryMainScreenComponent } from './main-screen/main-screen.component';
import { GoupingListComponent } from './grouping/grouping-list/grouping-list.component';
import { DateGroupingComponent } from './grouping/date-grouping/date-grouping.component';
import { FlagGroupingComponent } from './grouping/flag-grouping/flag-grouping.component';
import { DelayGroupingComponent } from './grouping/delay-grouping/delay-grouping.component';

import { DayMaskDirective } from '../directives/day-mask.directive';
import { WeekMaskDirective } from '../directives/week-directive';
import { YearMaskDirective } from '../directives/year-mask.directive';
import { DayWeekMaskDirective } from '../directives/day-week-mask.directive';
import { DisableControlDirective } from '../directives/disableControl.directive';
import { PreviewDialogComponent } from './preview-dialog/preview-dialog.component';
import { CharacterListComponent } from './grouping/character-list/character-list.component';
import { characterGroupingList } from './grouping/character-grouping-list/character-grouping-list.component';
import { CharacterFlagListComponent } from './grouping/character-flag-list/character-flag-list.component';
import { DelayConditionComponent } from './grouping/delay-condition/delay-condition.component';
import { CharacterNumberListComponent } from './grouping/character-number-list/character-number-list.component';
import { CreatePrivateGroupComponent } from './grouping/create-private-group/create-private-group.component';
import { ColumnSegmentThreeComponent } from './column-segment-three/column-segment-three.component';
import { SaveQueryNameComponent } from './save-query-name/save-query-name.component';
import { ExportFileComponent } from './export-file/export-file.component';
// export function HttpLoaderFactory(http: HttpClient) {
//     return new TranslateHttpLoader(http);

import { ResizeColumnDirective } from 'src/app/resize-column.directive';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        NgxPaginationModule,
        ReactiveFormsModule,
        AppMaterialModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                // useFactory: HttpLoaderFactory,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        QueryMainScreenComponent,
        GoupingListComponent,
        DateGroupingComponent,
        FlagGroupingComponent,
        DelayGroupingComponent,
        PreviewDialogComponent,
        SaveQueryNameComponent,
        DayMaskDirective,
        WeekMaskDirective,
        YearMaskDirective,
        DayWeekMaskDirective,
        DisableControlDirective,
        CharacterListComponent,
        characterGroupingList,
        CharacterFlagListComponent,
        DelayConditionComponent,
        CharacterNumberListComponent,
        CreatePrivateGroupComponent,
        ColumnSegmentThreeComponent,
        ExportFileComponent,
        ResizeColumnDirective,

    ], exports: [
        DayMaskDirective,
        WeekMaskDirective,
        YearMaskDirective,
        DisableControlDirective
    ],
    entryComponents: [
        PreviewDialogComponent,
        SaveQueryNameComponent,
        ExportFileComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class QueryScreenModule { }