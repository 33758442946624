import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-confirmation',
  templateUrl: './alert-confirmation.component.html',
  styleUrls: ['./alert-confirmation.component.scss']
})
export class AlertConfirmationComponent  {

  bln: boolean;
  textVal: string = '';

  constructor(public dialogRef: MatDialogRef<AlertConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  

  getValue() {
    if (!this.data.isText) {
      return true;
    } else {
      return this.textVal.trim();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
