import { Component, Input, Output, EventEmitter, OnChanges, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ToastyService } from 'ng2-toasty';

import { BackendApiService } from '../../../../services/backend-api/backend-api.service';
import { UtilService } from '../../../../services/util/util.service';

@Component({
  selector: 'app-delay-condition',
  templateUrl: './delay-condition.component.html',
  styleUrls: ['./delay-condition.component.scss']
})
export class DelayConditionComponent implements OnInit {
  @Input() delayInput;
  @Input() daysData;
  @Input() delayCheckVal;
  @Output() selectedIndex = new EventEmitter();
  @Output() delayOutput = new EventEmitter();

  profile = this.utilService.getUserProfile();
  ipn;

  selectedOption = 'Jours';
  selectedDiffOption = 0;
  ddValue: string;
  isSubmitted: boolean = false;
  delayOptionSelected = 0;

  diffTextValue = 0;
  maxLen = 3;

  delayOptions = [{ name: 'DAYS', value: 'Jours', selected: false },
  { name: 'WORKING_DAYS', value: 'Jour ouvres', selected: false },
  { name: 'WEEK', value: 'semaine', selected: false },
  { name: 'CALENDAR', value: 'Mois Calendaire', selected: false },
  { name: 'INDUSTRIAL', value: 'Mois Industriel', selected: false },
  { name: 'Y', value: 'year', selected: false },
  { name: 'FF', value: 'Free Format', selected: false }];

  delayDiffOptions = [{ name: 'EQUAL', value: '=', selected: false },
  { name: 'DIFFERENT', value: '<>', selected: false },
  { name: 'INFERIOR_EQUAL', value: '<=', selected: false },
  { name: 'SUPERIOR_EQUAL', value: '>=', selected: false }];

  constructor(readonly apiService: BackendApiService, readonly utilService: UtilService, readonly toastyService: ToastyService) {
    this.ipn = this.profile['login']; // sessionStorage.getItem("ipn");
    this.diffTextValue = null;
  }

  ngOnInit() {
  }

  ngOnChanges() {
    let i = 0;
    let j = 0;
    this.ddValue = undefined;
    if (this.delayInput.value) {

      this.diffTextValue = this.delayInput.value[1];

      if (this.delayInput.value.length >= 4 && this.delayInput.value[2] === 'Day' || this.delayInput.value[2] === 'Jour') {

        for (const item of this.delayDiffOptions) {
          if (item.value === this.delayInput.value[0]) {
            item.selected = true;
            this.selectedDiffOption = j;
            break;
          }
          j = j + 1;
        }

        for (const item of this.delayOptions) {
          if (item.value === 'Free Format') {
            item.selected = true;
            this.selectedOption = item.value;
            this.ddValue = this.delayInput.value[3];
            this.isSubmitted = true;
            this.delayOptionSelected = i;
            this.selectOption(i);
            break;
          }
          i = i + 1;
        }
      } else {

        for (const item of this.delayDiffOptions) {
          if (item.value === this.delayInput.value[0]) {
            item.selected = true;
            this.selectedDiffOption = j;
            break;
          }
          j = j + 1;
        }

        if (this.delayInput.value.length !== 4) {
          for (const item of this.delayOptions) {
            if (`${this.utilService.getTranslateName(`NEW_QUERY.DELAY_LIST.${item.name}`)}` === this.delayInput.value[2]) {
              item.selected = true;
              this.selectedOption = item.value;
              this.isSubmitted = true;
              this.delayOptionSelected = i;
              this.selectOption(i);
              break;
            }
            i = i + 1;
          }
        } else {
          for (const item of this.delayOptions) {
            if (`${this.utilService.getTranslateName(`NEW_QUERY.DELAY_LIST.${item.name}`)}` === `${this.delayInput.value[2]} ${this.delayInput.value[3]}`) {
              item.selected = true;
              this.selectedOption = item.value;
              this.isSubmitted = true;
              this.delayOptionSelected = i;
              this.selectOption(i);
              break;
            }
            i = i + 1;
          }
        }
      }
      if (this.selectedDiffOption === 0) {
        this.delayDiffOptions[0].selected = true;
      }
    } else {
      if (this.selectedDiffOption === 0) {
        this.delayDiffOptions[0].selected = true;
      }
    }
  }

  selectDiffOption(index) {
    this.selectedDiffOption = index;
  }

  selectOption(index) {
    const previousIndex = this.delayOptionSelected;
    this.delayOptionSelected = index;
    // console.log('delayOptionSelected', this.delayOptionSelected, previousIndex);
    if (index === 2 || index === 3 || index === 4) {
      this.maxLen = 2;
    } else {
      this.maxLen = 3
    }

    // if (this.diffTextValue === null || this.diffTextValue < 0) {
    //   if (this.delayOptionSelected === 2 || this.delayOptionSelected === 3 || this.delayOptionSelected === 4) {
    //     this.diffTextValue = parseInt(this.diffTextValue.toString().slice(0, this.maxLen));
    //   } else {
    //     this.diffTextValue = parseInt(this.diffTextValue.toString().slice(0, this.maxLen));
    //   }
    // } else {
    //   if (this.delayOptionSelected === 2 || this.delayOptionSelected === 3 || this.delayOptionSelected === 4) {
    //     this.diffTextValue = parseInt(this.diffTextValue.toString().slice(0, this.maxLen));
    //   } else {
    //     this.diffTextValue = parseInt(this.diffTextValue.toString().slice(0, this.maxLen));
    //   }
    // }

    const val = { "currentIndex": index, "previousIndex": previousIndex }
    this.selectedIndex.emit(val);
  }

  checkSelectedCheckboxOptions(list) {
    if (list.selected && this.delayInput.date1 !== '' && this.delayInput.date2 !== '') {
      this.isSubmitted = true;
      this.ddValue = list.value;
    }

    this.delayOptions.some(delay => {
      if (delay.name !== list.name) {
        delay.selected = false;
      }
    });
  }

  isLeapYear(year) {
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
  }

  daysOfAYear(year) {

    return this.isLeapYear(year) ? 366 : 365;
  }

  lastWeeksInYear(year) {
    return Math.ceil(this.daysOfAYear(year) / 7);
  }

  maxLengthCheck(object) {
    // if (this.diffTextValue === null || this.diffTextValue < 0) {
    //   if (this.delayOptionSelected === 2 || this.delayOptionSelected === 3 || this.delayOptionSelected === 4) {
    //     this.maxLen = 3;
    //   } else {
    //     this.maxLen = 4;
    //   }
    // } else {
    //   if (this.delayOptionSelected === 2 || this.delayOptionSelected === 3 || this.delayOptionSelected === 4) {
    //     this.maxLen = 2;
    //   } else {
    //     this.maxLen = 3;
    //   }
    // }
    // object.target.value = Math.abs(object.target.value);
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
      this.diffTextValue = object.target.value;
    }
    console.log("diffTextValue", this.diffTextValue)
  }

  submit() {
    let retValue, formatVal;
    if (this.diffTextValue === null) {
      this.diffTextValue = 0;
    }
    const curYearVal = new Date().getFullYear() + 10;
    this.delayOptions.forEach(el => {
      if (this.selectedOption === el.value) {
        formatVal = `${this.utilService.getTranslateName(`NEW_QUERY.DELAY_LIST.${el.name}`)}`;
        if (el.value === 'Free Format') {
          retValue = `${this.utilService.getTranslateName(`NEW_QUERY.DELAY_LIST.${el.name}`)}` + ' ' + this.ddValue;
        } else {
          retValue = `${this.utilService.getTranslateName(`NEW_QUERY.DELAY_LIST.${el.name}`)}`;
          ;
        }
      }
    })
    if (this.delayCheckVal === 2) {
      if (this.delayOptionSelected === 0 || this.delayOptionSelected === 1 || this.delayOptionSelected === 6) {
        let val = this.delayInput.date1.split('/');
        const dateVal = new Date(`${val[2]}/${val[1]}/01`);
        const lastDayOfMonth = new Date(dateVal.getFullYear(), dateVal.getMonth() + 1, 0);
        if (parseInt(val[0]) > lastDayOfMonth.getDate() || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Date");
          return;
        } else if (parseInt(val[1]) > 12 || parseInt(val[1]) < 1) {
          this.toastyService.warning("Invalid Month");
          return;
        } else if (val[2].length !== 4 || parseInt(val[2]) > curYearVal || parseInt(val[2]) < 1) {
          this.toastyService.warning("Invalid Year");
          return;
        }
      } else if (this.delayOptionSelected === 3 || this.delayOptionSelected === 4) {
        let val = this.delayInput.date1.split('/');
        let dateVal = new Date(`${val[2]}/${val[1]}/${val[0]}`);
        if (parseInt(val[0]) > 12 || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Month");
          return;
        } else if (val[1].length !== 4 || parseInt(val[1]) > curYearVal || parseInt(val[1]) < 1) {
          this.toastyService.warning("Invalid Year");
          return;
        }
      } else if (this.delayOptionSelected === 2) {
        let val = this.delayInput.date1.split('/');
        let dateVal = new Date(`${val[2]}/${val[1]}/${val[0]}`);
        if (parseInt(val[0]) > this.lastWeeksInYear(val[1]) || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Week");
          return;
        } else if (val[1].length !== 4 || parseInt(val[1]) > curYearVal || parseInt(val[1]) < 1) {
          this.toastyService.warning("Invalid Year");
          return;
        }
      } else if (this.delayOptionSelected === 5) {
        let val = this.delayInput.date1;
        if (val.length !== 4 || parseInt(val) > curYearVal || parseInt(val) < 1) {
          this.toastyService.warning("Invalid Year");
          return;
        }
      }
    }

    if (this.delayCheckVal === 4) {
      if (this.delayOptionSelected === 0 || this.delayOptionSelected === 1 || this.delayOptionSelected === 6) {
        let val = this.delayInput.date2.split('/');
        const dateVal = new Date(`${val[2]}/${val[1]}/01`);
        const lastDayOfMonth = new Date(dateVal.getFullYear(), dateVal.getMonth() + 1, 0);
        if (parseInt(val[0]) > lastDayOfMonth.getDate() || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Date");
          return;
        } else if (parseInt(val[1]) > 12 || parseInt(val[1]) < 1) {
          this.toastyService.warning("Invalid Month");
          return;
        } else if (val[2].length !== 4 || parseInt(val[2]) > curYearVal || parseInt(val[2]) < 1) {
          this.toastyService.warning("Invalid Year");
          return;
        }
      } else if (this.delayOptionSelected === 3 || this.delayOptionSelected === 4) {
        let val = this.delayInput.date2.split('/');
        let dateVal = new Date(`${val[2]}/${val[1]}/${val[0]}`);
        if (parseInt(val[0]) > 12 || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Month");
          return;
        } else if (val[1].length !== 4 || parseInt(val[1]) > curYearVal || parseInt(val[1]) < 1) {
          this.toastyService.warning("Invalid Year");
          return;
        }
      } else if (this.delayOptionSelected === 2) {
        let val = this.delayInput.date2.split('/');
        let dateVal = new Date(`${val[2]}/${val[1]}/${val[0]}`);
        if (parseInt(val[0]) > this.lastWeeksInYear(val[1]) || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Week");
          return;
        } else if (val[1].length !== 4 || parseInt(val[1]) > curYearVal || parseInt(val[1]) < 1) {
          this.toastyService.warning("Invalid Year");
          return;
        }
      } else if (this.delayOptionSelected === 5) {
        let val = this.delayInput.date2;
        if (val.length !== 4 || parseInt(val) > curYearVal || parseInt(val) < 1) {
          this.toastyService.warning("Invalid Year");
          return;
        }
      }
    }
    let value = {
      date1: this.delayInput.date1,
      date2: this.delayInput.date2,
      value: retValue,
      ddValue: this.ddValue,
      format: formatVal,
      diffValue: this.delayDiffOptions[this.selectedDiffOption].value,
      diffTextVal: this.diffTextValue
    };
    this.delayOutput.emit(value);
    this.selectedOption = undefined;
    this.ddValue = undefined;
  }

  cancel() {
    let value = {
      value: '',
      ddValue: ''
    };
    this.delayOutput.emit(value);
  }
}
