import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

import { environment } from '../../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class BackendApiService {

  apiBaseURL: string;
  apisBaseURL: string;
  idpVersion;
  apiLastLoginURL: string;
  uploadURL: string;
  constructor(readonly http: HttpClient, readonly oauthService: OAuthService) {
    this.apiLastLoginURL = window.location.origin + "/pil-connection/";
    if ((window.location.hostname.indexOf('pil.app.renault.com') !== -1) ||
      (window.location.hostname.indexOf('pil.app.renault.com') !== -1)) {
      // OPE
      this.idpVersion = 'ope';
      this.apiBaseURL = environment.idp.prod[this.idpVersion].apiURL;
      this.apisBaseURL = environment.idp.prod[this.idpVersion].apisBaseUrl;
      // this.apiLastLoginURL = "https://pil-app.gke2.dev.gcp.renault.com/pil-connection/";
    } else if (window.location.hostname.indexOf('pil-app.gke2.int.gcp.renault.com') !== -1) {
      // RE7
      this.idpVersion = 're7';
      this.apiBaseURL = environment.idp.prod[this.idpVersion].apiURL;
      this.apisBaseURL = environment.idp.prod[this.idpVersion].apisBaseUrl;
      // this.apiLastLoginURL = "https://pil-app.gke2.int.gcp.renault.com/pil-connection/";
      this.uploadURL = "https://pil-app.gke2.int.gcp.renault.com/pil-api-s3/uploadfile";
    } else if (window.location.hostname.indexOf('pil-app.gke2.dev.gcp.renault.com') !== -1) {
      // DEV
      this.idpVersion = 'dev';
      this.apiBaseURL = environment.idp.prod[this.idpVersion].apiURL;
      this.apisBaseURL = environment.idp.prod[this.idpVersion].apisBaseUrl;
      this.uploadURL = "https://pil-app.gke2.dev.gcp.renault.com/pil-api-s3/uploadfile";
      this.apiLastLoginURL = "https://pil-app.gke2.dev.gcp.renault.com/pil-connection/";
    } else {
      // LOCALHOST
      this.idpVersion = 're7';
      this.apiBaseURL = environment.idp.prod[this.idpVersion].apiURL;
      this.apisBaseURL = environment.idp.prod[this.idpVersion].apisBaseUrl;
      this.uploadURL = "https://pil-app.gke2.int.gcp.renault.com/pil-api-s3/uploadfile";
      this.apiLastLoginURL = "https://pil-app.gke2.int.gcp.renault.com/pil-connection/";
      // this.uploadURL = "https://pil-app.gke2.dev.gcp.renault.com/pil-api-s3/uploadfile";
      // this.apiLastLoginURL = "https://pil-app.gke2.dev.gcp.renault.com/pil-connection/";
    }
  }

  headers() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'apikey': environment.idp.prod[this.idpVersion].apikey,
      'authorization': `Bearer ${sessionStorage.getItem('access_token')}`
    });
  }

  headers4FileUpload() {
    return new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'apikey': environment.idp.prod[this.idpVersion].apikey,
      'authorization': `Bearer ${sessionStorage.getItem('access_token')}`
    });
  }


  public getChoiceTableDetails(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objliste', queryParam, { headers: headers });
    // return this.http.post<any>(this.apiBaseURL + 'object-lists', queryParam, { headers: this.headers() });
    let queryParam1 = 
    { 
      "payLoad": {
        input: queryParam.input, 
        region: queryParam.region
      },
      "endPoint": this.apisBaseURL + 'object-lists', 
      "jwtToken": "eyJraWQiOiJEVXBTQWFUREtQRHphS19mV0NJcldxUUVOVTQ4bzM2ZXR5ZlV5eG9PaFY0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkY0VTUwV19fY2tfVGpLU3FOdHMxa09DeWx1Y0xnNXBXcnp5MkRlelZhQjQiLCJpc3MiOiJodHRwczovL3Nzby11YXQucmVuYXVsdC5jb20vb2F1dGgyL2F1c3R3b2VzaGJDa1BKeXcxNDE2IiwiYXVkIjoiaHR0cHM6Ly9hcGlzLnJlbmF1bHQuY29tIiwiaWF0IjoxNjk3Njk4NjMyLCJleHAiOjE2OTc3MDIyMzIsImNpZCI6Imlybi03NjY1OF91YXRfcGtjZV9qZ2F3Z2d4cXViMmEiLCJ1aWQiOiIwMHU2c3p4a3JwQ01uNmR6VjQxNyIsInNjcCI6WyJhcGlzLmRlZmF1bHQiLCJsZWdhY3kiLCJhbGxpYW5jZV9wcm9maWxlIiwib3BlbmlkIl0sImF1dGhfdGltZSI6MTY5NzY5MjEwMCwiYXBwcm92ZXIiOiJSQU5KQU4gUmFqZWV2IiwibGFzdE5hbWUiOiJWZWVyYXJhZ2F2YW4iLCJzdWIiOiJ6MDQwNzgzIiwicGVyc29uYWxVbmlxdWVJZCI6IjAwMDBGVkI0IiwicHJlZmVycmVkTGFuZ3VhZ2UiOiJlbiIsImFwcHJvdmVyTWFpbCI6IlJhamVldi5SYW5qYW5Acm50YmNpLmNvbSIsIm1haWwiOiJzcmltYXRoaS52ZWVyYXJhZ2F2YW4tZXh0ZXJuQHJudGJjaS5jb20iLCJpcm4iOiJJUk4tNzY2NTgiLCJkaXNwbGF5TmFtZSI6IlZFRVJBUkFHQVZBTiBTcmltYXRoaSIsImNvc3RDZW50ZXIiOiJRUTEzMDA3IiwiYXBwcm92ZXJJZCI6InowMDAzMzMiLCJyb2xlcyI6WyJQSUxfQURNSU4iLCJQSUxfQ1VNVUwiLCJQSUxfRFVNUCIsIlBJTF9FWEVDX1JFUSIsIlBJTF9PQkpfU1lTVCIsIlBJTF9TQVZFX1BVQkxJQyIsIlBJTF9UT1VURVNfUE9QIiwiQUNDX1BJTF9FVVJPUEUiLCJBQ0NfUElMX01FUkNPU1VSIiwiR1JfUElMX1RPVVQiLCJQSU1fQ1VNVUwiLCJQSU1fRFVNUCIsIlBJTV9FWEVDX1JFUSIsIlBJTV9PQkpfU1lTVCIsIlBJTV9TQVZFX1BVQkxJQyIsIlBJTV9UT1VURVNfUE9QIiwiR1JfUElNX1RPVVQiXSwibGFuZ3VhZ2UiOiJlbl9JTiIsImxvZ2luIjoiejA0MDc4MyIsImNlcnQtdWlkIjoiIiwiZmlyc3ROYW1lIjoiU3JpbWF0aGkiLCJwb3N0T2ZmaWNlQm94IjoiSU5NQUFNSEEwMDAiLCJjb3VudHJ5Q29kZSI6IklOIiwicGVyaW1ldGVyIjoiTm9uZSIsImNvbXBhbnkiOiIwNzc0IiwidXNlclR5cGUiOiJCMkVfQ29udHJhY3RvciIsInBlcnNvblR5cGUiOiJjYXRwcmp2In0.JcpmurZxwI0B60aBbI8mDu2DVwKVbDleRVDu8_xs4hqXVWA6ypCXvAk5v9NLIhvwubPySBThPO1WdPJckvSyp9Rri3Exe75Jsw4RMB5nyd87QXA8Hm87Uv2vlLFtdo7qQEmloUoFUV0ya-KxAbUF6-KPqchGtKUCv7Z9IsPErSzjy2ldF2SC6FrOK-jJ-CDjo7vPOQMRRwm7k9xAnQTFhlyWwNuUYtQfFafqGaQ8SQN02sPEpwyyLlPASv2AvJ2_grANBfaiwEx2wLfM4BsksWbf_O06Uo5myyggztY5rw7sYpPtpa9NdC9TdNbDO1bELrr0tkkSGLDnwFfbTR-H5Q"
    };
    return this.http.post<any>(this.apiBaseURL, queryParam1, { headers: this.headers()});
  }

  public getMessageObjectList(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objliste', queryParam, { headers: headers });
    // return this.http.post<any>(this.apiBaseURL + 'object-lists', queryParam, { headers: this.headers() });
    let queryParam1 = 
    { 
      "payLoad": {
        input: queryParam.input, 
        region: queryParam.region
      }, 
      "endPoint": this.apisBaseURL + 'object-lists', 
      "jwtToken": "eyJraWQiOiJEVXBTQWFUREtQRHphS19mV0NJcldxUUVOVTQ4bzM2ZXR5ZlV5eG9PaFY0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkY0VTUwV19fY2tfVGpLU3FOdHMxa09DeWx1Y0xnNXBXcnp5MkRlelZhQjQiLCJpc3MiOiJodHRwczovL3Nzby11YXQucmVuYXVsdC5jb20vb2F1dGgyL2F1c3R3b2VzaGJDa1BKeXcxNDE2IiwiYXVkIjoiaHR0cHM6Ly9hcGlzLnJlbmF1bHQuY29tIiwiaWF0IjoxNjk3Njk4NjMyLCJleHAiOjE2OTc3MDIyMzIsImNpZCI6Imlybi03NjY1OF91YXRfcGtjZV9qZ2F3Z2d4cXViMmEiLCJ1aWQiOiIwMHU2c3p4a3JwQ01uNmR6VjQxNyIsInNjcCI6WyJhcGlzLmRlZmF1bHQiLCJsZWdhY3kiLCJhbGxpYW5jZV9wcm9maWxlIiwib3BlbmlkIl0sImF1dGhfdGltZSI6MTY5NzY5MjEwMCwiYXBwcm92ZXIiOiJSQU5KQU4gUmFqZWV2IiwibGFzdE5hbWUiOiJWZWVyYXJhZ2F2YW4iLCJzdWIiOiJ6MDQwNzgzIiwicGVyc29uYWxVbmlxdWVJZCI6IjAwMDBGVkI0IiwicHJlZmVycmVkTGFuZ3VhZ2UiOiJlbiIsImFwcHJvdmVyTWFpbCI6IlJhamVldi5SYW5qYW5Acm50YmNpLmNvbSIsIm1haWwiOiJzcmltYXRoaS52ZWVyYXJhZ2F2YW4tZXh0ZXJuQHJudGJjaS5jb20iLCJpcm4iOiJJUk4tNzY2NTgiLCJkaXNwbGF5TmFtZSI6IlZFRVJBUkFHQVZBTiBTcmltYXRoaSIsImNvc3RDZW50ZXIiOiJRUTEzMDA3IiwiYXBwcm92ZXJJZCI6InowMDAzMzMiLCJyb2xlcyI6WyJQSUxfQURNSU4iLCJQSUxfQ1VNVUwiLCJQSUxfRFVNUCIsIlBJTF9FWEVDX1JFUSIsIlBJTF9PQkpfU1lTVCIsIlBJTF9TQVZFX1BVQkxJQyIsIlBJTF9UT1VURVNfUE9QIiwiQUNDX1BJTF9FVVJPUEUiLCJBQ0NfUElMX01FUkNPU1VSIiwiR1JfUElMX1RPVVQiLCJQSU1fQ1VNVUwiLCJQSU1fRFVNUCIsIlBJTV9FWEVDX1JFUSIsIlBJTV9PQkpfU1lTVCIsIlBJTV9TQVZFX1BVQkxJQyIsIlBJTV9UT1VURVNfUE9QIiwiR1JfUElNX1RPVVQiXSwibGFuZ3VhZ2UiOiJlbl9JTiIsImxvZ2luIjoiejA0MDc4MyIsImNlcnQtdWlkIjoiIiwiZmlyc3ROYW1lIjoiU3JpbWF0aGkiLCJwb3N0T2ZmaWNlQm94IjoiSU5NQUFNSEEwMDAiLCJjb3VudHJ5Q29kZSI6IklOIiwicGVyaW1ldGVyIjoiTm9uZSIsImNvbXBhbnkiOiIwNzc0IiwidXNlclR5cGUiOiJCMkVfQ29udHJhY3RvciIsInBlcnNvblR5cGUiOiJjYXRwcmp2In0.JcpmurZxwI0B60aBbI8mDu2DVwKVbDleRVDu8_xs4hqXVWA6ypCXvAk5v9NLIhvwubPySBThPO1WdPJckvSyp9Rri3Exe75Jsw4RMB5nyd87QXA8Hm87Uv2vlLFtdo7qQEmloUoFUV0ya-KxAbUF6-KPqchGtKUCv7Z9IsPErSzjy2ldF2SC6FrOK-jJ-CDjo7vPOQMRRwm7k9xAnQTFhlyWwNuUYtQfFafqGaQ8SQN02sPEpwyyLlPASv2AvJ2_grANBfaiwEx2wLfM4BsksWbf_O06Uo5myyggztY5rw7sYpPtpa9NdC9TdNbDO1bELrr0tkkSGLDnwFfbTR-H5Q"
    };
    return this.http.post<any>(this.apiBaseURL, queryParam1, { headers: this.headers()});
  
  }

  public getMessageObject(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objliste', queryParam, { headers: headers });
    // return this.http.post<any>(this.apiBaseURL + 'getting-objects', queryParam, { headers: this.headers() });
    let queryParam1 = 
    { 
      "payLoad": {
        input: queryParam.input, 
        region: queryParam.region
      },
      "endPoint": this.apisBaseURL + 'getting-objects', 
      "jwtToken": "eyJraWQiOiJEVXBTQWFUREtQRHphS19mV0NJcldxUUVOVTQ4bzM2ZXR5ZlV5eG9PaFY0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkY0VTUwV19fY2tfVGpLU3FOdHMxa09DeWx1Y0xnNXBXcnp5MkRlelZhQjQiLCJpc3MiOiJodHRwczovL3Nzby11YXQucmVuYXVsdC5jb20vb2F1dGgyL2F1c3R3b2VzaGJDa1BKeXcxNDE2IiwiYXVkIjoiaHR0cHM6Ly9hcGlzLnJlbmF1bHQuY29tIiwiaWF0IjoxNjk3Njk4NjMyLCJleHAiOjE2OTc3MDIyMzIsImNpZCI6Imlybi03NjY1OF91YXRfcGtjZV9qZ2F3Z2d4cXViMmEiLCJ1aWQiOiIwMHU2c3p4a3JwQ01uNmR6VjQxNyIsInNjcCI6WyJhcGlzLmRlZmF1bHQiLCJsZWdhY3kiLCJhbGxpYW5jZV9wcm9maWxlIiwib3BlbmlkIl0sImF1dGhfdGltZSI6MTY5NzY5MjEwMCwiYXBwcm92ZXIiOiJSQU5KQU4gUmFqZWV2IiwibGFzdE5hbWUiOiJWZWVyYXJhZ2F2YW4iLCJzdWIiOiJ6MDQwNzgzIiwicGVyc29uYWxVbmlxdWVJZCI6IjAwMDBGVkI0IiwicHJlZmVycmVkTGFuZ3VhZ2UiOiJlbiIsImFwcHJvdmVyTWFpbCI6IlJhamVldi5SYW5qYW5Acm50YmNpLmNvbSIsIm1haWwiOiJzcmltYXRoaS52ZWVyYXJhZ2F2YW4tZXh0ZXJuQHJudGJjaS5jb20iLCJpcm4iOiJJUk4tNzY2NTgiLCJkaXNwbGF5TmFtZSI6IlZFRVJBUkFHQVZBTiBTcmltYXRoaSIsImNvc3RDZW50ZXIiOiJRUTEzMDA3IiwiYXBwcm92ZXJJZCI6InowMDAzMzMiLCJyb2xlcyI6WyJQSUxfQURNSU4iLCJQSUxfQ1VNVUwiLCJQSUxfRFVNUCIsIlBJTF9FWEVDX1JFUSIsIlBJTF9PQkpfU1lTVCIsIlBJTF9TQVZFX1BVQkxJQyIsIlBJTF9UT1VURVNfUE9QIiwiQUNDX1BJTF9FVVJPUEUiLCJBQ0NfUElMX01FUkNPU1VSIiwiR1JfUElMX1RPVVQiLCJQSU1fQ1VNVUwiLCJQSU1fRFVNUCIsIlBJTV9FWEVDX1JFUSIsIlBJTV9PQkpfU1lTVCIsIlBJTV9TQVZFX1BVQkxJQyIsIlBJTV9UT1VURVNfUE9QIiwiR1JfUElNX1RPVVQiXSwibGFuZ3VhZ2UiOiJlbl9JTiIsImxvZ2luIjoiejA0MDc4MyIsImNlcnQtdWlkIjoiIiwiZmlyc3ROYW1lIjoiU3JpbWF0aGkiLCJwb3N0T2ZmaWNlQm94IjoiSU5NQUFNSEEwMDAiLCJjb3VudHJ5Q29kZSI6IklOIiwicGVyaW1ldGVyIjoiTm9uZSIsImNvbXBhbnkiOiIwNzc0IiwidXNlclR5cGUiOiJCMkVfQ29udHJhY3RvciIsInBlcnNvblR5cGUiOiJjYXRwcmp2In0.JcpmurZxwI0B60aBbI8mDu2DVwKVbDleRVDu8_xs4hqXVWA6ypCXvAk5v9NLIhvwubPySBThPO1WdPJckvSyp9Rri3Exe75Jsw4RMB5nyd87QXA8Hm87Uv2vlLFtdo7qQEmloUoFUV0ya-KxAbUF6-KPqchGtKUCv7Z9IsPErSzjy2ldF2SC6FrOK-jJ-CDjo7vPOQMRRwm7k9xAnQTFhlyWwNuUYtQfFafqGaQ8SQN02sPEpwyyLlPASv2AvJ2_grANBfaiwEx2wLfM4BsksWbf_O06Uo5myyggztY5rw7sYpPtpa9NdC9TdNbDO1bELrr0tkkSGLDnwFfbTR-H5Q"
    };
    return this.http.post<any>(this.apiBaseURL, queryParam1, { headers: this.headers()});
  
  }

  public getExecuteData(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'results-lines', queryParam, { headers: this.headers() });
    let queryParam1 = 
    { 
      "payLoad": {
        inputLists: queryParam.inputLists, 
        region: queryParam.region
      },
      "endPoint": this.apisBaseURL + 'results-lines', 
      "jwtToken": "eyJraWQiOiJEVXBTQWFUREtQRHphS19mV0NJcldxUUVOVTQ4bzM2ZXR5ZlV5eG9PaFY0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkY0VTUwV19fY2tfVGpLU3FOdHMxa09DeWx1Y0xnNXBXcnp5MkRlelZhQjQiLCJpc3MiOiJodHRwczovL3Nzby11YXQucmVuYXVsdC5jb20vb2F1dGgyL2F1c3R3b2VzaGJDa1BKeXcxNDE2IiwiYXVkIjoiaHR0cHM6Ly9hcGlzLnJlbmF1bHQuY29tIiwiaWF0IjoxNjk3Njk4NjMyLCJleHAiOjE2OTc3MDIyMzIsImNpZCI6Imlybi03NjY1OF91YXRfcGtjZV9qZ2F3Z2d4cXViMmEiLCJ1aWQiOiIwMHU2c3p4a3JwQ01uNmR6VjQxNyIsInNjcCI6WyJhcGlzLmRlZmF1bHQiLCJsZWdhY3kiLCJhbGxpYW5jZV9wcm9maWxlIiwib3BlbmlkIl0sImF1dGhfdGltZSI6MTY5NzY5MjEwMCwiYXBwcm92ZXIiOiJSQU5KQU4gUmFqZWV2IiwibGFzdE5hbWUiOiJWZWVyYXJhZ2F2YW4iLCJzdWIiOiJ6MDQwNzgzIiwicGVyc29uYWxVbmlxdWVJZCI6IjAwMDBGVkI0IiwicHJlZmVycmVkTGFuZ3VhZ2UiOiJlbiIsImFwcHJvdmVyTWFpbCI6IlJhamVldi5SYW5qYW5Acm50YmNpLmNvbSIsIm1haWwiOiJzcmltYXRoaS52ZWVyYXJhZ2F2YW4tZXh0ZXJuQHJudGJjaS5jb20iLCJpcm4iOiJJUk4tNzY2NTgiLCJkaXNwbGF5TmFtZSI6IlZFRVJBUkFHQVZBTiBTcmltYXRoaSIsImNvc3RDZW50ZXIiOiJRUTEzMDA3IiwiYXBwcm92ZXJJZCI6InowMDAzMzMiLCJyb2xlcyI6WyJQSUxfQURNSU4iLCJQSUxfQ1VNVUwiLCJQSUxfRFVNUCIsIlBJTF9FWEVDX1JFUSIsIlBJTF9PQkpfU1lTVCIsIlBJTF9TQVZFX1BVQkxJQyIsIlBJTF9UT1VURVNfUE9QIiwiQUNDX1BJTF9FVVJPUEUiLCJBQ0NfUElMX01FUkNPU1VSIiwiR1JfUElMX1RPVVQiLCJQSU1fQ1VNVUwiLCJQSU1fRFVNUCIsIlBJTV9FWEVDX1JFUSIsIlBJTV9PQkpfU1lTVCIsIlBJTV9TQVZFX1BVQkxJQyIsIlBJTV9UT1VURVNfUE9QIiwiR1JfUElNX1RPVVQiXSwibGFuZ3VhZ2UiOiJlbl9JTiIsImxvZ2luIjoiejA0MDc4MyIsImNlcnQtdWlkIjoiIiwiZmlyc3ROYW1lIjoiU3JpbWF0aGkiLCJwb3N0T2ZmaWNlQm94IjoiSU5NQUFNSEEwMDAiLCJjb3VudHJ5Q29kZSI6IklOIiwicGVyaW1ldGVyIjoiTm9uZSIsImNvbXBhbnkiOiIwNzc0IiwidXNlclR5cGUiOiJCMkVfQ29udHJhY3RvciIsInBlcnNvblR5cGUiOiJjYXRwcmp2In0.JcpmurZxwI0B60aBbI8mDu2DVwKVbDleRVDu8_xs4hqXVWA6ypCXvAk5v9NLIhvwubPySBThPO1WdPJckvSyp9Rri3Exe75Jsw4RMB5nyd87QXA8Hm87Uv2vlLFtdo7qQEmloUoFUV0ya-KxAbUF6-KPqchGtKUCv7Z9IsPErSzjy2ldF2SC6FrOK-jJ-CDjo7vPOQMRRwm7k9xAnQTFhlyWwNuUYtQfFafqGaQ8SQN02sPEpwyyLlPASv2AvJ2_grANBfaiwEx2wLfM4BsksWbf_O06Uo5myyggztY5rw7sYpPtpa9NdC9TdNbDO1bELrr0tkkSGLDnwFfbTR-H5Q"
    };
    return this.http.post<any>(this.apiBaseURL, queryParam1, { headers: this.headers()});
  
  }

  public getDetailExecuteData(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'results-vehicle-lists', queryParam, { headers: this.headers() });
    let queryParam1 = 
    { 
      "payLoad": {
        inputLists: queryParam.inputLists, 
        region: queryParam.region
      },
      "endPoint": this.apisBaseURL + 'results-vehicle-lists', 
      "jwtToken": "eyJraWQiOiJEVXBTQWFUREtQRHphS19mV0NJcldxUUVOVTQ4bzM2ZXR5ZlV5eG9PaFY0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkY0VTUwV19fY2tfVGpLU3FOdHMxa09DeWx1Y0xnNXBXcnp5MkRlelZhQjQiLCJpc3MiOiJodHRwczovL3Nzby11YXQucmVuYXVsdC5jb20vb2F1dGgyL2F1c3R3b2VzaGJDa1BKeXcxNDE2IiwiYXVkIjoiaHR0cHM6Ly9hcGlzLnJlbmF1bHQuY29tIiwiaWF0IjoxNjk3Njk4NjMyLCJleHAiOjE2OTc3MDIyMzIsImNpZCI6Imlybi03NjY1OF91YXRfcGtjZV9qZ2F3Z2d4cXViMmEiLCJ1aWQiOiIwMHU2c3p4a3JwQ01uNmR6VjQxNyIsInNjcCI6WyJhcGlzLmRlZmF1bHQiLCJsZWdhY3kiLCJhbGxpYW5jZV9wcm9maWxlIiwib3BlbmlkIl0sImF1dGhfdGltZSI6MTY5NzY5MjEwMCwiYXBwcm92ZXIiOiJSQU5KQU4gUmFqZWV2IiwibGFzdE5hbWUiOiJWZWVyYXJhZ2F2YW4iLCJzdWIiOiJ6MDQwNzgzIiwicGVyc29uYWxVbmlxdWVJZCI6IjAwMDBGVkI0IiwicHJlZmVycmVkTGFuZ3VhZ2UiOiJlbiIsImFwcHJvdmVyTWFpbCI6IlJhamVldi5SYW5qYW5Acm50YmNpLmNvbSIsIm1haWwiOiJzcmltYXRoaS52ZWVyYXJhZ2F2YW4tZXh0ZXJuQHJudGJjaS5jb20iLCJpcm4iOiJJUk4tNzY2NTgiLCJkaXNwbGF5TmFtZSI6IlZFRVJBUkFHQVZBTiBTcmltYXRoaSIsImNvc3RDZW50ZXIiOiJRUTEzMDA3IiwiYXBwcm92ZXJJZCI6InowMDAzMzMiLCJyb2xlcyI6WyJQSUxfQURNSU4iLCJQSUxfQ1VNVUwiLCJQSUxfRFVNUCIsIlBJTF9FWEVDX1JFUSIsIlBJTF9PQkpfU1lTVCIsIlBJTF9TQVZFX1BVQkxJQyIsIlBJTF9UT1VURVNfUE9QIiwiQUNDX1BJTF9FVVJPUEUiLCJBQ0NfUElMX01FUkNPU1VSIiwiR1JfUElMX1RPVVQiLCJQSU1fQ1VNVUwiLCJQSU1fRFVNUCIsIlBJTV9FWEVDX1JFUSIsIlBJTV9PQkpfU1lTVCIsIlBJTV9TQVZFX1BVQkxJQyIsIlBJTV9UT1VURVNfUE9QIiwiR1JfUElNX1RPVVQiXSwibGFuZ3VhZ2UiOiJlbl9JTiIsImxvZ2luIjoiejA0MDc4MyIsImNlcnQtdWlkIjoiIiwiZmlyc3ROYW1lIjoiU3JpbWF0aGkiLCJwb3N0T2ZmaWNlQm94IjoiSU5NQUFNSEEwMDAiLCJjb3VudHJ5Q29kZSI6IklOIiwicGVyaW1ldGVyIjoiTm9uZSIsImNvbXBhbnkiOiIwNzc0IiwidXNlclR5cGUiOiJCMkVfQ29udHJhY3RvciIsInBlcnNvblR5cGUiOiJjYXRwcmp2In0.JcpmurZxwI0B60aBbI8mDu2DVwKVbDleRVDu8_xs4hqXVWA6ypCXvAk5v9NLIhvwubPySBThPO1WdPJckvSyp9Rri3Exe75Jsw4RMB5nyd87QXA8Hm87Uv2vlLFtdo7qQEmloUoFUV0ya-KxAbUF6-KPqchGtKUCv7Z9IsPErSzjy2ldF2SC6FrOK-jJ-CDjo7vPOQMRRwm7k9xAnQTFhlyWwNuUYtQfFafqGaQ8SQN02sPEpwyyLlPASv2AvJ2_grANBfaiwEx2wLfM4BsksWbf_O06Uo5myyggztY5rw7sYpPtpa9NdC9TdNbDO1bELrr0tkkSGLDnwFfbTR-H5Q"
    };
    return this.http.post<any>(this.apiBaseURL, queryParam1, { headers: this.headers()});
  
  }

  public getVehicleDetailExecuteData(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'results-vehicle-details', queryParam, { headers: this.headers() });
    let queryParam1 = 
    { 
      "payLoad": {
        inputLists: queryParam.inputLists, 
        region: queryParam.region
      },
      "endPoint": this.apisBaseURL + 'results-vehicle-details', 
      "jwtToken": "eyJraWQiOiJEVXBTQWFUREtQRHphS19mV0NJcldxUUVOVTQ4bzM2ZXR5ZlV5eG9PaFY0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkY0VTUwV19fY2tfVGpLU3FOdHMxa09DeWx1Y0xnNXBXcnp5MkRlelZhQjQiLCJpc3MiOiJodHRwczovL3Nzby11YXQucmVuYXVsdC5jb20vb2F1dGgyL2F1c3R3b2VzaGJDa1BKeXcxNDE2IiwiYXVkIjoiaHR0cHM6Ly9hcGlzLnJlbmF1bHQuY29tIiwiaWF0IjoxNjk3Njk4NjMyLCJleHAiOjE2OTc3MDIyMzIsImNpZCI6Imlybi03NjY1OF91YXRfcGtjZV9qZ2F3Z2d4cXViMmEiLCJ1aWQiOiIwMHU2c3p4a3JwQ01uNmR6VjQxNyIsInNjcCI6WyJhcGlzLmRlZmF1bHQiLCJsZWdhY3kiLCJhbGxpYW5jZV9wcm9maWxlIiwib3BlbmlkIl0sImF1dGhfdGltZSI6MTY5NzY5MjEwMCwiYXBwcm92ZXIiOiJSQU5KQU4gUmFqZWV2IiwibGFzdE5hbWUiOiJWZWVyYXJhZ2F2YW4iLCJzdWIiOiJ6MDQwNzgzIiwicGVyc29uYWxVbmlxdWVJZCI6IjAwMDBGVkI0IiwicHJlZmVycmVkTGFuZ3VhZ2UiOiJlbiIsImFwcHJvdmVyTWFpbCI6IlJhamVldi5SYW5qYW5Acm50YmNpLmNvbSIsIm1haWwiOiJzcmltYXRoaS52ZWVyYXJhZ2F2YW4tZXh0ZXJuQHJudGJjaS5jb20iLCJpcm4iOiJJUk4tNzY2NTgiLCJkaXNwbGF5TmFtZSI6IlZFRVJBUkFHQVZBTiBTcmltYXRoaSIsImNvc3RDZW50ZXIiOiJRUTEzMDA3IiwiYXBwcm92ZXJJZCI6InowMDAzMzMiLCJyb2xlcyI6WyJQSUxfQURNSU4iLCJQSUxfQ1VNVUwiLCJQSUxfRFVNUCIsIlBJTF9FWEVDX1JFUSIsIlBJTF9PQkpfU1lTVCIsIlBJTF9TQVZFX1BVQkxJQyIsIlBJTF9UT1VURVNfUE9QIiwiQUNDX1BJTF9FVVJPUEUiLCJBQ0NfUElMX01FUkNPU1VSIiwiR1JfUElMX1RPVVQiLCJQSU1fQ1VNVUwiLCJQSU1fRFVNUCIsIlBJTV9FWEVDX1JFUSIsIlBJTV9PQkpfU1lTVCIsIlBJTV9TQVZFX1BVQkxJQyIsIlBJTV9UT1VURVNfUE9QIiwiR1JfUElNX1RPVVQiXSwibGFuZ3VhZ2UiOiJlbl9JTiIsImxvZ2luIjoiejA0MDc4MyIsImNlcnQtdWlkIjoiIiwiZmlyc3ROYW1lIjoiU3JpbWF0aGkiLCJwb3N0T2ZmaWNlQm94IjoiSU5NQUFNSEEwMDAiLCJjb3VudHJ5Q29kZSI6IklOIiwicGVyaW1ldGVyIjoiTm9uZSIsImNvbXBhbnkiOiIwNzc0IiwidXNlclR5cGUiOiJCMkVfQ29udHJhY3RvciIsInBlcnNvblR5cGUiOiJjYXRwcmp2In0.JcpmurZxwI0B60aBbI8mDu2DVwKVbDleRVDu8_xs4hqXVWA6ypCXvAk5v9NLIhvwubPySBThPO1WdPJckvSyp9Rri3Exe75Jsw4RMB5nyd87QXA8Hm87Uv2vlLFtdo7qQEmloUoFUV0ya-KxAbUF6-KPqchGtKUCv7Z9IsPErSzjy2ldF2SC6FrOK-jJ-CDjo7vPOQMRRwm7k9xAnQTFhlyWwNuUYtQfFafqGaQ8SQN02sPEpwyyLlPASv2AvJ2_grANBfaiwEx2wLfM4BsksWbf_O06Uo5myyggztY5rw7sYpPtpa9NdC9TdNbDO1bELrr0tkkSGLDnwFfbTR-H5Q"
    };
    return this.http.post<any>(this.apiBaseURL, queryParam1, { headers: this.headers()});
  
  }

  public getObjectSize(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objnbre', queryParam, { headers: headers });
    // return this.http.post<any>(this.apiBaseURL + 'object-numbers', queryParam, { headers: this.headers() });
    let queryParam1 = 
    { 
      "payLoad": {
        input: queryParam.input, 
        region: queryParam.region
      },
      "endPoint": this.apisBaseURL + 'object-numbers', 
      "jwtToken": "eyJraWQiOiJEVXBTQWFUREtQRHphS19mV0NJcldxUUVOVTQ4bzM2ZXR5ZlV5eG9PaFY0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkY0VTUwV19fY2tfVGpLU3FOdHMxa09DeWx1Y0xnNXBXcnp5MkRlelZhQjQiLCJpc3MiOiJodHRwczovL3Nzby11YXQucmVuYXVsdC5jb20vb2F1dGgyL2F1c3R3b2VzaGJDa1BKeXcxNDE2IiwiYXVkIjoiaHR0cHM6Ly9hcGlzLnJlbmF1bHQuY29tIiwiaWF0IjoxNjk3Njk4NjMyLCJleHAiOjE2OTc3MDIyMzIsImNpZCI6Imlybi03NjY1OF91YXRfcGtjZV9qZ2F3Z2d4cXViMmEiLCJ1aWQiOiIwMHU2c3p4a3JwQ01uNmR6VjQxNyIsInNjcCI6WyJhcGlzLmRlZmF1bHQiLCJsZWdhY3kiLCJhbGxpYW5jZV9wcm9maWxlIiwib3BlbmlkIl0sImF1dGhfdGltZSI6MTY5NzY5MjEwMCwiYXBwcm92ZXIiOiJSQU5KQU4gUmFqZWV2IiwibGFzdE5hbWUiOiJWZWVyYXJhZ2F2YW4iLCJzdWIiOiJ6MDQwNzgzIiwicGVyc29uYWxVbmlxdWVJZCI6IjAwMDBGVkI0IiwicHJlZmVycmVkTGFuZ3VhZ2UiOiJlbiIsImFwcHJvdmVyTWFpbCI6IlJhamVldi5SYW5qYW5Acm50YmNpLmNvbSIsIm1haWwiOiJzcmltYXRoaS52ZWVyYXJhZ2F2YW4tZXh0ZXJuQHJudGJjaS5jb20iLCJpcm4iOiJJUk4tNzY2NTgiLCJkaXNwbGF5TmFtZSI6IlZFRVJBUkFHQVZBTiBTcmltYXRoaSIsImNvc3RDZW50ZXIiOiJRUTEzMDA3IiwiYXBwcm92ZXJJZCI6InowMDAzMzMiLCJyb2xlcyI6WyJQSUxfQURNSU4iLCJQSUxfQ1VNVUwiLCJQSUxfRFVNUCIsIlBJTF9FWEVDX1JFUSIsIlBJTF9PQkpfU1lTVCIsIlBJTF9TQVZFX1BVQkxJQyIsIlBJTF9UT1VURVNfUE9QIiwiQUNDX1BJTF9FVVJPUEUiLCJBQ0NfUElMX01FUkNPU1VSIiwiR1JfUElMX1RPVVQiLCJQSU1fQ1VNVUwiLCJQSU1fRFVNUCIsIlBJTV9FWEVDX1JFUSIsIlBJTV9PQkpfU1lTVCIsIlBJTV9TQVZFX1BVQkxJQyIsIlBJTV9UT1VURVNfUE9QIiwiR1JfUElNX1RPVVQiXSwibGFuZ3VhZ2UiOiJlbl9JTiIsImxvZ2luIjoiejA0MDc4MyIsImNlcnQtdWlkIjoiIiwiZmlyc3ROYW1lIjoiU3JpbWF0aGkiLCJwb3N0T2ZmaWNlQm94IjoiSU5NQUFNSEEwMDAiLCJjb3VudHJ5Q29kZSI6IklOIiwicGVyaW1ldGVyIjoiTm9uZSIsImNvbXBhbnkiOiIwNzc0IiwidXNlclR5cGUiOiJCMkVfQ29udHJhY3RvciIsInBlcnNvblR5cGUiOiJjYXRwcmp2In0.JcpmurZxwI0B60aBbI8mDu2DVwKVbDleRVDu8_xs4hqXVWA6ypCXvAk5v9NLIhvwubPySBThPO1WdPJckvSyp9Rri3Exe75Jsw4RMB5nyd87QXA8Hm87Uv2vlLFtdo7qQEmloUoFUV0ya-KxAbUF6-KPqchGtKUCv7Z9IsPErSzjy2ldF2SC6FrOK-jJ-CDjo7vPOQMRRwm7k9xAnQTFhlyWwNuUYtQfFafqGaQ8SQN02sPEpwyyLlPASv2AvJ2_grANBfaiwEx2wLfM4BsksWbf_O06Uo5myyggztY5rw7sYpPtpa9NdC9TdNbDO1bELrr0tkkSGLDnwFfbTR-H5Q"
    };
    return this.http.post<any>(this.apiBaseURL, queryParam1, { headers: this.headers()});

  }

  public getSaveQueryName(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'object-numbers', queryParam, { headers: this.headers() });
    let queryParam1 = 
    { 
      "payLoad": {
        input: queryParam.input, 
        region: queryParam.region
      },
      "endPoint": this.apisBaseURL + 'object-numbers', 
      "jwtToken": "eyJraWQiOiJEVXBTQWFUREtQRHphS19mV0NJcldxUUVOVTQ4bzM2ZXR5ZlV5eG9PaFY0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkY0VTUwV19fY2tfVGpLU3FOdHMxa09DeWx1Y0xnNXBXcnp5MkRlelZhQjQiLCJpc3MiOiJodHRwczovL3Nzby11YXQucmVuYXVsdC5jb20vb2F1dGgyL2F1c3R3b2VzaGJDa1BKeXcxNDE2IiwiYXVkIjoiaHR0cHM6Ly9hcGlzLnJlbmF1bHQuY29tIiwiaWF0IjoxNjk3Njk4NjMyLCJleHAiOjE2OTc3MDIyMzIsImNpZCI6Imlybi03NjY1OF91YXRfcGtjZV9qZ2F3Z2d4cXViMmEiLCJ1aWQiOiIwMHU2c3p4a3JwQ01uNmR6VjQxNyIsInNjcCI6WyJhcGlzLmRlZmF1bHQiLCJsZWdhY3kiLCJhbGxpYW5jZV9wcm9maWxlIiwib3BlbmlkIl0sImF1dGhfdGltZSI6MTY5NzY5MjEwMCwiYXBwcm92ZXIiOiJSQU5KQU4gUmFqZWV2IiwibGFzdE5hbWUiOiJWZWVyYXJhZ2F2YW4iLCJzdWIiOiJ6MDQwNzgzIiwicGVyc29uYWxVbmlxdWVJZCI6IjAwMDBGVkI0IiwicHJlZmVycmVkTGFuZ3VhZ2UiOiJlbiIsImFwcHJvdmVyTWFpbCI6IlJhamVldi5SYW5qYW5Acm50YmNpLmNvbSIsIm1haWwiOiJzcmltYXRoaS52ZWVyYXJhZ2F2YW4tZXh0ZXJuQHJudGJjaS5jb20iLCJpcm4iOiJJUk4tNzY2NTgiLCJkaXNwbGF5TmFtZSI6IlZFRVJBUkFHQVZBTiBTcmltYXRoaSIsImNvc3RDZW50ZXIiOiJRUTEzMDA3IiwiYXBwcm92ZXJJZCI6InowMDAzMzMiLCJyb2xlcyI6WyJQSUxfQURNSU4iLCJQSUxfQ1VNVUwiLCJQSUxfRFVNUCIsIlBJTF9FWEVDX1JFUSIsIlBJTF9PQkpfU1lTVCIsIlBJTF9TQVZFX1BVQkxJQyIsIlBJTF9UT1VURVNfUE9QIiwiQUNDX1BJTF9FVVJPUEUiLCJBQ0NfUElMX01FUkNPU1VSIiwiR1JfUElMX1RPVVQiLCJQSU1fQ1VNVUwiLCJQSU1fRFVNUCIsIlBJTV9FWEVDX1JFUSIsIlBJTV9PQkpfU1lTVCIsIlBJTV9TQVZFX1BVQkxJQyIsIlBJTV9UT1VURVNfUE9QIiwiR1JfUElNX1RPVVQiXSwibGFuZ3VhZ2UiOiJlbl9JTiIsImxvZ2luIjoiejA0MDc4MyIsImNlcnQtdWlkIjoiIiwiZmlyc3ROYW1lIjoiU3JpbWF0aGkiLCJwb3N0T2ZmaWNlQm94IjoiSU5NQUFNSEEwMDAiLCJjb3VudHJ5Q29kZSI6IklOIiwicGVyaW1ldGVyIjoiTm9uZSIsImNvbXBhbnkiOiIwNzc0IiwidXNlclR5cGUiOiJCMkVfQ29udHJhY3RvciIsInBlcnNvblR5cGUiOiJjYXRwcmp2In0.JcpmurZxwI0B60aBbI8mDu2DVwKVbDleRVDu8_xs4hqXVWA6ypCXvAk5v9NLIhvwubPySBThPO1WdPJckvSyp9Rri3Exe75Jsw4RMB5nyd87QXA8Hm87Uv2vlLFtdo7qQEmloUoFUV0ya-KxAbUF6-KPqchGtKUCv7Z9IsPErSzjy2ldF2SC6FrOK-jJ-CDjo7vPOQMRRwm7k9xAnQTFhlyWwNuUYtQfFafqGaQ8SQN02sPEpwyyLlPASv2AvJ2_grANBfaiwEx2wLfM4BsksWbf_O06Uo5myyggztY5rw7sYpPtpa9NdC9TdNbDO1bELrr0tkkSGLDnwFfbTR-H5Q"
    };
    return this.http.post<any>(this.apiBaseURL, queryParam1, { headers: this.headers()});

  }

  public getSaveQueryNameList(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'object-lists', queryParam, { headers: this.headers() });
    let queryParam1 = 
    { 
      "payLoad": {
        input: queryParam.input, 
        region: queryParam.region
      },
      "endPoint": this.apisBaseURL + 'object-lists', 
      "jwtToken": "eyJraWQiOiJEVXBTQWFUREtQRHphS19mV0NJcldxUUVOVTQ4bzM2ZXR5ZlV5eG9PaFY0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkY0VTUwV19fY2tfVGpLU3FOdHMxa09DeWx1Y0xnNXBXcnp5MkRlelZhQjQiLCJpc3MiOiJodHRwczovL3Nzby11YXQucmVuYXVsdC5jb20vb2F1dGgyL2F1c3R3b2VzaGJDa1BKeXcxNDE2IiwiYXVkIjoiaHR0cHM6Ly9hcGlzLnJlbmF1bHQuY29tIiwiaWF0IjoxNjk3Njk4NjMyLCJleHAiOjE2OTc3MDIyMzIsImNpZCI6Imlybi03NjY1OF91YXRfcGtjZV9qZ2F3Z2d4cXViMmEiLCJ1aWQiOiIwMHU2c3p4a3JwQ01uNmR6VjQxNyIsInNjcCI6WyJhcGlzLmRlZmF1bHQiLCJsZWdhY3kiLCJhbGxpYW5jZV9wcm9maWxlIiwib3BlbmlkIl0sImF1dGhfdGltZSI6MTY5NzY5MjEwMCwiYXBwcm92ZXIiOiJSQU5KQU4gUmFqZWV2IiwibGFzdE5hbWUiOiJWZWVyYXJhZ2F2YW4iLCJzdWIiOiJ6MDQwNzgzIiwicGVyc29uYWxVbmlxdWVJZCI6IjAwMDBGVkI0IiwicHJlZmVycmVkTGFuZ3VhZ2UiOiJlbiIsImFwcHJvdmVyTWFpbCI6IlJhamVldi5SYW5qYW5Acm50YmNpLmNvbSIsIm1haWwiOiJzcmltYXRoaS52ZWVyYXJhZ2F2YW4tZXh0ZXJuQHJudGJjaS5jb20iLCJpcm4iOiJJUk4tNzY2NTgiLCJkaXNwbGF5TmFtZSI6IlZFRVJBUkFHQVZBTiBTcmltYXRoaSIsImNvc3RDZW50ZXIiOiJRUTEzMDA3IiwiYXBwcm92ZXJJZCI6InowMDAzMzMiLCJyb2xlcyI6WyJQSUxfQURNSU4iLCJQSUxfQ1VNVUwiLCJQSUxfRFVNUCIsIlBJTF9FWEVDX1JFUSIsIlBJTF9PQkpfU1lTVCIsIlBJTF9TQVZFX1BVQkxJQyIsIlBJTF9UT1VURVNfUE9QIiwiQUNDX1BJTF9FVVJPUEUiLCJBQ0NfUElMX01FUkNPU1VSIiwiR1JfUElMX1RPVVQiLCJQSU1fQ1VNVUwiLCJQSU1fRFVNUCIsIlBJTV9FWEVDX1JFUSIsIlBJTV9PQkpfU1lTVCIsIlBJTV9TQVZFX1BVQkxJQyIsIlBJTV9UT1VURVNfUE9QIiwiR1JfUElNX1RPVVQiXSwibGFuZ3VhZ2UiOiJlbl9JTiIsImxvZ2luIjoiejA0MDc4MyIsImNlcnQtdWlkIjoiIiwiZmlyc3ROYW1lIjoiU3JpbWF0aGkiLCJwb3N0T2ZmaWNlQm94IjoiSU5NQUFNSEEwMDAiLCJjb3VudHJ5Q29kZSI6IklOIiwicGVyaW1ldGVyIjoiTm9uZSIsImNvbXBhbnkiOiIwNzc0IiwidXNlclR5cGUiOiJCMkVfQ29udHJhY3RvciIsInBlcnNvblR5cGUiOiJjYXRwcmp2In0.JcpmurZxwI0B60aBbI8mDu2DVwKVbDleRVDu8_xs4hqXVWA6ypCXvAk5v9NLIhvwubPySBThPO1WdPJckvSyp9Rri3Exe75Jsw4RMB5nyd87QXA8Hm87Uv2vlLFtdo7qQEmloUoFUV0ya-KxAbUF6-KPqchGtKUCv7Z9IsPErSzjy2ldF2SC6FrOK-jJ-CDjo7vPOQMRRwm7k9xAnQTFhlyWwNuUYtQfFafqGaQ8SQN02sPEpwyyLlPASv2AvJ2_grANBfaiwEx2wLfM4BsksWbf_O06Uo5myyggztY5rw7sYpPtpa9NdC9TdNbDO1bELrr0tkkSGLDnwFfbTR-H5Q"
    };
    return this.http.post<any>(this.apiBaseURL, queryParam1, { headers: this.headers()});

  }

  public getObjectGet(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objget', queryParam, { headers: headers });
    // return this.http.post<any>(this.apiBaseURL + 'getting-objects', queryParam, { headers: this.headers() });
    let queryParam1 = 
    { 
      "payLoad": {
        input: queryParam.input, 
        region: queryParam.region
      },
      "endPoint": this.apisBaseURL + 'getting-objects', 
      "jwtToken": "eyJraWQiOiJEVXBTQWFUREtQRHphS19mV0NJcldxUUVOVTQ4bzM2ZXR5ZlV5eG9PaFY0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkY0VTUwV19fY2tfVGpLU3FOdHMxa09DeWx1Y0xnNXBXcnp5MkRlelZhQjQiLCJpc3MiOiJodHRwczovL3Nzby11YXQucmVuYXVsdC5jb20vb2F1dGgyL2F1c3R3b2VzaGJDa1BKeXcxNDE2IiwiYXVkIjoiaHR0cHM6Ly9hcGlzLnJlbmF1bHQuY29tIiwiaWF0IjoxNjk3Njk4NjMyLCJleHAiOjE2OTc3MDIyMzIsImNpZCI6Imlybi03NjY1OF91YXRfcGtjZV9qZ2F3Z2d4cXViMmEiLCJ1aWQiOiIwMHU2c3p4a3JwQ01uNmR6VjQxNyIsInNjcCI6WyJhcGlzLmRlZmF1bHQiLCJsZWdhY3kiLCJhbGxpYW5jZV9wcm9maWxlIiwib3BlbmlkIl0sImF1dGhfdGltZSI6MTY5NzY5MjEwMCwiYXBwcm92ZXIiOiJSQU5KQU4gUmFqZWV2IiwibGFzdE5hbWUiOiJWZWVyYXJhZ2F2YW4iLCJzdWIiOiJ6MDQwNzgzIiwicGVyc29uYWxVbmlxdWVJZCI6IjAwMDBGVkI0IiwicHJlZmVycmVkTGFuZ3VhZ2UiOiJlbiIsImFwcHJvdmVyTWFpbCI6IlJhamVldi5SYW5qYW5Acm50YmNpLmNvbSIsIm1haWwiOiJzcmltYXRoaS52ZWVyYXJhZ2F2YW4tZXh0ZXJuQHJudGJjaS5jb20iLCJpcm4iOiJJUk4tNzY2NTgiLCJkaXNwbGF5TmFtZSI6IlZFRVJBUkFHQVZBTiBTcmltYXRoaSIsImNvc3RDZW50ZXIiOiJRUTEzMDA3IiwiYXBwcm92ZXJJZCI6InowMDAzMzMiLCJyb2xlcyI6WyJQSUxfQURNSU4iLCJQSUxfQ1VNVUwiLCJQSUxfRFVNUCIsIlBJTF9FWEVDX1JFUSIsIlBJTF9PQkpfU1lTVCIsIlBJTF9TQVZFX1BVQkxJQyIsIlBJTF9UT1VURVNfUE9QIiwiQUNDX1BJTF9FVVJPUEUiLCJBQ0NfUElMX01FUkNPU1VSIiwiR1JfUElMX1RPVVQiLCJQSU1fQ1VNVUwiLCJQSU1fRFVNUCIsIlBJTV9FWEVDX1JFUSIsIlBJTV9PQkpfU1lTVCIsIlBJTV9TQVZFX1BVQkxJQyIsIlBJTV9UT1VURVNfUE9QIiwiR1JfUElNX1RPVVQiXSwibGFuZ3VhZ2UiOiJlbl9JTiIsImxvZ2luIjoiejA0MDc4MyIsImNlcnQtdWlkIjoiIiwiZmlyc3ROYW1lIjoiU3JpbWF0aGkiLCJwb3N0T2ZmaWNlQm94IjoiSU5NQUFNSEEwMDAiLCJjb3VudHJ5Q29kZSI6IklOIiwicGVyaW1ldGVyIjoiTm9uZSIsImNvbXBhbnkiOiIwNzc0IiwidXNlclR5cGUiOiJCMkVfQ29udHJhY3RvciIsInBlcnNvblR5cGUiOiJjYXRwcmp2In0.JcpmurZxwI0B60aBbI8mDu2DVwKVbDleRVDu8_xs4hqXVWA6ypCXvAk5v9NLIhvwubPySBThPO1WdPJckvSyp9Rri3Exe75Jsw4RMB5nyd87QXA8Hm87Uv2vlLFtdo7qQEmloUoFUV0ya-KxAbUF6-KPqchGtKUCv7Z9IsPErSzjy2ldF2SC6FrOK-jJ-CDjo7vPOQMRRwm7k9xAnQTFhlyWwNuUYtQfFafqGaQ8SQN02sPEpwyyLlPASv2AvJ2_grANBfaiwEx2wLfM4BsksWbf_O06Uo5myyggztY5rw7sYpPtpa9NdC9TdNbDO1bELrr0tkkSGLDnwFfbTR-H5Q"
    };
    return this.http.post<any>(this.apiBaseURL, queryParam1, { headers: this.headers()});

  }

  public saveObject(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objsave', queryParam, { headers: headers });
    // return this.http.post<any>(this.apiBaseURL + 'saving-objects', queryParam, { headers: this.headers() });
    let queryParam1 = 
    { 
      "payLoad": {
        input: queryParam.input, 
        region: queryParam.region
      },
      "endPoint": this.apisBaseURL + 'saving-objects', 
      "jwtToken": "eyJraWQiOiJEVXBTQWFUREtQRHphS19mV0NJcldxUUVOVTQ4bzM2ZXR5ZlV5eG9PaFY0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkY0VTUwV19fY2tfVGpLU3FOdHMxa09DeWx1Y0xnNXBXcnp5MkRlelZhQjQiLCJpc3MiOiJodHRwczovL3Nzby11YXQucmVuYXVsdC5jb20vb2F1dGgyL2F1c3R3b2VzaGJDa1BKeXcxNDE2IiwiYXVkIjoiaHR0cHM6Ly9hcGlzLnJlbmF1bHQuY29tIiwiaWF0IjoxNjk3Njk4NjMyLCJleHAiOjE2OTc3MDIyMzIsImNpZCI6Imlybi03NjY1OF91YXRfcGtjZV9qZ2F3Z2d4cXViMmEiLCJ1aWQiOiIwMHU2c3p4a3JwQ01uNmR6VjQxNyIsInNjcCI6WyJhcGlzLmRlZmF1bHQiLCJsZWdhY3kiLCJhbGxpYW5jZV9wcm9maWxlIiwib3BlbmlkIl0sImF1dGhfdGltZSI6MTY5NzY5MjEwMCwiYXBwcm92ZXIiOiJSQU5KQU4gUmFqZWV2IiwibGFzdE5hbWUiOiJWZWVyYXJhZ2F2YW4iLCJzdWIiOiJ6MDQwNzgzIiwicGVyc29uYWxVbmlxdWVJZCI6IjAwMDBGVkI0IiwicHJlZmVycmVkTGFuZ3VhZ2UiOiJlbiIsImFwcHJvdmVyTWFpbCI6IlJhamVldi5SYW5qYW5Acm50YmNpLmNvbSIsIm1haWwiOiJzcmltYXRoaS52ZWVyYXJhZ2F2YW4tZXh0ZXJuQHJudGJjaS5jb20iLCJpcm4iOiJJUk4tNzY2NTgiLCJkaXNwbGF5TmFtZSI6IlZFRVJBUkFHQVZBTiBTcmltYXRoaSIsImNvc3RDZW50ZXIiOiJRUTEzMDA3IiwiYXBwcm92ZXJJZCI6InowMDAzMzMiLCJyb2xlcyI6WyJQSUxfQURNSU4iLCJQSUxfQ1VNVUwiLCJQSUxfRFVNUCIsIlBJTF9FWEVDX1JFUSIsIlBJTF9PQkpfU1lTVCIsIlBJTF9TQVZFX1BVQkxJQyIsIlBJTF9UT1VURVNfUE9QIiwiQUNDX1BJTF9FVVJPUEUiLCJBQ0NfUElMX01FUkNPU1VSIiwiR1JfUElMX1RPVVQiLCJQSU1fQ1VNVUwiLCJQSU1fRFVNUCIsIlBJTV9FWEVDX1JFUSIsIlBJTV9PQkpfU1lTVCIsIlBJTV9TQVZFX1BVQkxJQyIsIlBJTV9UT1VURVNfUE9QIiwiR1JfUElNX1RPVVQiXSwibGFuZ3VhZ2UiOiJlbl9JTiIsImxvZ2luIjoiejA0MDc4MyIsImNlcnQtdWlkIjoiIiwiZmlyc3ROYW1lIjoiU3JpbWF0aGkiLCJwb3N0T2ZmaWNlQm94IjoiSU5NQUFNSEEwMDAiLCJjb3VudHJ5Q29kZSI6IklOIiwicGVyaW1ldGVyIjoiTm9uZSIsImNvbXBhbnkiOiIwNzc0IiwidXNlclR5cGUiOiJCMkVfQ29udHJhY3RvciIsInBlcnNvblR5cGUiOiJjYXRwcmp2In0.JcpmurZxwI0B60aBbI8mDu2DVwKVbDleRVDu8_xs4hqXVWA6ypCXvAk5v9NLIhvwubPySBThPO1WdPJckvSyp9Rri3Exe75Jsw4RMB5nyd87QXA8Hm87Uv2vlLFtdo7qQEmloUoFUV0ya-KxAbUF6-KPqchGtKUCv7Z9IsPErSzjy2ldF2SC6FrOK-jJ-CDjo7vPOQMRRwm7k9xAnQTFhlyWwNuUYtQfFafqGaQ8SQN02sPEpwyyLlPASv2AvJ2_grANBfaiwEx2wLfM4BsksWbf_O06Uo5myyggztY5rw7sYpPtpa9NdC9TdNbDO1bELrr0tkkSGLDnwFfbTR-H5Q"
    };
    return this.http.post<any>(this.apiBaseURL, queryParam1, { headers: this.headers()});

  }

  public deleteObject(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objsave', queryParam, { headers: headers });
    // return this.http.post<any>(this.apiBaseURL + 'deleting-objects', queryParam, { headers: this.headers() });
    let queryParam1 = 
    { 
      "payLoad": {
        input: queryParam.input, 
        region: queryParam.region
      },
      "endPoint": this.apisBaseURL + 'deleting-objects', 
      "jwtToken": "eyJraWQiOiJEVXBTQWFUREtQRHphS19mV0NJcldxUUVOVTQ4bzM2ZXR5ZlV5eG9PaFY0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkY0VTUwV19fY2tfVGpLU3FOdHMxa09DeWx1Y0xnNXBXcnp5MkRlelZhQjQiLCJpc3MiOiJodHRwczovL3Nzby11YXQucmVuYXVsdC5jb20vb2F1dGgyL2F1c3R3b2VzaGJDa1BKeXcxNDE2IiwiYXVkIjoiaHR0cHM6Ly9hcGlzLnJlbmF1bHQuY29tIiwiaWF0IjoxNjk3Njk4NjMyLCJleHAiOjE2OTc3MDIyMzIsImNpZCI6Imlybi03NjY1OF91YXRfcGtjZV9qZ2F3Z2d4cXViMmEiLCJ1aWQiOiIwMHU2c3p4a3JwQ01uNmR6VjQxNyIsInNjcCI6WyJhcGlzLmRlZmF1bHQiLCJsZWdhY3kiLCJhbGxpYW5jZV9wcm9maWxlIiwib3BlbmlkIl0sImF1dGhfdGltZSI6MTY5NzY5MjEwMCwiYXBwcm92ZXIiOiJSQU5KQU4gUmFqZWV2IiwibGFzdE5hbWUiOiJWZWVyYXJhZ2F2YW4iLCJzdWIiOiJ6MDQwNzgzIiwicGVyc29uYWxVbmlxdWVJZCI6IjAwMDBGVkI0IiwicHJlZmVycmVkTGFuZ3VhZ2UiOiJlbiIsImFwcHJvdmVyTWFpbCI6IlJhamVldi5SYW5qYW5Acm50YmNpLmNvbSIsIm1haWwiOiJzcmltYXRoaS52ZWVyYXJhZ2F2YW4tZXh0ZXJuQHJudGJjaS5jb20iLCJpcm4iOiJJUk4tNzY2NTgiLCJkaXNwbGF5TmFtZSI6IlZFRVJBUkFHQVZBTiBTcmltYXRoaSIsImNvc3RDZW50ZXIiOiJRUTEzMDA3IiwiYXBwcm92ZXJJZCI6InowMDAzMzMiLCJyb2xlcyI6WyJQSUxfQURNSU4iLCJQSUxfQ1VNVUwiLCJQSUxfRFVNUCIsIlBJTF9FWEVDX1JFUSIsIlBJTF9PQkpfU1lTVCIsIlBJTF9TQVZFX1BVQkxJQyIsIlBJTF9UT1VURVNfUE9QIiwiQUNDX1BJTF9FVVJPUEUiLCJBQ0NfUElMX01FUkNPU1VSIiwiR1JfUElMX1RPVVQiLCJQSU1fQ1VNVUwiLCJQSU1fRFVNUCIsIlBJTV9FWEVDX1JFUSIsIlBJTV9PQkpfU1lTVCIsIlBJTV9TQVZFX1BVQkxJQyIsIlBJTV9UT1VURVNfUE9QIiwiR1JfUElNX1RPVVQiXSwibGFuZ3VhZ2UiOiJlbl9JTiIsImxvZ2luIjoiejA0MDc4MyIsImNlcnQtdWlkIjoiIiwiZmlyc3ROYW1lIjoiU3JpbWF0aGkiLCJwb3N0T2ZmaWNlQm94IjoiSU5NQUFNSEEwMDAiLCJjb3VudHJ5Q29kZSI6IklOIiwicGVyaW1ldGVyIjoiTm9uZSIsImNvbXBhbnkiOiIwNzc0IiwidXNlclR5cGUiOiJCMkVfQ29udHJhY3RvciIsInBlcnNvblR5cGUiOiJjYXRwcmp2In0.JcpmurZxwI0B60aBbI8mDu2DVwKVbDleRVDu8_xs4hqXVWA6ypCXvAk5v9NLIhvwubPySBThPO1WdPJckvSyp9Rri3Exe75Jsw4RMB5nyd87QXA8Hm87Uv2vlLFtdo7qQEmloUoFUV0ya-KxAbUF6-KPqchGtKUCv7Z9IsPErSzjy2ldF2SC6FrOK-jJ-CDjo7vPOQMRRwm7k9xAnQTFhlyWwNuUYtQfFafqGaQ8SQN02sPEpwyyLlPASv2AvJ2_grANBfaiwEx2wLfM4BsksWbf_O06Uo5myyggztY5rw7sYpPtpa9NdC9TdNbDO1bELrr0tkkSGLDnwFfbTR-H5Q"
    };
    return this.http.post<any>(this.apiBaseURL, queryParam1, { headers: this.headers()});

  }

  public memoryReleaseObject(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objsave', queryParam, { headers: headers });
    // return this.http.post<any>(this.apiBaseURL + 'memory-releases', queryParam, { headers: this.headers() });
    let queryParam1 = 
    { 
      "payLoad": {
        input: queryParam.input, 
        region: queryParam.region
      },
      "endPoint": this.apisBaseURL + 'memory-releases', 
      "jwtToken": "eyJraWQiOiJEVXBTQWFUREtQRHphS19mV0NJcldxUUVOVTQ4bzM2ZXR5ZlV5eG9PaFY0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkY0VTUwV19fY2tfVGpLU3FOdHMxa09DeWx1Y0xnNXBXcnp5MkRlelZhQjQiLCJpc3MiOiJodHRwczovL3Nzby11YXQucmVuYXVsdC5jb20vb2F1dGgyL2F1c3R3b2VzaGJDa1BKeXcxNDE2IiwiYXVkIjoiaHR0cHM6Ly9hcGlzLnJlbmF1bHQuY29tIiwiaWF0IjoxNjk3Njk4NjMyLCJleHAiOjE2OTc3MDIyMzIsImNpZCI6Imlybi03NjY1OF91YXRfcGtjZV9qZ2F3Z2d4cXViMmEiLCJ1aWQiOiIwMHU2c3p4a3JwQ01uNmR6VjQxNyIsInNjcCI6WyJhcGlzLmRlZmF1bHQiLCJsZWdhY3kiLCJhbGxpYW5jZV9wcm9maWxlIiwib3BlbmlkIl0sImF1dGhfdGltZSI6MTY5NzY5MjEwMCwiYXBwcm92ZXIiOiJSQU5KQU4gUmFqZWV2IiwibGFzdE5hbWUiOiJWZWVyYXJhZ2F2YW4iLCJzdWIiOiJ6MDQwNzgzIiwicGVyc29uYWxVbmlxdWVJZCI6IjAwMDBGVkI0IiwicHJlZmVycmVkTGFuZ3VhZ2UiOiJlbiIsImFwcHJvdmVyTWFpbCI6IlJhamVldi5SYW5qYW5Acm50YmNpLmNvbSIsIm1haWwiOiJzcmltYXRoaS52ZWVyYXJhZ2F2YW4tZXh0ZXJuQHJudGJjaS5jb20iLCJpcm4iOiJJUk4tNzY2NTgiLCJkaXNwbGF5TmFtZSI6IlZFRVJBUkFHQVZBTiBTcmltYXRoaSIsImNvc3RDZW50ZXIiOiJRUTEzMDA3IiwiYXBwcm92ZXJJZCI6InowMDAzMzMiLCJyb2xlcyI6WyJQSUxfQURNSU4iLCJQSUxfQ1VNVUwiLCJQSUxfRFVNUCIsIlBJTF9FWEVDX1JFUSIsIlBJTF9PQkpfU1lTVCIsIlBJTF9TQVZFX1BVQkxJQyIsIlBJTF9UT1VURVNfUE9QIiwiQUNDX1BJTF9FVVJPUEUiLCJBQ0NfUElMX01FUkNPU1VSIiwiR1JfUElMX1RPVVQiLCJQSU1fQ1VNVUwiLCJQSU1fRFVNUCIsIlBJTV9FWEVDX1JFUSIsIlBJTV9PQkpfU1lTVCIsIlBJTV9TQVZFX1BVQkxJQyIsIlBJTV9UT1VURVNfUE9QIiwiR1JfUElNX1RPVVQiXSwibGFuZ3VhZ2UiOiJlbl9JTiIsImxvZ2luIjoiejA0MDc4MyIsImNlcnQtdWlkIjoiIiwiZmlyc3ROYW1lIjoiU3JpbWF0aGkiLCJwb3N0T2ZmaWNlQm94IjoiSU5NQUFNSEEwMDAiLCJjb3VudHJ5Q29kZSI6IklOIiwicGVyaW1ldGVyIjoiTm9uZSIsImNvbXBhbnkiOiIwNzc0IiwidXNlclR5cGUiOiJCMkVfQ29udHJhY3RvciIsInBlcnNvblR5cGUiOiJjYXRwcmp2In0.JcpmurZxwI0B60aBbI8mDu2DVwKVbDleRVDu8_xs4hqXVWA6ypCXvAk5v9NLIhvwubPySBThPO1WdPJckvSyp9Rri3Exe75Jsw4RMB5nyd87QXA8Hm87Uv2vlLFtdo7qQEmloUoFUV0ya-KxAbUF6-KPqchGtKUCv7Z9IsPErSzjy2ldF2SC6FrOK-jJ-CDjo7vPOQMRRwm7k9xAnQTFhlyWwNuUYtQfFafqGaQ8SQN02sPEpwyyLlPASv2AvJ2_grANBfaiwEx2wLfM4BsksWbf_O06Uo5myyggztY5rw7sYpPtpa9NdC9TdNbDO1bELrr0tkkSGLDnwFfbTR-H5Q"
    };
    return this.http.post<any>(this.apiBaseURL, queryParam1, { headers: this.headers()});

  }

  public exportResultObject(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objsave', queryParam, { headers: headers });
    // return this.http.post<any>(this.apiBaseURL + 'export-results', queryParam, { headers: this.headers() });
    let queryParam1 = 
    { 
      "payLoad": {
        inputLists: queryParam.inputLists, 
        region: queryParam.region
      },
      "endPoint": this.apisBaseURL + 'export-results', 
      "jwtToken": "eyJraWQiOiJEVXBTQWFUREtQRHphS19mV0NJcldxUUVOVTQ4bzM2ZXR5ZlV5eG9PaFY0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkY0VTUwV19fY2tfVGpLU3FOdHMxa09DeWx1Y0xnNXBXcnp5MkRlelZhQjQiLCJpc3MiOiJodHRwczovL3Nzby11YXQucmVuYXVsdC5jb20vb2F1dGgyL2F1c3R3b2VzaGJDa1BKeXcxNDE2IiwiYXVkIjoiaHR0cHM6Ly9hcGlzLnJlbmF1bHQuY29tIiwiaWF0IjoxNjk3Njk4NjMyLCJleHAiOjE2OTc3MDIyMzIsImNpZCI6Imlybi03NjY1OF91YXRfcGtjZV9qZ2F3Z2d4cXViMmEiLCJ1aWQiOiIwMHU2c3p4a3JwQ01uNmR6VjQxNyIsInNjcCI6WyJhcGlzLmRlZmF1bHQiLCJsZWdhY3kiLCJhbGxpYW5jZV9wcm9maWxlIiwib3BlbmlkIl0sImF1dGhfdGltZSI6MTY5NzY5MjEwMCwiYXBwcm92ZXIiOiJSQU5KQU4gUmFqZWV2IiwibGFzdE5hbWUiOiJWZWVyYXJhZ2F2YW4iLCJzdWIiOiJ6MDQwNzgzIiwicGVyc29uYWxVbmlxdWVJZCI6IjAwMDBGVkI0IiwicHJlZmVycmVkTGFuZ3VhZ2UiOiJlbiIsImFwcHJvdmVyTWFpbCI6IlJhamVldi5SYW5qYW5Acm50YmNpLmNvbSIsIm1haWwiOiJzcmltYXRoaS52ZWVyYXJhZ2F2YW4tZXh0ZXJuQHJudGJjaS5jb20iLCJpcm4iOiJJUk4tNzY2NTgiLCJkaXNwbGF5TmFtZSI6IlZFRVJBUkFHQVZBTiBTcmltYXRoaSIsImNvc3RDZW50ZXIiOiJRUTEzMDA3IiwiYXBwcm92ZXJJZCI6InowMDAzMzMiLCJyb2xlcyI6WyJQSUxfQURNSU4iLCJQSUxfQ1VNVUwiLCJQSUxfRFVNUCIsIlBJTF9FWEVDX1JFUSIsIlBJTF9PQkpfU1lTVCIsIlBJTF9TQVZFX1BVQkxJQyIsIlBJTF9UT1VURVNfUE9QIiwiQUNDX1BJTF9FVVJPUEUiLCJBQ0NfUElMX01FUkNPU1VSIiwiR1JfUElMX1RPVVQiLCJQSU1fQ1VNVUwiLCJQSU1fRFVNUCIsIlBJTV9FWEVDX1JFUSIsIlBJTV9PQkpfU1lTVCIsIlBJTV9TQVZFX1BVQkxJQyIsIlBJTV9UT1VURVNfUE9QIiwiR1JfUElNX1RPVVQiXSwibGFuZ3VhZ2UiOiJlbl9JTiIsImxvZ2luIjoiejA0MDc4MyIsImNlcnQtdWlkIjoiIiwiZmlyc3ROYW1lIjoiU3JpbWF0aGkiLCJwb3N0T2ZmaWNlQm94IjoiSU5NQUFNSEEwMDAiLCJjb3VudHJ5Q29kZSI6IklOIiwicGVyaW1ldGVyIjoiTm9uZSIsImNvbXBhbnkiOiIwNzc0IiwidXNlclR5cGUiOiJCMkVfQ29udHJhY3RvciIsInBlcnNvblR5cGUiOiJjYXRwcmp2In0.JcpmurZxwI0B60aBbI8mDu2DVwKVbDleRVDu8_xs4hqXVWA6ypCXvAk5v9NLIhvwubPySBThPO1WdPJckvSyp9Rri3Exe75Jsw4RMB5nyd87QXA8Hm87Uv2vlLFtdo7qQEmloUoFUV0ya-KxAbUF6-KPqchGtKUCv7Z9IsPErSzjy2ldF2SC6FrOK-jJ-CDjo7vPOQMRRwm7k9xAnQTFhlyWwNuUYtQfFafqGaQ8SQN02sPEpwyyLlPASv2AvJ2_grANBfaiwEx2wLfM4BsksWbf_O06Uo5myyggztY5rw7sYpPtpa9NdC9TdNbDO1bELrr0tkkSGLDnwFfbTR-H5Q"
    };
    return this.http.post<any>(this.apiBaseURL, queryParam1, { headers: this.headers()});
  
  }

  downloadFile(url): Observable<any> {
    // return this.http.get(url, { responseType: 'blob' });
    return this.http.get<Blob>(url, { observe: 'response', responseType: 'blob' as 'json' })
  }

  uploadFile(queryParam: any): Observable<any> {
    // return this.http.get(url, { responseType: 'blob' });
    return this.http.post<any>(this.uploadURL, queryParam, { headers: this.headers4FileUpload() });
  }

  public specialBases(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objsave', queryParam, { headers: headers });
    // return this.http.post<any>(this.apiBaseURL + 'special-bases', queryParam, { headers: this.headers() });
    let queryParam1 = 
    { 
      "payLoad": {
        input: queryParam.input, 
        region: queryParam.region
      },
      "endPoint": this.apisBaseURL + 'special-bases', 
      "jwtToken": "eyJraWQiOiJEVXBTQWFUREtQRHphS19mV0NJcldxUUVOVTQ4bzM2ZXR5ZlV5eG9PaFY0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkY0VTUwV19fY2tfVGpLU3FOdHMxa09DeWx1Y0xnNXBXcnp5MkRlelZhQjQiLCJpc3MiOiJodHRwczovL3Nzby11YXQucmVuYXVsdC5jb20vb2F1dGgyL2F1c3R3b2VzaGJDa1BKeXcxNDE2IiwiYXVkIjoiaHR0cHM6Ly9hcGlzLnJlbmF1bHQuY29tIiwiaWF0IjoxNjk3Njk4NjMyLCJleHAiOjE2OTc3MDIyMzIsImNpZCI6Imlybi03NjY1OF91YXRfcGtjZV9qZ2F3Z2d4cXViMmEiLCJ1aWQiOiIwMHU2c3p4a3JwQ01uNmR6VjQxNyIsInNjcCI6WyJhcGlzLmRlZmF1bHQiLCJsZWdhY3kiLCJhbGxpYW5jZV9wcm9maWxlIiwib3BlbmlkIl0sImF1dGhfdGltZSI6MTY5NzY5MjEwMCwiYXBwcm92ZXIiOiJSQU5KQU4gUmFqZWV2IiwibGFzdE5hbWUiOiJWZWVyYXJhZ2F2YW4iLCJzdWIiOiJ6MDQwNzgzIiwicGVyc29uYWxVbmlxdWVJZCI6IjAwMDBGVkI0IiwicHJlZmVycmVkTGFuZ3VhZ2UiOiJlbiIsImFwcHJvdmVyTWFpbCI6IlJhamVldi5SYW5qYW5Acm50YmNpLmNvbSIsIm1haWwiOiJzcmltYXRoaS52ZWVyYXJhZ2F2YW4tZXh0ZXJuQHJudGJjaS5jb20iLCJpcm4iOiJJUk4tNzY2NTgiLCJkaXNwbGF5TmFtZSI6IlZFRVJBUkFHQVZBTiBTcmltYXRoaSIsImNvc3RDZW50ZXIiOiJRUTEzMDA3IiwiYXBwcm92ZXJJZCI6InowMDAzMzMiLCJyb2xlcyI6WyJQSUxfQURNSU4iLCJQSUxfQ1VNVUwiLCJQSUxfRFVNUCIsIlBJTF9FWEVDX1JFUSIsIlBJTF9PQkpfU1lTVCIsIlBJTF9TQVZFX1BVQkxJQyIsIlBJTF9UT1VURVNfUE9QIiwiQUNDX1BJTF9FVVJPUEUiLCJBQ0NfUElMX01FUkNPU1VSIiwiR1JfUElMX1RPVVQiLCJQSU1fQ1VNVUwiLCJQSU1fRFVNUCIsIlBJTV9FWEVDX1JFUSIsIlBJTV9PQkpfU1lTVCIsIlBJTV9TQVZFX1BVQkxJQyIsIlBJTV9UT1VURVNfUE9QIiwiR1JfUElNX1RPVVQiXSwibGFuZ3VhZ2UiOiJlbl9JTiIsImxvZ2luIjoiejA0MDc4MyIsImNlcnQtdWlkIjoiIiwiZmlyc3ROYW1lIjoiU3JpbWF0aGkiLCJwb3N0T2ZmaWNlQm94IjoiSU5NQUFNSEEwMDAiLCJjb3VudHJ5Q29kZSI6IklOIiwicGVyaW1ldGVyIjoiTm9uZSIsImNvbXBhbnkiOiIwNzc0IiwidXNlclR5cGUiOiJCMkVfQ29udHJhY3RvciIsInBlcnNvblR5cGUiOiJjYXRwcmp2In0.JcpmurZxwI0B60aBbI8mDu2DVwKVbDleRVDu8_xs4hqXVWA6ypCXvAk5v9NLIhvwubPySBThPO1WdPJckvSyp9Rri3Exe75Jsw4RMB5nyd87QXA8Hm87Uv2vlLFtdo7qQEmloUoFUV0ya-KxAbUF6-KPqchGtKUCv7Z9IsPErSzjy2ldF2SC6FrOK-jJ-CDjo7vPOQMRRwm7k9xAnQTFhlyWwNuUYtQfFafqGaQ8SQN02sPEpwyyLlPASv2AvJ2_grANBfaiwEx2wLfM4BsksWbf_O06Uo5myyggztY5rw7sYpPtpa9NdC9TdNbDO1bELrr0tkkSGLDnwFfbTR-H5Q"
    };
    return this.http.post<any>(this.apiBaseURL, queryParam1, { headers: this.headers()});

  }

  public getlostloginlist(): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objsave', queryParam, { headers: headers });
    return this.http.get<any>(this.apiLastLoginURL + "lostloginlist", { headers: this.headers() });
  }

  public createuserlogin(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objsave', queryParam, { headers: headers });
    return this.http.post<any>(this.apiLastLoginURL + "loginentry", queryParam, { headers: this.headers() });
  }

  public writingLogs(queryParam: any): Observable<any> {
    //console.log("writing log param", queryParam);
    // return this.http.post<any>(this.apiBaseURL + 'svc_objsave', queryParam, { headers: headers });
    // return this.http.post<any>(this.apiBaseURL + 'writing-logs', queryParam, { headers: this.headers() });
    let queryParam1 = 
    { 
      "payLoad": {
        inputLists: queryParam.inputLists, 
        region: queryParam.region
      },
      "endPoint": this.apisBaseURL + 'writing-logs', 
      "jwtToken": "eyJraWQiOiJEVXBTQWFUREtQRHphS19mV0NJcldxUUVOVTQ4bzM2ZXR5ZlV5eG9PaFY0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkY0VTUwV19fY2tfVGpLU3FOdHMxa09DeWx1Y0xnNXBXcnp5MkRlelZhQjQiLCJpc3MiOiJodHRwczovL3Nzby11YXQucmVuYXVsdC5jb20vb2F1dGgyL2F1c3R3b2VzaGJDa1BKeXcxNDE2IiwiYXVkIjoiaHR0cHM6Ly9hcGlzLnJlbmF1bHQuY29tIiwiaWF0IjoxNjk3Njk4NjMyLCJleHAiOjE2OTc3MDIyMzIsImNpZCI6Imlybi03NjY1OF91YXRfcGtjZV9qZ2F3Z2d4cXViMmEiLCJ1aWQiOiIwMHU2c3p4a3JwQ01uNmR6VjQxNyIsInNjcCI6WyJhcGlzLmRlZmF1bHQiLCJsZWdhY3kiLCJhbGxpYW5jZV9wcm9maWxlIiwib3BlbmlkIl0sImF1dGhfdGltZSI6MTY5NzY5MjEwMCwiYXBwcm92ZXIiOiJSQU5KQU4gUmFqZWV2IiwibGFzdE5hbWUiOiJWZWVyYXJhZ2F2YW4iLCJzdWIiOiJ6MDQwNzgzIiwicGVyc29uYWxVbmlxdWVJZCI6IjAwMDBGVkI0IiwicHJlZmVycmVkTGFuZ3VhZ2UiOiJlbiIsImFwcHJvdmVyTWFpbCI6IlJhamVldi5SYW5qYW5Acm50YmNpLmNvbSIsIm1haWwiOiJzcmltYXRoaS52ZWVyYXJhZ2F2YW4tZXh0ZXJuQHJudGJjaS5jb20iLCJpcm4iOiJJUk4tNzY2NTgiLCJkaXNwbGF5TmFtZSI6IlZFRVJBUkFHQVZBTiBTcmltYXRoaSIsImNvc3RDZW50ZXIiOiJRUTEzMDA3IiwiYXBwcm92ZXJJZCI6InowMDAzMzMiLCJyb2xlcyI6WyJQSUxfQURNSU4iLCJQSUxfQ1VNVUwiLCJQSUxfRFVNUCIsIlBJTF9FWEVDX1JFUSIsIlBJTF9PQkpfU1lTVCIsIlBJTF9TQVZFX1BVQkxJQyIsIlBJTF9UT1VURVNfUE9QIiwiQUNDX1BJTF9FVVJPUEUiLCJBQ0NfUElMX01FUkNPU1VSIiwiR1JfUElMX1RPVVQiLCJQSU1fQ1VNVUwiLCJQSU1fRFVNUCIsIlBJTV9FWEVDX1JFUSIsIlBJTV9PQkpfU1lTVCIsIlBJTV9TQVZFX1BVQkxJQyIsIlBJTV9UT1VURVNfUE9QIiwiR1JfUElNX1RPVVQiXSwibGFuZ3VhZ2UiOiJlbl9JTiIsImxvZ2luIjoiejA0MDc4MyIsImNlcnQtdWlkIjoiIiwiZmlyc3ROYW1lIjoiU3JpbWF0aGkiLCJwb3N0T2ZmaWNlQm94IjoiSU5NQUFNSEEwMDAiLCJjb3VudHJ5Q29kZSI6IklOIiwicGVyaW1ldGVyIjoiTm9uZSIsImNvbXBhbnkiOiIwNzc0IiwidXNlclR5cGUiOiJCMkVfQ29udHJhY3RvciIsInBlcnNvblR5cGUiOiJjYXRwcmp2In0.JcpmurZxwI0B60aBbI8mDu2DVwKVbDleRVDu8_xs4hqXVWA6ypCXvAk5v9NLIhvwubPySBThPO1WdPJckvSyp9Rri3Exe75Jsw4RMB5nyd87QXA8Hm87Uv2vlLFtdo7qQEmloUoFUV0ya-KxAbUF6-KPqchGtKUCv7Z9IsPErSzjy2ldF2SC6FrOK-jJ-CDjo7vPOQMRRwm7k9xAnQTFhlyWwNuUYtQfFafqGaQ8SQN02sPEpwyyLlPASv2AvJ2_grANBfaiwEx2wLfM4BsksWbf_O06Uo5myyggztY5rw7sYpPtpa9NdC9TdNbDO1bELrr0tkkSGLDnwFfbTR-H5Q"
    };
    return this.http.post<any>(this.apiBaseURL, queryParam1, { headers: this.headers()});
  
  }

}

