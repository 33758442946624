import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { BackendApiService } from '../../../../services/backend-api/backend-api.service';
import { UtilService } from '../../../../services/util/util.service';

@Component({
  selector: 'app-character-number-list',
  templateUrl: './character-number-list.component.html',
  styleUrls: ['./character-number-list.component.scss']
})

export class CharacterNumberListComponent implements OnChanges {
  @Input() selectedListItem: string;
  @Input() editValue: string[] = [];
  @Output() setCharacterFlagData = new EventEmitter();
  characterNumberValue = "=";
  numberValue;
  isSubmitted: boolean = false;
  characterNumberList = [{ name: 'EQUAL', value: '=' },
  { name: 'DIFFERENT', value: '<>' },
  { name: 'INFERIOR', value: '<=' },
  { name: 'SUPERIOR', value: '>=' }];

  ngOnChanges() {
    if (this.editValue && this.editValue.length > 0) {
      this.characterNumberList.forEach(item => {
        // if (this.editValue[1].includes(item.value)) {
        const len = item.value.length;
        if (this.editValue[1].substr(0, len) === item.value) {
          this.editValue = this.editValue[1].split(item.value);
          this.characterNumberValue = item.value;
          //.log("old=",parseInt(this.editValue[1]),"new=",parseInt(this.editValue[1],10))
          this.numberValue = parseInt(this.editValue[1],10);
        }
      });
    }
  }

  maxLengthCheck(object) {
    object.target.value = Math.abs(object.target.value);
    if (object.target.value.length > object.target.maxLength)
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    this.numberValue = object.target.value;
  }

  submit() {
    if (!this.characterNumberValue) {
      this.isSubmitted = true;
    }
    let value = {
      'format': this.characterNumberValue,
      'value': this.numberValue,
      displayGroupingForm: false
    }
    this.setCharacterFlagData.emit(value);
  }

  cancel() {
    let value = {
      value: '',
      displayGroupingForm: false
    };
    this.setCharacterFlagData.emit(value);
  }
}
