export const environment = {
  production: true,
  idp: {
    // scope: 'openid arcaX role-pil-irn6804',
    scope: 'openid alliance_profile apis.default legacy',
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    redirectUri: window.location.origin.replace('https://', 'http://'),
    prod: {
      openIdEndPoint: 'https://idp.renault.com/nidp/oauth/nam/.well-known/openid-configuration',
      loginUrl: 'https://idp.renault.com/nidp/oauth/nam/authz',
      logoutUrl: 'https://idp.renault.com/nidp/oauth/nam/authz',
      userinfoEndpoint: 'https://idp.renault.com/nidp/oauth/nam/userinfo',
      issuer: 'https://idp.renault.com/nidp/oauth/nam/authz',
      responseType: 'id_token token',
      postLoginRoute: '',
      forbiddenRoute: '',
      unauthorizedRoute: '',
      dev: {
        ///IDP
        /*loginUrl: 'https://idp2.renault.com/nidp/oauth/nam/authz',
        logoutUrl: 'https://idp2.renault.com/nidp/app/logout',
        userinfoEndpoint: 'https://idp2.renault.com/nidp/oauth/nam/userinfo',
        issuer: 'https://idp2.renault.com/nidp/oauth/nam',
        client_id: 'ed066280-a724-4b16-aeba-b97f98d4f51e',*/

        //OKTA
        issuer: 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416',
        loginUrl: 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/authorize',
        userinfoEndpoint: 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/userinfo',
        logoutUrl: '',
        client_id: 'irn-76658_uat_pkce_jgawggxqub2a',

        redirect_uri: 'https://pil-app.gke2.dev.gcp.renault.com/',
        baseUrl: 'https://pil-app.gke2.dev.gcp.renault.com/',
        apiURL: 'https://pil-app.gke2.dev.gcp.renault.com/common-backend-api/common-api-request',
        apikey: 'XM789pzBwYkchgTWJwdk3In0veoqFUct',
        // apiURL: '/pil/',
        piwikID: 2342,
        apisBaseUrl: 'https://apis-qa.renault.com/pil/v1/'
      },
      re7: {

        //IDP
        /*loginUrl: 'https://idp2.renault.com/nidp/oauth/nam/authz',
        logoutUrl: 'https://idp2.renault.com/nidp/app/logout',
        userinfoEndpoint: 'https://idp2.renault.com/nidp/oauth/nam/userinfo',
        issuer: 'https://idp2.renault.com/nidp/oauth/nam',
        client_id: '3cafd93b-e110-4788-bc68-330f17a096ab',*/

        //OKTA
        issuer: 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416',
        loginUrl: 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/authorize',
        userinfoEndpoint: 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/userinfo',
        logoutUrl: '',
        client_id: 'irn-76658_uat_pkce_jgawggxqub2a',


        redirect_uri: 'https://pil-app.gke2.int.gcp.renault.com/',
        baseUrl: 'https://pil-app.gke2.int.gcp.renault.com/',
        // apiURL: '/pil/',
        apiURL: 'https://pil-app.gke2.int.gcp.renault.com/common-backend-api/common-api-request',
        apikey: 'XM789pzBwYkchgTWJwdk3In0veoqFUct',
        piwikID: 2341,
        apisBaseUrl: 'https://apis-qa.renault.com/pil/v1/'
      },
      localhost: {

        //IDP
        /*loginUrl: 'https://idp2.renault.com/nidp/oauth/nam/authz',
        logoutUrl: 'https://idp2.renault.com/nidp/app/logout',
        userinfoEndpoint: 'https://idp2.renault.com/nidp/oauth/nam/userinfo',
        issuer: 'https://idp2.renault.com/nidp/oauth/nam',
        client_id: '3cafd93b-e110-4788-bc68-330f17a096ab',*/

        //OKTA
        issuer: 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416',
        loginUrl: 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/authorize',
        userinfoEndpoint: 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/userinfo',
        logoutUrl: '',
        client_id: 'irn-76658_uat_pkce_jgawggxqub2a',


        redirect_uri: 'http://localhost:4200/',
        baseUrl: 'https://pil-app.gke2.int.gcp.renault.com/',
        // apiURL: '/pil/',
        apiURL: 'https://pil-app.gke2.int.gcp.renault.com/common-backend-api/common-api-request',
        apikey: 'XM789pzBwYkchgTWJwdk3In0veoqFUct',
        piwikID: 2341,
        apisBaseUrl: 'https://apis-qa.renault.com/pil/v1/'
      },
      ope: {
        // IDP
        /* loginUrl: 'https://idp.renault.com/nidp/oauth/nam/authz',
        logoutUrl: 'https://idp.renault.com/nidp/app/logout',
        userinfoEndpoint: 'https://idp.renault.com/nidp/oauth/nam/userinfo',
        issuer: 'https://idp.renault.com/nidp/oauth/nam',
        client_id: 'b5572c06-4e2a-408c-8cab-a6c97ea96a2e', */

        // OKTA
        issuer: 'https://sso.renault.com/oauth2/aus133y6mks4ptDss417',
        loginUrl: 'https://sso.renault.com/oauth2/aus133y6mks4ptDss417/v1/authorize',
        userinfoEndpoint: 'https://sso.renault.com/oauth2/aus133y6mks4ptDss417/v1/userinfo',
        logoutUrl: '',
        client_id: 'irn-76658_ope_pkce_9idgcotpigco',

        redirect_uri: 'https://pil.app.renault.com/',
        baseUrl: 'https://pil.app.renault.com/',
        // apiURL: 'https://apis.renault.com/pil/v1/',
        apiURL: 'https://pil.app.renault.com/common-backend-api/common-api-request',
        apikey: 'eicXN1S2aTscGJrRfExQM5oA3M24IGMB',
        // apiURL: '/pil/',
        piwikID: 2343,
        apisBaseUrl: 'https://apis.renault.com/pil/v1/'
      }
    }
  },
  timeOutwhenNotInteractive: 60,
  maxIdAllowed: 60,
};

