import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { ToastyService } from 'ng2-toasty';
import { RequestService } from './../../services/request/request.service';
import { TranslateService } from '@ngx-translate/core';
import 'moment/min/locales.min';
import * as moment from 'moment';
import { UtilService } from '../../services/util/util.service';
import { BackendApiService } from '../../services/backend-api/backend-api.service';
import { MatomoTracker, MatomoInjector } from 'ngx-matomo';
import { environment } from '../../../environments/environment.prod';
import * as pkg from '../../../../package.json';

@Component({
  selector: 'app-authentification',
  templateUrl: './authentification.component.html',
  styleUrls: ['./authentification.component.scss'],

})
export class AuthentificationComponent implements OnInit {

  @ViewChild('userUnauthorizedModal', { static: true })

  processing = false; // login processing
  isAuthorized: boolean;
  isAuthorizedSubscription: Subscription;
  apiResult: string;
  ipn: string;
  userrole: any;

  constructor(private oauthService: OAuthService, private matomoTracker: MatomoTracker, private matomoInjector: MatomoInjector, readonly translateService: TranslateService, readonly toastyService: ToastyService,
    private router: Router, readonly utilService: UtilService, readonly apiService: BackendApiService,
    private http: HttpClient, readonly requestService: RequestService) {
    this.isAuthorized = false;

  }

  ngOnInit() {
    this.IDPLoginAndStartSession();
    // if (this.oauthService.hasValidIdToken()) {
    //   // console.log(this.oauthService.hasValidIdToken(), "this.oauthService.hasValidIdToken()", this.oauthService.getAccessToken());

    //   this.checkFirstAccces();
    // } else {
    //   this.login();
    // }
  }

  /** Check is first Acces **/
  private checkFirstAccces() {
    this.processing = true;
    console.log("this.oauthService.getIdentityClaims()", this.oauthService.getIdentityClaims());
    this.oauthService.loadUserProfile().then(() => {
      const claims = this.oauthService.getIdentityClaims();

      sessionStorage.setItem("start_ts", sessionStorage.getItem("expires_at"));
      if (claims['preferredLanguage'].split(',').length > 1) {
        this.requestService.addLang('fr');
        this.translateService.setDefaultLang('fr');
      } else {
        const supportedLanguages = ['en', 'fr'];
        if (supportedLanguages.indexOf(claims['preferredLanguage']) > -1) {
          this.requestService.addLang(claims['preferredLanguage'].toLocaleLowerCase());
          this.translateService.setDefaultLang(claims['preferredLanguage'].toLocaleLowerCase());
        } else {
          this.requestService.addLang('fr');
          this.translateService.setDefaultLang('fr');
        }
      }

      let selectedLang = this.requestService.getSelectedLang();
      const supportedLanguages = ['en', 'fr'];
      // if ((selectedLang === undefined || selectedLang === null) && supportedLanguages.indexOf(selectedLang) <= -1) {
      //   this.translateService.setDefaultLang('fr');
      //   this.requestService.addLang('fr')
      // } else {
      //   this.translateService.setDefaultLang(selectedLang);
      // }
      if (selectedLang !== undefined && selectedLang !== null && supportedLanguages.indexOf(selectedLang) > -1) {
        this.translateService.setDefaultLang(selectedLang);
      } else {
        this.translateService.setDefaultLang('fr');
        this.requestService.addLang('fr');
        // this.translateService.use('fr');
      }
      if (this.translateService.currentLang) {
        const currentLanguage =
          supportedLanguages.indexOf(this.translateService.currentLang) >= 0 ?
            this.translateService.currentLang :
            this.translateService.getBrowserLang();
        this.translateService.addLangs(supportedLanguages);
        this.translateService.use(currentLanguage);
        moment.locale(currentLanguage);
      }

      ////IDP
      /*if (!claims['role-acc-pil-irn6804']) {
        this.processing = false;
        this.toastyService.warning('Unauthorized user');
        this.router.navigate(['/unauthorized']);
      } else {
        this.processing = false;
        let region = this.requestService.getRegionMsg();
        if (region['europe'] === null && region['mercosur'] === null) {
          this.requestService.addRegionMsg('false', 'false');
        }



        this.matomoPageTrack();
        this.utilService.setExpired(false);

        const lastURL = localStorage.getItem("lURL");
        if (lastURL) {
          localStorage.removeItem("lURL");

          Object.keys(localStorage).forEach((key) => {
            // console.log("sessionStorage[key]", sessionStorage[key])
            if (sessionStorage[key] === undefined) {
              sessionStorage[key] = localStorage.getItem(key);
            }
            localStorage.removeItem(key);
          });
          this.router.navigate([lastURL]);
        } else {
          this.router.navigate(['/home']);
        }
        // this.goToFirstScreen();
      }*/

      ////OKTA
      if (!claims['roles']) {
        this.processing = false;
        this.toastyService.warning('Unauthorized user');
        this.router.navigate(['/unauthorized']);
      } else {
        this.processing = false;
        let region = this.requestService.getRegionMsg();
        if (region['europe'] === null && region['mercosur'] === null) {
          this.requestService.addRegionMsg('false', 'false');
        }



        this.matomoPageTrack();
        this.utilService.setExpired(false);

        const lastURL = localStorage.getItem("lURL");
        if (lastURL) {
          localStorage.removeItem("lURL");

          Object.keys(localStorage).forEach((key) => {
            // console.log("sessionStorage[key]", sessionStorage[key])
            if (sessionStorage[key] === undefined) {
              sessionStorage[key] = localStorage.getItem(key);
            }
            localStorage.removeItem(key);
          });
          this.router.navigate([lastURL]);
        } else {
          this.router.navigate(['/home']);
        }
        // this.goToFirstScreen();
      }
    });
  }
  /**
  * Redirect to execute module
  */
  login() {
    this.oauthService.initCodeFlow();
  }

  logOut() {
    localStorage.clear();
    sessionStorage.clear();
    //this.oauthService.logOut();
    this.router.navigate(['']);
  }

  closeShownModalAndLogout() {
    // this.shownModal.close();
    this.logOut();
  }

  IDPLoginAndStartSession() {
    // this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.loadDiscoveryDocumentAndTryLogin().catch(err => {
      console.error(err);
    }).then(() => {
      console.log("validation", this.oauthService.hasValidAccessToken())
      if (!this.oauthService.hasValidAccessToken()) {
        console.log("validation login")
        this.login();  // Code flow
      } else {
        this.checkFirstAccces();
      }
    });
  }


  matomoPageTrack(): void {
    // debugger;
    const claims = this.oauthService.getIdentityClaims();
    const ipm = `${claims['firstname']} ${claims['lastname']}`;
    // console.log("${claims['uid']} ${claims['firstname']} ${claims['lastname']}", `${claims['uid']} ${claims['firstname']} ${claims['lastname']}`)
    this.matomoTracker.setUserId(ipm);
    // this.matomoTracker.setCustomDimension(1, `${claims['firstname']} ${claims['lastname']}.`);
    this.matomoTracker.trackEvent("Login", "Login", claims['login']);
    // this.matomoInjector.init('https://piwik.renault-digital.com/', 2342);
    if ((window.location.hostname.indexOf('pil.app.renault.com') !== -1)
      || (window.location.hostname.indexOf('pil-app.gke2.ope.gcp.renault.com') !== -1)) {
      this.matomoInjector.init('https://matomo.dt.renault.com/', environment.idp.prod.ope.piwikID);
    } else if (window.location.hostname.indexOf('pil-app.gke2.int.gcp.renault.com') !== -1) {
      this.matomoInjector.init('https://matomo.dt.renault.com/', environment.idp.prod.re7.piwikID);
    } else if (window.location.hostname.indexOf('pil-app.gke2.dev.gcp.renault.com') !== -1) {
      this.matomoInjector.init('https://matomo.dt.renault.com/', environment.idp.prod.dev.piwikID);
    } else {
      this.matomoInjector.init('https://matomo.dt.renault.com/', environment.idp.prod.dev.piwikID);
    }
    sessionStorage.setItem('isMatomo', "1");
    const queryParam = { "userIpn": claims['uid'], "clientVersion": pkg.version };
    console.log("test1", pkg.version);
    this.apiService.createuserlogin(queryParam).subscribe(res => {
      console.log("test", res)
    },
      error => {
        console.log("error", error.message);
      });
    // this.matomoTracker.setCustomDimension(2, `${claims['uid']} ${claims['firstname']} ${claims['lastname']}`);
  }

}

