import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util/util.service';
import { AlertConfirmationComponent } from '../../alert-confirmation/alert-confirmation.component';

@Component({
  selector: 'app-user-message-sendinstantly',
  templateUrl: './user-message-sendinstantly.component.html',
  styleUrls: ['./user-message-sendinstantly.component.scss']
})
export class UserMessageSendinstantlyComponent implements OnInit {
  blnQuickLink: boolean;
  txtMsg: string;


  constructor(public dialogRef: MatDialogRef<UserMessageSendinstantlyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, readonly router: Router,  readonly utilService: UtilService,
    public dialog: MatDialog) { }

  ngOnInit() {
  } 
  
alertPopup(msg, isAlert) {
  const dialogRef = this.dialog.open(AlertConfirmationComponent, {
    disableClose: true,
    width: '550px',
    data: { 'display': msg, isAlert: isAlert }
  });
}

  onNoClick(): void {
    this.dialogRef.close();
  }
  onClickValid(){
    if(this.txtMsg.length > 248){
      this.alertPopup(this.utilService.getTranslateName("USER_MESSAGE_SEND_INSTANTLY.MESSAGE_POPUP"), true);
    return;
    }
    if(this.txtMsg.toString().trim()===""){
      this.alertPopup(this.utilService.getTranslateName("USER_MESSAGE_SEND_INSTANTLY.EMPTY_MESSAGE"), true);
      this.txtMsg="";
      return; 
    }
    if(this.txtMsg.length < 248){
      this.alertPopup(this.utilService.getTranslateName("USER_MESSAGE_SEND_INSTANTLY.IMPORTANT_MESSAGE"), false);
    return;
    }
  }

}
