import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { ToastyService } from 'ng2-toasty';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private oAuthService: OAuthService, private router: Router, readonly toastyService: ToastyService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // if (this.oauthService.hasValidIdToken()) {
    //   return true;
    // }

    // this.router.navigate(['']);
    // return false;
    let returnFlag:boolean;
    const identity = this.oAuthService.getIdentityClaims();

    // identity is available
    if (identity) {
      // get the current time and check if identity is expired
      const now = new Date().getTime();
      const expired = now > identity['exp'] * 1000;

      // user is not expired
      if (!expired) {
        // console.log('not expired', expired, location.pathname, identity['exp'] * 1000, identity);
        returnFlag= true;
      }

      // console.log('expired', expired, location.pathname, identity['exp'] * 1000, identity);
    } else {
      // this.toastyService.warning('Unauthorized user');
      this.router.navigate(['']);
    }


    returnFlag = true;

    return returnFlag;
  }
}
