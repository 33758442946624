import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { BackendApiService } from '../../../../services/backend-api/backend-api.service';
import { UtilService } from '../../../../services/util/util.service';

@Component({
  selector: 'app-character-flag-list',
  templateUrl: './character-flag-list.component.html',
  styleUrls: ['./character-flag-list.component.scss']
})

export class CharacterFlagListComponent implements OnChanges {
  @Input() selectedListItem: string;
  @Input() editValue: string[] = [];
  @Output() setCharacterFlagData = new EventEmitter();
  characterFlagValue;
  isSubmitted: boolean = false;
  characterFlagList = [
    { 'name': 'TRUE', value: '1' },
    { 'name': 'FALSE', value: '0' }];

  ngOnChanges() {
    if (this.editValue && this.editValue.length > 0) {
      this.characterFlagValue = this.editValue[1];
    }
  }

  submit() {
    if (!this.characterFlagList) {
      this.isSubmitted = true;
    }
    let value = {
      'value': this.characterFlagValue,
      displayGroupingForm: false
    }
    this.setCharacterFlagData.emit(value);
  }

  cancel() {
    let value = {
      value: '',
      displayGroupingForm: false
    };
    this.setCharacterFlagData.emit(value);
  }
}