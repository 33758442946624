import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './services/auth/auth.guard.service';
import { AuthentificationComponent } from './core/authentification/authentification.component';
import { UnauthorizedAccessComponent } from './core/authentification/unauthorized-access.component';
import { HomeComponent } from './component/home/home.component';
import { ChoiceTableComponent } from './component/table/choice-table/choice-table.component'
import { QueryMainScreenComponent } from './component/query/main-screen/main-screen.component';
import { LoadSpinnerComponent } from './load-spinner/load-spinner.component';
import { CreatePrivateGroupComponent } from './component/query/grouping/create-private-group/create-private-group.component';
import { LabelManagementComponent } from './component/label-management/label-management.component';
import { UserMessageComponent } from './component/user-message/user-message.component';
import { GroupFormattingComponent } from './component/group-formatting/group-formatting.component';
import { ListConnectionComponent } from './list-connection/list-connection.component';
import { RealignmentMainComponent } from './component/realignment-main/realignment-main.component';

const routes: Routes = [
  {
    path: '',
    component: AuthentificationComponent
  },
  { path: 'unauthorized', component: UnauthorizedAccessComponent },

  {
    path: "home", component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: "user-message", component: UserMessageComponent, canActivate: [AuthGuard] },
      { path: "list-connection", component: ListConnectionComponent, canActivate: [AuthGuard] },
      { path: "loader", component: LoadSpinnerComponent, canActivate: [AuthGuard] },
      { path: "table", component: ChoiceTableComponent, canActivate: [AuthGuard] },
      { path: "query", component: QueryMainScreenComponent, canActivate: [AuthGuard] },
      { path: "vue", component: CreatePrivateGroupComponent, canActivate: [AuthGuard] },
      { path: "open-request", component: CreatePrivateGroupComponent, canActivate: [AuthGuard] },
      { path: "import-request", component: CreatePrivateGroupComponent, canActivate: [AuthGuard] },
      { path: "extraction-tool", component: CreatePrivateGroupComponent, canActivate: [AuthGuard] },
      { path: "champs", component: CreatePrivateGroupComponent, canActivate: [AuthGuard] },
      { path: "label-management", component: LabelManagementComponent, canActivate: [AuthGuard] },
      { path: "group-formatting", component: GroupFormattingComponent, canActivate: [AuthGuard] },
      { path: "realignment", component: RealignmentMainComponent, canActivate: [AuthGuard] },
    ]
  }
  // { path: '', redirectTo: "/home", pathMatch: "full" } , canActivate: [AuthGuard]
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
