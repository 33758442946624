import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import moment from 'moment';
import { RequestService } from '../request/request.service';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  public $favoriteConnecton$: BehaviorSubject<string>;
  public $showIcon$: BehaviorSubject<boolean>;
  helper = new JwtHelperService();
  $showQuickLink$: BehaviorSubject<boolean>;

  isExpired;

  constructor(readonly requestService: RequestService, readonly translateService: TranslateService, private oauthService: OAuthService) {
    this.getSelectedLang();
    this.$favoriteConnecton$ = new BehaviorSubject('');
    this.$showIcon$ = new BehaviorSubject(true);
    this.$showQuickLink$ = new BehaviorSubject(false);
  }

  getUserProfile() {
    // const claims = sessionStorage.getItem('claims');
    // // console.log('claims', JSON.parse(claims))
    // return JSON.parse(claims);
    // console.log('this.helper.decodeToken(sessionStorage.getItem', this.helper.decodeToken(sessionStorage.getItem('access_token')));
    return this.helper.decodeToken(sessionStorage.getItem('access_token'));
    // this.$claimsDetails$.subscribe(a => {
    //   if (a !== '') {
    //     console.log('aaaaaaaaaa', JSON.parse(a));
    //     return JSON.parse(a);
    //   } else {
    //     // const claims = this.oauthService.getIdentityClaims();
    //     return this.oauthService.getIdentityClaims();
    //   }
    // })
    // return this.oauthService.getIdentityClaims();
  }

  getSelectedLang() {
    const selectedLang = this.requestService.getSelectedLang();
    return selectedLang;
  }

  getTranslateName(label) {
    let transName;
    this.translateService.get(label).subscribe(a => {
      // console.log('ttttttttttttttt', a);
      transName = a;
    })
    return transName;
  }

  setExpired(val) {
    this.isExpired = val;
  }

  getExpired() {
    console.log("expired", this.isExpired);
    return this.isExpired;
  }

  sortLangArray(lang) {
    return function (a, b) {
      if (a[lang] && b[lang]) {
        if (a[lang].match(/\w/) && b[lang].match(/\w/)) {
          return a[lang].toLowerCase().localeCompare(b[lang].toLowerCase());
        } else {
          return 0;
        }
      }
    }
  }


  sortArray(a, b) {
    if (a.match(/\w/) && b.match(/\w/)) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    } else {
      return 0;
    }
  }

  sortArrayCriteriaListGCOName(a, b) {
    if (a.GCOName.match(/\w/) && b.GCOName.match(/\w/)) {
      return a.GCOName.toLowerCase().localeCompare(b.GCOName.toLowerCase());
    } else {
      return 0;
    }
  }

  sortArrayCriteriaListGCONameDesc(a, b) {
    if (b.GCOName.match(/\w/) && a.GCOName.match(/\w/)) {
      return b.GCOName.toLowerCase().localeCompare(a.GCOName.toLowerCase());
    } else {
      return 0;
    }
  }

  sortArrayCriteriaListLongFR(a, b) {
    if (a.longFR.match(/\w/) && b.longFR.match(/\w/)) {
      return a.longFR.toLowerCase().localeCompare(b.longFR.toLowerCase());
    } else {
      return 0;
    }
  }

  sortArrayCriteriaListLongFRDesc(a, b) {
    if (b.longFR.match(/\w/) && a.longFR.match(/\w/)) {
      return b.longFR.toLowerCase().localeCompare(a.longFR.toLowerCase());
    } else {
      return 0;
    }
  }

  sortArrayCriteriaListLongEN(a, b) {
    if (a.longEN.match(/\w/) && b.longEN.match(/\w/)) {
      return a.longEN.toLowerCase().localeCompare(b.longEN.toLowerCase());
    } else {
      return 0;
    }
  }

  sortArrayCriteriaListLongENDesc(a, b) {
    if (b.longEN.match(/\w/) && a.longEN.match(/\w/)) {
      return b.longEN.toLowerCase().localeCompare(a.longEN.toLowerCase());
    } else {
      return 0;
    }
  }

  sortArrayCriteriaListShortFR(a, b) {
    if (a.shortFR.match(/\w/) && b.shortFR.match(/\w/)) {
      return a.shortFR.toLowerCase().localeCompare(b.shortFR.toLowerCase());
    } else {
      return 0;
    }
  }

  sortArrayCriteriaListShortFRDesc(a, b) {
    if (b.shortFR.match(/\w/) && a.shortFR.match(/\w/)) {
      return b.shortFR.toLowerCase().localeCompare(a.shortFR.toLowerCase());
    } else {
      return 0;
    }
  }

  sortArrayCriteriaListShortEN(a, b) {
    if (a.shortEN && b.shortEN) {
      if (a.shortEN.match(/\w/) && b.shortEN.match(/\w/)) {
        return a.shortEN.toLowerCase().localeCompare(b.shortEN.toLowerCase());
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  sortArrayCriteriaListShortENDesc(a, b) {
    if (a.shortEN && b.shortEN) {
      if (b.shortEN.match(/\w/) && a.shortEN.match(/\w/)) {
        return b.shortEN.toLowerCase().localeCompare(a.shortEN.toLowerCase());
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }


  sortArrayCriteriaList(a, b) {
    if (a.label.match(/\w/) && b.label.match(/\w/)) {
      return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
    } else {
      return 0;
    }
  }


  sortArrayValueList(a, b) {
    if (a.table.match(/\w/) && b.table.match(/\w/)) {
      return a.table.toLowerCase().localeCompare(b.table.toLowerCase());
    } else {
      return 0;
    }
  }

  MySort(alphabet) {
    return function (a, b) {
      var index_a = alphabet.indexOf(a[0]),
        index_b = alphabet.indexOf(b[0]);

      if (index_a === index_b) {
        // same first character, sort regular
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        }
        return 0;
      } else {
        return index_a - index_b;
      }
    }
  }

  sortArraySpecial(a, b) {
    let digitRegex = /^\d/;
    let alphabetRegex = /^[a-zA-Z]/;
    let symbolRegex = /^[^\w\s]/;

    let scoreA = +symbolRegex.test(a) * 1 || +digitRegex.test(a) * 10 || +alphabetRegex.test(a) * 100;
    let scoreB = +symbolRegex.test(b) * 1 || +digitRegex.test(b) * 10 || +alphabetRegex.test(b) * 100;

    if (scoreA !== scoreB) {
      return scoreA - scoreB;
    }

    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    }

    return 0;
  }

  convertUTCDateToLocalDate(date) {
    const utcTime = date;
    const utcText = moment(utcTime).format("L hh:mm:ss A");
    return new Date(utcText);
  }

  addOpenedWindowName(pathName) {
    this.removeQueryWindowNameInList(pathName);
    let openedWindowNameList = this.getOpenedWindowNameList();
    if (openedWindowNameList[openedWindowNameList.length - 1] !== pathName) {
      openedWindowNameList.push(pathName);
      sessionStorage.setItem("openedWindow", JSON.stringify(openedWindowNameList));
    }
  }

  removeQueryWindowNameInList(pathName) {
    let openedWindow = sessionStorage.getItem("openedWindow") ? JSON.parse(sessionStorage.getItem("openedWindow")) : [];
    if (openedWindow.length > 0) {
      if (openedWindow.indexOf(pathName) >= 0) {
        openedWindow.splice(openedWindow.indexOf(pathName), 1);
      }
      sessionStorage.setItem('openedWindow', JSON.stringify(openedWindow));
    }
  }

  getOpenedWindowNameList() {
    const nList = sessionStorage.getItem("openedWindow");
    if (nList) {
      if (nList.length > 0) {
        return JSON.parse(nList);
      } else {
        return [];
      }
    }
    return [];
  }

  removeOpenedWindowName(pathName) {
    let openedWindowNameList = this.getOpenedWindowNameList();
    if (openedWindowNameList.length > 0) {
      if (openedWindowNameList[openedWindowNameList.length - 1] === pathName) {
        openedWindowNameList.splice(openedWindowNameList.length - 1, 1);
      }
      sessionStorage.setItem("openedWindow", JSON.stringify(openedWindowNameList));
    }
  }

  setTrace(msg) {
    let data = sessionStorage.getItem('trace');
    if (typeof (msg) === 'object') {
      data = data + '\r\n' + JSON.stringify(msg, null, 4);
    } else {
      if (data === "") {
        data = data + msg;
      } else {
        data = data + '\r\n' + msg;
      }
    }
    sessionStorage.setItem('trace', data)
  }
}
