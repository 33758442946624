import { Component, Inject, AfterViewInit, OnChanges } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BackendApiService } from '../../../services/backend-api/backend-api.service';
import { AlertConfirmationComponent } from '../../alert-confirmation/alert-confirmation.component';
import { UtilService } from '../../../services/util/util.service';
import { ToastyService } from 'ng2-toasty';

@Component({
    selector: 'app-save-query-name',
    templateUrl: './save-query-name.component.html',
    styleUrls: ['./save-query-name.component.scss'],
  })
  export class SaveQueryNameComponent  {
    dataObj;
    queryName: string;
    queryNameList: Array<string>;
    isPublic: boolean = false;
    constructor(public dialogRef: MatDialogRef<SaveQueryNameComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    readonly apiService: BackendApiService, public dialog: MatDialog, readonly utilService: UtilService,
    readonly toastyService: ToastyService) {
        this.dataObj = data.value;
        this.getNameList();
        if (this.data.type === 'private') {
          this.queryName = this.dataObj;
        }
      }
    
      getNameList() {
        let ipn;
        if (!this.isPublic) {
          ipn = this.dataObj.ipn;
        } else {
          ipn = 'system';
        }
      let queryParam = {"input" : `REQU@${ipn}@${this.dataObj.table}@@`,  "region": this.dataObj.region };
      this.apiService.getSaveQueryName(queryParam).subscribe(res => {
        if (res.noObjects > 0) {
          let queryList = {"input" : `REQU@${ipn}@${this.dataObj.table}@@0@${res.noObjects}@`, "region": this.dataObj.region };
          this.apiService.getSaveQueryNameList(queryList).subscribe(elem => {
            this.queryNameList = elem.lists;
          }, error => {
            console.log("error", error.message);
          });
        }
      }, error => {
        console.log("error", error.message);
      });
    }

    changeNameList() {
        this.getNameList();
    }

    submit() {
      let queryList = [];
      if (this.queryNameList && this.queryNameList.length > 0) {
        this.queryNameList.forEach(el => {
          queryList.push(el.toLowerCase());
        });
        if (queryList.includes(this.queryName.toLowerCase())) {
          this.saveConfirmation();
        } else {
          this.dialogRef.close({name: this.queryName, isPublic : this.isPublic});
        }
      } else {
        this.dialogRef.close({name: this.queryName, isPublic : this.isPublic});
      }
    }

    saveConfirmation(): void {
        const dialogRef = this.dialog.open(AlertConfirmationComponent, {
          disableClose: true,
          width: '550px',
          data: { 'display': this.utilService.getTranslateName(`NEW_QUERY.QUERY_NAME_OVERWRITE`), 'isText': false }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result === true) {
            this.dialogRef.close({name: this.queryName, isPublic : this.isPublic});
          }
        })
    }

    cancel() {
      this.queryName = '';
      this.dialogRef.close({name: this.queryName});
    }
  }