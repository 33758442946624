import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BackendApiService } from 'src/app/services/backend-api/backend-api.service';
import { UtilService } from 'src/app/services/util/util.service';
import { RequestService } from '../../services/request/request.service';
import { MatSelectionList, MatSelectionListChange, MatListOption } from '@angular/material';

@Component({
  selector: 'app-group-formatting-condition',
  templateUrl: './group-formatting-condition.component.html',
  styleUrls: ['./group-formatting-condition.component.scss']
})
export class GroupFormattingConditionComponent implements OnInit {
  @ViewChild('characterListId', { static: false }) characterId: MatSelectionList;
  bln: boolean;
  textVal: string = '';
  selectedCategory;
  selectedCriterias;
  selectedItemValue;
  selectedCharacterList;
  gSource: any = [];
  selectedIndexValue: any;
  groupingEditValue: any;
  lang = 'fr';
  splitChar = "§";

  isModify = false;

  displayCList = [];
  categoryList: any;
  searchText;
  selectedItem: any;
  isShowCondition: boolean;

  selectList = [];
  selectedListIndex;

  constructor(public dialogRef: MatDialogRef<GroupFormattingConditionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, readonly requestService: RequestService, readonly utilService: UtilService,
    readonly apiService: BackendApiService) {
    this.lang = requestService.getSelectedLang();
  }

  ngOnInit() {
    this.selectedCategory = this.utilService.getTranslateName(`COMMON.ALL`);
    this.gSource = [];
    this.isModify = false;
    if (this.data && this.data.hasOwnProperty('gPreSource')) {
      // this.gSource.push(this.data.gPreSource);
      this.data.tableValues.selectedTable = this.data.gPreSource.table;
      const splitValue = this.data.gPreSource.GCOName.split(",");
      for (let index = 0; index < splitValue.length; index++) {
        const element = splitValue[index];
        const displayLabelValue = this.data.gPreSource.value.split(",")[index].toString().split("[");
        const displayLabel = displayLabelValue[0].substr(0, displayLabelValue[0].indexOf(" = "));
        const displayValue = displayLabelValue[1].substr(0, displayLabelValue[1].length - 1);
        this.gSource.push({
          "GCOName": this.data.gPreSource.GCOName.split(",")[index], "index": this.data.gPreSource.index.toString().split(",")[index],
          "size": this.data.gPreSource.size.toString().split(",")[index], "table": this.data.gPreSource.table, "ts": this.data.gPreSource.ts,
          "type": this.data.gPreSource.type.toString().split(",")[index], "value": this.data.gPreSource.value.split(",")[index],
          "displayLabel": displayLabel, "displayValue": displayValue
        })
        this.isModify = true;
      }
    }
    // console.log("this.gSource", this.gSource)
    if (this.data.displayCriteriaList) {
      for (const item of this.data.displayCriteriaList) {
        this.displayCList.push(item);
      }
      this.displayCList = this.displayCList.sort(this.utilService.sortArrayCriteriaList);
    }
  }

  searchFilter() {
    // console.log("this.searchText", this.searchText);
    this.displayCList = this.data.displayCriteriaList.filter(a => a.label.toUpperCase().includes(this.searchText.toUpperCase()));
    this.displayCList = this.displayCList.sort(this.utilService.sortArrayCriteriaList);
  }

  getValue() {
    if (!this.data.isText) {
      return true;
    } else {
      return this.textVal.trim();
    }
  }

  clickCriteriaList(item, index) {
    if (!this.selectedItemValue) {
      this.groupingEditValue = undefined;
      this.selectedCharacterList = [];
      this.selectedIndexValue = -1;
      this.selectedItemValue = undefined;
      this.selectedItem = undefined
      this.isShowCondition = false;

      if (item.GCOName === "I" || item.GCOName === "A_Chassis" || item.GCOName === "A_Ident") {
        this.selectedItemValue = undefined;
      } else {
        this.selectedItemValue = item.label;
        this.selectedItem = item;
        // this.getCharacterData(item);
      }
    }
  }

  moveRight() {
    if (this.selectedItemValue) {
      this.groupingEditValue = undefined;
      this.selectedCharacterList = [];
      this.selectedIndexValue = -1;

      if (this.selectedItem.GCOName === "I" || this.selectedItem.GCOName === "A_Chassis" || this.selectedItem.GCOName === "A_Ident") {
        this.selectedItemValue = undefined;
      } else {
        this.selectedItemValue = this.selectedItem.label;
        this.isShowCondition = true;
        this.getCharacterData(this.selectedItem);
      }
    }
  }

  getCharacterData(criteriaFilterVal) {
    this.selectedCharacterList = {
      'longLabel': criteriaFilterVal.longLabel,
      'size': criteriaFilterVal.size,
      'GCOName': criteriaFilterVal.GCOName,
      'type': criteriaFilterVal.type,
      'selectedTable': this.data.tableValues.selectedTable,
      'ipn': this.data.tableValues.ipn,
      'region': this.data.tableValues.selectedRegion,
      'selectedGroupItemValue': this.selectedItemValue
    };
    // console.log("criteriaFilterVal", criteriaFilterVal, this.selectedCharacterList)
  }

  onGroupDateOk($event) {
    if ($event.value !== '') {
      if (this.selectedIndexValue > -1) {
        this.gSource[this.selectedIndexValue].displayLabel = this.selectedCharacterList.selectedGroupItemValue;
        if ($event.indVal === 2) {
          this.gSource[this.selectedIndexValue].value = `${this.selectedCharacterList.selectedGroupItemValue} = [${$event.value.join('/')}]`;
          this.gSource[this.selectedIndexValue].displayValue = `${$event.value.join('/')}`;
        } else {
          this.gSource[this.selectedIndexValue].value = `${this.selectedCharacterList.selectedGroupItemValue} = [${$event.format} ${$event.value}]`;
          this.gSource[this.selectedIndexValue].displayValue = `${$event.format} ${$event.value}`;
        }
        this.gSource[this.selectedIndexValue].index = $event.indVal;
        this.selectedIndexValue = -1;
        this.selectedItemValue = undefined;
        this.selectedCharacterList = [];
      } else {

        this.gSource.push({
          "table": this.selectedCharacterList.selectedTable, "ts": "T", "GCOName": this.selectedCharacterList.GCOName, "type": this.selectedCharacterList.type,
          "size": this.selectedCharacterList.size, "index": $event.indVal, "displayValue": "", "displayLabel": this.selectedCharacterList.selectedGroupItemValue
        });
        if ($event.indVal === 6) {
          this.gSource[this.gSource.length - 1].value = `${this.selectedCharacterList.selectedGroupItemValue} = [${$event.format}${$event.value}]`;
          this.gSource[this.gSource.length - 1].displayValue = `${$event.format} ${$event.value}`;
        } else if ($event.indVal === 2) {
          this.gSource[this.gSource.length - 1].value = `${this.selectedCharacterList.selectedGroupItemValue} = [${$event.value.join('/')}]`;
          this.gSource[this.gSource.length - 1].displayValue = `${$event.value.join('/')}`;
        } else {
          this.gSource[this.gSource.length - 1].value = `${this.selectedCharacterList.selectedGroupItemValue} = [${$event.format} ${$event.value}]`;
          this.gSource[this.gSource.length - 1].displayValue = `${$event.format} ${$event.value}`;
        }
        this.selectedItemValue = undefined;
      }
      // console.log("event", $event, this.gSource);
    }
    this.isShowCondition = false;
    this.selectedIndexValue = -1;
    this.selectedItemValue = undefined;
    this.selectedCharacterList = [];
  }

  onNoClick(): void {
    this.selectedItemValue = undefined;
    this.dialogRef.close();
  }

  editValue(item, indexValue) {
    const filV = this.data.displayCriteriaList.filter(v => v.GCOName === item.GCOName);
    if (filV.length > 0) {

      this.selectedItemValue = `${filV[0].longLabel.split('§').length > 1 ? filV[0].longLabel.split('§')[this.getLangIndex(this.lang)] : filV[0].longLabel}`;
      this.selectedCharacterList = {
        'longLabel': filV[0].longLabel,
        'size': filV[0].size,
        'GCOName': filV[0].GCOName,
        'type': filV[0].type,
        'selectedTable': this.data.tableValues.selectedTable,
        'ipn': this.data.tableValues.ipn,
        'region': this.data.tableValues.selectedRegion,
        'selectedGroupItemValue': this.selectedItemValue
      };
      // console.log("criteriaFilterVal", filV[0], this.selectedCharacterList)

      this.groupingEditValue = item;
      this.isShowCondition = true;
      this.selectedIndexValue = indexValue;
      // console.log("item", item, indexValue);
    }
  }

  deleteValue(indVal) {
    if (typeof (indVal) !== "undefined") {
      this.gSource.splice(indVal, 1);
      // console.log("this.gSourceDisplay", this.gSource);
    }
  }

  getLangIndex(lang) {
    if (lang === 'en') {
      return 1;
    } else {
      return 0;
    }
  }



  onCategoryChange(val, type) {
    if (val === '') {
      this.selectedCategory = this.utilService.getTranslateName(`COMMON.ALL`);
    } else {
      this.selectedCategory = val;
    }
    const privateCategory = this.data.privateFilterCategory.filter(xy => xy === val);
    if (privateCategory.length > 0) {
      this.getSearchCategory(this.data.tableValues.ipn);
    } else {
      this.getSearchCategory('system');
    }
  }

  getFavoriteRegion() {
    if (this.data.tableValues.selectedRegion === "Europe") {
      return "PIL";
    } else {
      return "PIM";
    }
  }

  getSearchCategory(ipnValue) {
    this.displayCList = [];
    this.categoryList = [];
    if (this.selectedCategory === this.utilService.getTranslateName(`COMMON.ALL`)) {
      for (const item of this.data.displayCriteriaList) {
        this.displayCList.push(item);
      }
      this.displayCList = this.displayCList.sort(this.utilService.sortArrayCriteriaList);
      // this.displayCList = this.displayCList.sort((a, b) => (a.GCOName < b.GCOName) ? -1 : 1);
    } else {
      const queryParam = { "input": `FILTRE@${ipnValue}@${this.data.tableValues.selectedTable}@${this.selectedCategory}@`, "region": this.getFavoriteRegion() };
      this.apiService.getObjectGet(queryParam).subscribe(res => {
        // console.log("res.data", res.data)
        const dataRes = res.data.split('@');
        for (let i = 0; i < dataRes.length; i = i + 4) {
          if (i > 0) {
            this.categoryList.push(dataRes[i - 1]);
          }
        }
        for (const iterator of this.categoryList) {
          const filterVal = this.data.displayCriteriaList.filter(a => a.GCOName.toUpperCase() === iterator.substring(0, iterator.length - 2).toUpperCase());
          for (const item of filterVal) {
            this.displayCList.push(item);
          }
        }
        // this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArray);
        this.displayCList = this.displayCList.sort(this.utilService.sortArrayCriteriaList);
        this.selectedItemValue = undefined;
        this.selectedIndexValue = -1;
      },
        error => {
          console.log("error", error.message);
        });
    }
  }

  clickList(index, event) {
    // this.selectedListIndex = undefined;
    // if (this.selectList.filter(a => a === index).length === 0) {
    //   this.selectList.push(index);
    //   this.selectedListIndex = index;
    // } else {
    //   this.selectList.splice(index, 1)
    // }
    // this.selectList.sort()

    this.selectedListIndex = index;

    if (this.characterId !== undefined) {
      this.characterId.selectionChange.subscribe((s: MatSelectionListChange) => {
        if (s.source._value.length > 0) {
          this.characterId.deselectAll();
          s.option.selected = true;
          // this.selectedListIndex = index;
        } else {
          this.characterId.deselectAll();
        }
      });
    }
  }

  removeSelectedItem(index) {
    var selectList = document.getElementById("characterListId");
    var selectOptions = selectList.getElementsByTagName('mat-list-option');
    for (var i = 0; i < selectOptions.length; i++) {
      if (i !== index && selectOptions[i].getAttribute('aria-selected') === 'true') {
        let div = selectOptions[i].getAttribute('id');
        document.getElementById(div).click()
      } else if (i === index && selectOptions[i].getAttribute('aria-selected') === 'false') {
        let div = selectOptions[i].getAttribute('id');
        document.getElementById(div).click()
      }
    }
  }

  dblClickCharacterList(index, item) {
    this.selectedListIndex = index;
    this.editValue(item, index);
    // console.log("selectedListIndex", this.selectedListIndex);
    this.removeSelectedItem(index);
    // if (this.characterId !== undefined) {
    //   this.characterId.selectionChange.subscribe((s: MatSelectionListChange) => {
    //     if (s.source._value.length > 0) {
    //       this.characterId.deselectAll();
    //       s.option.selected = true;
    //       this.selectedListIndex = index;
    //     } else {
    //       this.characterId.deselectAll();
    //     }
    //   });
    // }
  }

}
