import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo';
import { MatomoInjector } from 'ngx-matomo';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment.prod';
import { UtilService } from '../../services/util/util.service';
import * as pkg from '../../../../package.json';
import { interval } from 'rxjs'
// import {
//   trigger,
//   style,
//   animate,
//   transition,
// } from '@angular/animations';

import { DomSanitizer } from '@angular/platform-browser'
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertConfirmationComponent } from '../alert-confirmation/alert-confirmation.component';
import { BackendApiService } from 'src/app/services/backend-api/backend-api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ]
  // animations: [
  //   trigger('fadeSlideInOut', [
  //     transition(':enter', [
  //       style({
  //         opacity: 0
  //       }),
  //       animate('1s ease-in', style({
  //         opacity: 1
  //       }))
  //     ]),
  //     transition(':leave', [
  //       style({
  //         opacity: 1
  //       }),
  //       animate('1s ease-out', style({
  //         opacity: 0
  //       }))
  //     ]),
  //   ]),
  // ]
})
export class HomeComponent implements OnInit {
  urlToImage = '../../../assets/images/slideshow/3.jpg'
  index: number = 1;
  numImages: number = 4;
  imagesLoaded: number = 0;
  showIcons = true;
  chatBotDialog = true;
  background;

  constructor(private oauthService: OAuthService,private translateService: TranslateService, private matomoTracker: MatomoTracker, private matomoInjector: MatomoInjector, private router: Router,
    readonly apiService: BackendApiService, readonly utilService: UtilService, private sanitization: DomSanitizer, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) {
    const isMatomo = sessionStorage.getItem('isMatomo');
    // if (isMatomo === null) {
    sessionStorage.setItem('isMatomo', "0");
    const claims = this.oauthService.getIdentityClaims();
    const queryParam = { "userIpn": claims['login'], "clientVersion": pkg.version };
    console.log("test1", pkg.version);
    this.apiService.createuserlogin(queryParam).subscribe(res => {
      console.log("test", res)
    },
      error => {
        console.log("error", error.message);
      });
    // }
    this.utilService.$favoriteConnecton$.next("");
    this.background =
      this.sanitization.bypassSecurityTrustStyle(`url(../../../assets/images/slideshow/1.jpg) no-repeat`);
    this.utilService.$showIcon$.next(true);
  }

  ngOnInit() {
    // const claims = this.oauthService.getIdentityClaims();
    // this.matomoPageTrack(`${claims['firstname']} ${claims['lastname']}`, '');
    // this.background =
    //   this.sanitization.bypassSecurityTrustStyle(`url(../../../assets/images/slideshow/${this.index}.jpg) no-repeat`);
    // interval(30000).subscribe(() => {
    //   // this.index = (this.index + 1) % this.numImages;
    //   // this.background =
    //   //   this.sanitization.bypassSecurityTrustStyle(`url(../../../assets/images/slideshow/${this.index + 1}.jpg) no-repeat`);

    //   let expat = sessionStorage.getItem("expires_at");
    //   const startTS = sessionStorage.getItem("start_ts");
    //   if (expat !== startTS) {
    //     sessionStorage.setItem("start_ts", sessionStorage.getItem("expires_at"));
    //     alert("Session expired. Page auto refresh");
    //     window.location.reload();
    //   }

    // let dt = new Date();
    // dt.setMinutes(dt.getMinutes() - 5);
    // console.log("expat", Number(startTS), dt.valueOf());
    // if (dt.valueOf() > Number(startTS)) {
    //   this.oauthService.silentRefresh();
    //   alert("Session expired. Page auto refresh");
    //   let dtNew = new Date();
    //   sessionStorage.setItem("start_ts", dtNew.valueOf().toString());
    //   window.location.reload();
    // }

    // if (expat !== null) {
    //   let dt = new Date();
    //   dt.setMinutes(dt.getMinutes() + 10);
    //   console.log("expat", Number(expat), dt.valueOf());
    //   if (dt.valueOf() > Number(expat)) {
    //     this.oauthService.silentRefresh();
    //     alert("Session expired. Page auto refresh");
    //     window.location.reload();
    //   }
    // }

    // })

    this.utilService.$showIcon$.subscribe(z => {
      this.showIcons = z;
    })

    // setInterval(() => {
    //   const lastURL = localStorage.getItem("lURL");
    //   if (!lastURL) {
    //     const claims = this.oauthService.getIdentityClaims();
    //     // console.log("this.difference(new Date(claims['exp'] * 1000), new Date()) < 28", this.difference(new Date(claims['exp'] * 1000), new Date()) < 28, new Date(claims['exp'] * 1000), new Date())
    //     // if (this.difference(new Date(claims['exp'] * 1000), new Date()) < 28) {
    //     if (new Date() > new Date(claims['exp'] * 1000)) {
    //       // console.log("datediff", this.difference(new Date(claims['exp'] * 1000), new Date()));
    //       const store = window.sessionStorage;
    //       Object.keys(store).forEach((key) => {
    //         localStorage[key] = store.getItem(key);
    //       });
    //       sessionStorage.setItem("lURL", window.location.pathname);
    //       const dialogRef = this.dialog.open(AlertConfirmationComponent, {
    //         disableClose: true,
    //         width: '550px',
    //         data: { 'display': this.utilService.getTranslateName(`COMMON.sessionexp`), 'isAlert': true, 'isText': false }
    //       });

    //       dialogRef.afterClosed().subscribe(result => {
    //         // this.oauthService.logOut();

    //         this.router.navigate([""]);
    //         window.location.reload();
    //       })
    //     }
    //   }

    // }, 30000);
  }


  difference(date1, date2) {
    var utcThis = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate(), date1.getHours(), date1.getMinutes(), date1.getSeconds(), date1.getMilliseconds());
    var utcOther = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate(), date2.getHours(), date2.getMinutes(), date2.getSeconds(), date2.getMilliseconds());

    return Math.abs(utcThis - utcOther) / 60000;
  }
  /**
   * Progresses the carousel forward by 1 slide.
   */
  next() {
    if (this.index + 1 <= this.numImages) {
      this.index = this.index + 1;
      this.background =
        this.sanitization.bypassSecurityTrustStyle(`url(../../../assets/images/slideshow/${this.index}.jpg) no-repeat`);
    }
  }

  /**
   * Regresses the carousel backwards by 1 slide.
   */
  prev() {
    // if (this.currentSlide === 0) return;
    if (this.index > 1) {
      this.index = this.index - 1;
      this.background =
        this.sanitization.bypassSecurityTrustStyle(`url(../../../assets/images/slideshow/${this.index}.jpg) no-repeat`);
    }
  }

  // matomoPageTrack(ipm: string, role: string): void {
  //   // debugger;
  //   if (location.pathname === '/home' && sessionStorage.getItem('isMatomo') === '0') {
  //     const claims = this.oauthService.getIdentityClaims();
  //     // console.log("${claims['uid']} ${claims['firstname']} ${claims['lastname']}", `${claims['uid']} ${claims['firstname']} ${claims['lastname']}`)
  //     this.matomoTracker.setUserId(ipm);
  //     this.matomoTracker.setCustomDimension(1, `${claims['firstname']} ${claims['lastname']}`);
  //     // this.matomoInjector.init('https://piwik.renault-digital.com/', 2342);
  //     if ((window.location.hostname.indexOf('pil.app.renault.com') !== -1)
  //       || (window.location.hostname.indexOf('pil-app.gke2.ope.gcp.renault.com') !== -1)) {
  //       this.matomoInjector.init('https://matomo.dt.renault.com/', environment.idp.prod.ope.piwikID);
  //     } else if (window.location.hostname.indexOf('pil-app.gke2.int.gcp.renault.com') !== -1) {
  //       this.matomoInjector.init('https://matomo.dt.renault.com/', environment.idp.prod.re7.piwikID);
  //     } else if (window.location.hostname.indexOf('pil-app.gke2.dev.gcp.renault.com') !== -1) {
  //       this.matomoInjector.init('https://matomo.dt.renault.com/', environment.idp.prod.dev.piwikID);
  //     } else {
  //       this.matomoInjector.init('https://matomo.dt.renault.com/', environment.idp.prod.dev.piwikID);
  //     }
  //     sessionStorage.setItem('isMatomo', "1");
  //     const queryParam = { "userIpn": claims['uid'], "clientVersion": pkg.version };
  //     console.log("test1", pkg.version);
  //     this.apiService.createuserlogin(queryParam).subscribe(res => {
  //       console.log("test", res)
  //     },
  //       error => {
  //         console.log("error", error.message);
  //       });
  //     // this.matomoTracker.setCustomDimension(2, `${claims['uid']} ${claims['firstname']} ${claims['lastname']}`);

  //   }
  // }

  closeDialogBox(){
    this.chatBotDialog=false;
  }

}
