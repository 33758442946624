import { Component, OnInit, Input, Output, EventEmitter, OnChanges, AfterViewInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, FormsModule, NgControl, FormGroup } from '@angular/forms';
import { ToastyService } from 'ng2-toasty';
import { UtilService } from '../../../../services/util/util.service';

@Component({
  selector: 'app-date-grouping',
  templateUrl: './date-grouping.component.html',
  styleUrls: ['./date-grouping.component.scss']
})
export class DateGroupingComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() selectedListItem: string;
  @Input() blnGroupingEdit: boolean;
  @Input() groupingEditValue: string;
  @Output() groupDateRetrun = new EventEmitter<any>();
  @Output() blnCancel = new EventEmitter<boolean>();
  dateVal;
  fromDate;
  toDate;

  freeFormatValue: string;

  dateRange: Array<any>;

  groupingDateRange = [{ name: 'DCMY', value: 'jours' },
  { name: 'DWY', value: 'jour/prog./année' },
  { name: 'PY', value: 'programmes' },
  { name: 'IMY', value: 'mois industriels' },
  { name: 'CMY', value: 'mois calendaires' },
  { name: 'Y', value: 'année' },
  { name: 'FF', value: 'Free Format' },
  { name: 'FKD', value: 'flag' }];
  abbrevations = [ {name : 'ABBR_IY'},
  {name : 'ABBR_IM'},
  {name : 'ABBR_IW'},
  {name : 'ABBR_DW'},
  {name : 'ABBR_CY'},
  {name : 'ABBR_CM'},
  {name : 'ABBR_DM'},
  {name : 'ABBR_DY'}]

  populationDateRange = [{ name: 'CONNUE', value: 'connue', selected: 'false' },
  { name: 'INCONNUE', value: 'inconnue', selected: 'false' },
  { name: 'JOUR', value: 'jour' },
  { name: 'ASL', value: 'ASJ' },
  { name: 'PROGRAM', value: 'programme' },
  { name: 'INDUSTRIAL', value: 'mois insustriel' },
  { name: 'CALENDAR', value: 'mois calendaire' },
  { name: 'Y', value: 'année' }]

  form: FormGroup;
  selectOptIndex;
  dateTextVal;
  dateTextVal2;

  constructor(fb: FormBuilder, readonly toastyService: ToastyService, readonly utilService: UtilService) {
    this.form = fb.group({
      day: [''],
      week: [''],
      dayWeek: [''],
      year: [''],
      finDay: [''],
      finWeek: [''],
      finDayWeek: [''],
      finYear: ['']
    });
  }

  ngOnInit() { }

  ngAfterViewInit() { }

  ngOnChanges() {
    if (this.title !== 'POPULATION') {
      this.dateRange = this.groupingDateRange;
      let freeFormat: boolean = true;
      if (this.groupingEditValue) {
        for (const item of this.groupingDateRange) {
          if (this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${item.value}`) === this.groupingEditValue) {
            this.dateVal = item.value;
            freeFormat = false;
            break;
          }
        }
        if (freeFormat) {
          this.dateVal = 'Free Format';
          this.freeFormatValue = this.groupingEditValue;
        }
      }
    } else {
      this.dateRange = this.populationDateRange;
      let freeFormat: boolean = true;
      this.dateRange[0].selected = false;
      this.dateRange[1].selected = false;
      if (this.groupingEditValue) {
        const value = `${this.selectedListItem.split('[')[1].replace(']', '')}`;
        if (value) {
          this.selectedListItem = this.selectedListItem.substr(0, this.selectedListItem.indexOf('[') - 1);
        }
        if (this.groupingEditValue[0][0] === "=") {
          if (this.groupingEditValue[0][1] === "1") {
            this.dateVal = this.populationDateRange[0].value;
            this.dateRange[0].selected = true;
            this.selectOptIndex = 0;
          } else {
            this.dateVal = this.populationDateRange[1].value;
            this.dateRange[1].selected = true;
            this.selectOptIndex = 1;
          }
        } else {
          let startDate;
          let endDate;
          if (this.groupingEditValue.length > 2) {
            startDate = this.groupingEditValue[0].split('>=')[1];
            endDate = this.groupingEditValue[2].split('<=')[1];
          } else {
            if (this.groupingEditValue[0][0] === '>') {
              startDate = this.groupingEditValue[0].split('>=')[1];
            } else {
              endDate = this.groupingEditValue[0].split('<=')[1];
            }
          }
          let indexVal = 0;
          for (const item of this.populationDateRange) {
            if (this.editDateVal(item.name) === value) {
              this.dateTextVal = startDate;
              this.dateTextVal2 = endDate;
              this.dateVal = item.value;
              this.selectOptIndex = indexVal;
              this.selectOptIndexChange(undefined);
              freeFormat = false;
              break;
            }
            indexVal = indexVal + 1;
          }
          if (freeFormat) {
            this.dateVal = 'Free Format';
            this.freeFormatValue = this.groupingEditValue;
          }
        }
      }
    }
  }

  editDateVal(name) {
    let dateVal;
    if (name !== 'CONNUE' && name !== 'INCONNUE' && name !== 'Y') {
      dateVal = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${name}_SAVE`);
    } else {
      dateVal = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${name}`);
    }
    return dateVal;
  }

  statusOkButton() {
    if (this.selectOptIndex > 1 && !this.dateTextVal) {
      if (this.dateTextVal2) {
        return false;
      }
      return true;
    } else if (this.selectOptIndex > 1 && !this.dateTextVal2) {
      if (this.dateTextVal) {
        return false;
      }
      return true;
    }
    return false;
  }

  onCancel() {
    this.blnCancel.emit(true);
  }

  getGroupingDateChange(name) {
    for (let item of this.dateRange) {
      if (item.value === name) {
        return this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${item.value}`);
      }
    }
  }

  onOk() {
    if (this.title !== 'POPULATION') {
      if (this.dateVal === 'Free Format') {
        this.groupDateRetrun.emit(this.freeFormatValue);
      } else {
        this.groupDateRetrun.emit(this.getGroupingDateChange(this.dateVal));
      }
    } else {
      if (this.selectOptIndex > 1) {
        if (!this.dateValidation()) {
          return;
        } else {
          let value = {
            date1: this.dateTextVal,
            date2: this.dateTextVal2,
            format: this.dateVal,
            // value: `${startDateVal ? startDateVal : ''}${endDateVal ? endDateVal : ''}`
            value: this.convertDateVal(this.dateRange[this.selectOptIndex].name)
          };
          this.groupDateRetrun.emit(value);
        }
      } else {
        const connueVal = this.dateRange[this.selectOptIndex].value === 'connue' ? '1' : '0';
        let value = {
          date1: this.dateTextVal,
          date2: this.dateTextVal2,
          value: this.convertDateVal(this.dateRange[this.selectOptIndex].name),
          conneValue: connueVal
        };
        this.groupDateRetrun.emit(value);
      }
    }
    // console.log("this.dayForm.controls['day']", this.form.controls['day'].value, this.form.controls['finDay'].value);
  }

  convertDateVal(name) {
    let dateVal;
    if (name === 'ASL' || name === 'JOUR' || name === 'PROGRAM' || name === 'INDUSTRIAL' || name === 'CALENDAR') {
      dateVal = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${this.dateRange[this.selectOptIndex].name}_SAVE`);
    } else {
      dateVal = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${this.dateRange[this.selectOptIndex].name}`)
    }
    return dateVal;
  }

  selectOption(idx, event) {
    if (this.title === 'POPULATION') {
      // console.log('idx', this.selectOptIndex, idx, this.dateRange[idx], this.dateVal);
      if (idx > 1) {
        const pIndex = this.selectOptIndex;
        this.selectOptIndex = idx;
        this.selectOptIndexChange(pIndex)
      } else {
        this.dateTextVal = undefined;
        this.dateTextVal2 = undefined;
        this.selectOptIndex = idx;
      }
    }
  }

  getDateOfWeek(week, year) {
    let d = (1 + (week - 1) * 7); // 1st of January + 7 days for each week
    if (week < 10) {
      d = (1 + (week) * 7); // 1st of January + 7 days for each week
      return new Date(year, 1, d);
    }
    return new Date(year, 0, d);
  }

  getWeekOfMonth(year, month_number) {

    // month_number is in the range 1..12

    var firstOfMonth = new Date(year, month_number - 1, 1);
    var lastOfMonth = new Date(year, month_number, 0);

    var used = firstOfMonth.getDay() + 6 + lastOfMonth.getDate();

    // return Math.ceil(used / 7);
    return this.getWeek(year, month_number, 1);
  }

  getWeek(year, month, day) {
    if (month !== 1) {
      month = month - 1;
    }
    //lets calc weeknumber the cruel and hard way :D
    //Find JulianDay 
    month += 1; //use 1-12
    var a = Math.floor((14 - (month)) / 12);
    var y = year + 4800 - a;
    var m = (month) + (12 * a) - 3;
    var jd = day + Math.floor(((153 * m) + 2) / 5) +
      (365 * y) + Math.floor(y / 4) - Math.floor(y / 100) +
      Math.floor(y / 400) - 32045;      // (gregorian calendar)
    //var jd = (day+1)+Math.Round(((153*m)+2)/5)+(365+y) + 
    //                 Math.round(y/4)-32083;    // (julian calendar)

    //now calc weeknumber according to JD
    var d4 = (jd + 31741 - (jd % 7)) % 146097 % 36524 % 1461;
    var L = Math.floor(d4 / 1460);
    var d1 = ((d4 - L) % 365) + L;
    const NumberOfWeek = Math.floor(d1 / 7) + 1;
    if (month === 1) {
      return 1;
    }
    return NumberOfWeek;
  }

  getWeekOfDate(year, month, day) {
    return this.getWeek(year, month, 1) + Math.floor(day / 7);
  }

  selectOptIndexChange(pIndex) {
    if (this.selectOptIndex === 2) {
      if (pIndex === 4) {
        if (this.dateTextVal) {
          const dateVal = this.getDateOfWeek(parseInt(this.dateTextVal.split("/")[0]), parseInt(this.dateTextVal.split("/")[1]));
          this.dateTextVal = (dateVal.getDate() < 10 ? '0' + dateVal.getDate() : dateVal.getDate()) + '/' + (dateVal.getMonth() < 10 ? '0' + dateVal.getMonth() : dateVal.getMonth()) + '/' + dateVal.getFullYear();
        }
        if (this.dateTextVal2) {
          const dateVal1 = this.getDateOfWeek(parseInt(this.dateTextVal2.split("/")[0]), parseInt(this.dateTextVal2.split("/")[1]));
          this.dateTextVal2 = (dateVal1.getDate() < 10 ? '0' + dateVal1.getDate() : dateVal1.getDate()) + '/' + (dateVal1.getMonth() < 10 ? '0' + dateVal1.getMonth() : dateVal1.getMonth()) + '/' + dateVal1.getFullYear();
        }
      } else if (pIndex === 3) {
        if (this.dateTextVal) {
          const dateVal = this.getDateOfWeek(parseInt(this.dateTextVal.split("/")[1]), parseInt(this.dateTextVal.split("/")[2]));
          this.dateTextVal = (dateVal.getDate() < 10 ? '0' + dateVal.getDate() : dateVal.getDate()) + '/' + (dateVal.getMonth() < 10 ? '0' + dateVal.getMonth() : dateVal.getMonth()) + '/' + dateVal.getFullYear();
        }
        if (this.dateTextVal2) {
          const dateVal1 = this.getDateOfWeek(parseInt(this.dateTextVal2.split("/")[1]), parseInt(this.dateTextVal2.split("/")[2]));
          this.dateTextVal2 = (dateVal1.getDate() < 10 ? '0' + dateVal1.getDate() : dateVal1.getDate()) + '/' + (dateVal1.getMonth() < 10 ? '0' + dateVal1.getMonth() : dateVal1.getMonth()) + '/' + dateVal1.getFullYear();
        }
      } else if (pIndex === 5 || pIndex === 6) {
        if (this.dateTextVal) {
          this.dateTextVal = '01/' + this.dateTextVal.split("/")[0] + '/' + this.dateTextVal.split("/")[1];
        }
        if (this.dateTextVal2) {
          this.dateTextVal2 = '01/' + this.dateTextVal2.split("/")[0] + '/' + this.dateTextVal2.split("/")[1];
        }
      } else if (pIndex === 7) {
        if (this.dateTextVal) {
          this.dateTextVal = '01/01/' + this.dateTextVal;
        }
        if (this.dateTextVal2) {
          this.dateTextVal2 = '01/01/' + this.dateTextVal2;
        }
      }
    } else if (this.selectOptIndex === 5 || this.selectOptIndex === 6) {
      if (pIndex === 2) {
        if (this.dateTextVal) {
          this.dateTextVal = this.dateTextVal.split("/")[1] + '/' + this.dateTextVal.split("/")[2];
        }
        if (this.dateTextVal2) {
          this.dateTextVal2 = this.dateTextVal2.split("/")[1] + '/' + this.dateTextVal2.split("/")[2];
        }
      } else if (pIndex === 4) {
        if (this.dateTextVal) {
          const dateVal = this.getDateOfWeek(parseInt(this.dateTextVal.split("/")[0]), parseInt(this.dateTextVal.split("/")[1]));
          this.dateTextVal = (dateVal.getMonth() < 10 ? '0' + dateVal.getMonth() : dateVal.getMonth()) + '/' + dateVal.getFullYear();
        }
        if (this.dateTextVal2) {
          const dateVal1 = this.getDateOfWeek(parseInt(this.dateTextVal2.split("/")[0]), parseInt(this.dateTextVal2.split("/")[1]));
          this.dateTextVal2 = (dateVal1.getMonth() < 10 ? '0' + dateVal1.getMonth() : dateVal1.getMonth()) + '/' + dateVal1.getFullYear();
        }
      } else if (pIndex === 3) {
        if (this.dateTextVal) {
          const dateVal = this.getDateOfWeek(parseInt(this.dateTextVal.split("/")[1]), parseInt(this.dateTextVal.split("/")[2]));
          this.dateTextVal = (dateVal.getMonth() < 10 ? '0' + dateVal.getMonth() : dateVal.getMonth()) + '/' + dateVal.getFullYear();
        }
        if (this.dateTextVal2) {
          const dateVal1 = this.getDateOfWeek(parseInt(this.dateTextVal2.split("/")[1]), parseInt(this.dateTextVal2.split("/")[2]));
          this.dateTextVal2 = (dateVal1.getMonth() < 10 ? '0' + dateVal1.getMonth() : dateVal1.getMonth()) + '/' + dateVal1.getFullYear();
        }
      } else if (pIndex === 7) {
        if (this.dateTextVal) {
          this.dateTextVal = '01/' + this.dateTextVal;
        }
        if (this.dateTextVal2) {
          this.dateTextVal2 = '01/' + this.dateTextVal2;
        }
      }
    } else if (this.selectOptIndex === 7) {
      if (this.dateTextVal) {
        if (this.dateTextVal.replace("/", "").replace("/", "").length > 6) {
          this.dateTextVal = this.dateTextVal.split("/")[2];
        } else if (this.dateTextVal.replace("/", "").replace("/", "").length === 6) {
          this.dateTextVal = this.dateTextVal.split("/")[1];
        }
      }
      if (this.dateTextVal2) {
        if (this.dateTextVal2.replace("/", "").replace("/", "").length > 6) {
          this.dateTextVal2 = this.dateTextVal2.split("/")[2];
        } else if (this.dateTextVal2.replace("/", "").replace("/", "").length === 6) {
          this.dateTextVal2 = this.dateTextVal2.split("/")[1];
        }
      }
    } else if (this.selectOptIndex === 4) {
      if (pIndex === 5 || pIndex === 6) {
        if (this.dateTextVal) {
          const val = this.getWeekOfMonth(this.dateTextVal.split("/")[1], this.dateTextVal.split("/")[0]);
          this.dateTextVal = (val < 10 ? '0' + val : val) + '/' + this.dateTextVal.split("/")[1];
        }
        if (this.dateTextVal2) {
          const val1 = this.getWeekOfMonth(this.dateTextVal2.split("/")[1], this.dateTextVal2.split("/")[0]);
          this.dateTextVal2 = (val1 < 10 ? '0' + val1 : val1) + '/' + this.dateTextVal2.split("/")[1];
        }
      } else if (pIndex === 2) {
        if (this.dateTextVal) {
          const val = this.getWeekOfDate(this.dateTextVal.split("/")[2], this.dateTextVal.split("/")[1], this.dateTextVal.split("/")[0]);
          this.dateTextVal = (val < 10 ? '0' + val : val) + '/' + this.dateTextVal.split("/")[2];
        }
        if (this.dateTextVal2) {
          const val1 = this.getWeekOfDate(this.dateTextVal2.split("/")[2], this.dateTextVal2.split("/")[1], this.dateTextVal2.split("/")[0]);
          this.dateTextVal2 = (val1 < 10 ? '0' + val1 : val1) + '/' + this.dateTextVal2.split("/")[2];
        }
      } else if (pIndex === 3) {
        if (this.dateTextVal) {
          this.dateTextVal = this.dateTextVal.split("/")[1] + '/' + this.dateTextVal.split("/")[2];
        }
        if (this.dateTextVal2) {
          this.dateTextVal2 = this.dateTextVal2.split("/")[1] + '/' + this.dateTextVal2.split("/")[2];
        }
      } else if (pIndex === 7) {
        if (this.dateTextVal) {
          this.dateTextVal = '01/' + this.dateTextVal;
        }
        if (this.dateTextVal2) {
          this.dateTextVal2 = '01/' + this.dateTextVal2;
        }
      }
    } else if (this.selectOptIndex === 3) {
      if (pIndex === 5 || pIndex === 6) {
        if (this.dateTextVal) {
          const val = this.getWeekOfMonth(this.dateTextVal.split("/")[1], this.dateTextVal.split("/")[0]);
          this.dateTextVal = '1/' + (val < 10 ? '0' + val : val) + '/' + this.dateTextVal.split("/")[1];
        }
        if (this.dateTextVal2) {
          const val1 = this.getWeekOfMonth(this.dateTextVal2.split("/")[1], this.dateTextVal2.split("/")[0]);
          this.dateTextVal2 = '1/' + (val1 < 10 ? '0' + val1 : val1) + '/' + this.dateTextVal2.split("/")[1];
        }
      } else if (pIndex === 2) {
        if (this.dateTextVal) {
          const val = this.getWeekOfDate(this.dateTextVal.split("/")[2], this.dateTextVal.split("/")[1], this.dateTextVal.split("/")[0]);
          this.dateTextVal = '1/' + (val < 10 ? '0' + val : val) + '/' + this.dateTextVal.split("/")[2];
        }
        if (this.dateTextVal2) {
          const val1 = this.getWeekOfDate(this.dateTextVal2.split("/")[2], this.dateTextVal2.split("/")[1], this.dateTextVal2.split("/")[0]);
          this.dateTextVal2 = '1/' + (val1 < 10 ? '0' + val1 : val1) + '/' + this.dateTextVal2.split("/")[2];
        }
      } else if (pIndex === 4) {
        if (this.dateTextVal) {
          this.dateTextVal = '1/' + this.dateTextVal.split("/")[0] + '/' + this.dateTextVal.split("/")[1];
        }
        if (this.dateTextVal2) {
          this.dateTextVal2 = '1/' + this.dateTextVal2.split("/")[0] + '/' + this.dateTextVal2.split("/")[1];
        }
      } else if (pIndex === 7) {
        if (this.dateTextVal) {
          this.dateTextVal = '1/' + '01/' + this.dateTextVal;
        }
        if (this.dateTextVal2) {
          this.dateTextVal2 = '1/' + '01/' + this.dateTextVal2;
        }
      }
    }
  }

  isLeapYear(year) {
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
  }

  daysOfAYear(year) {

    return this.isLeapYear(year) ? 366 : 365;
  }

  lastWeeksInYear(year) {
    return Math.ceil(this.daysOfAYear(year) / 7);
  }

  dateValidation() {
    const curYearVal = new Date().getFullYear() + 10;
    if (this.dateTextVal) {
      if (this.dateTextVal.replace('/', '').replace('/', '').length < 4) {
        this.toastyService.warning("Invalid Format");
        return;
      }
      if (this.selectOptIndex === 2) {
        if (this.dateTextVal.replace('/', '').replace('/', '').length < 5) {
          this.toastyService.warning("Invalid Format");
          return;
        }
        let val = this.dateTextVal.split('/');
        const dateVal = new Date(`${val[2]}/${val[1]}/01`);
        const lastDayOfMonth = new Date(dateVal.getFullYear(), dateVal.getMonth() + 1, 0);
        if (parseInt(val[0]) > lastDayOfMonth.getDate() || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Date");
          return false;
        } else if (parseInt(val[1]) > 12 || parseInt(val[1]) < 1) {
          this.toastyService.warning("Invalid Month");
          return false;
        } else if (val[2].length !== 4 || parseInt(val[2]) > curYearVal || parseInt(val[2]) < (curYearVal - 50)) {
          this.toastyService.warning("Invalid Year");
          return false;
        }
      } else if (this.selectOptIndex === 5 || this.selectOptIndex === 6) {
        let val = this.dateTextVal.split('/');
        let dateVal = new Date(`${val[2]}/${val[1]}/${val[0]}`);
        if (parseInt(val[0]) > 12 || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Month");
          return false;
        } else if (val[1].length !== 4 || parseInt(val[1]) > curYearVal || parseInt(val[1]) < (curYearVal - 50)) {
          this.toastyService.warning("Invalid Year");
          return false;
        }
      } else if (this.selectOptIndex === 4) {
        let val = this.dateTextVal.split('/');
        if (parseInt(val[0]) > this.lastWeeksInYear(val[1]) || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Week");
          return false;
        } else if (val[1].length !== 4 || parseInt(val[1]) > curYearVal || parseInt(val[1]) < (curYearVal - 50)) {
          this.toastyService.warning("Invalid Year");
          return false;
        }
      } else if (this.selectOptIndex === 3) {
        let val = this.dateTextVal.split('/');
        if (parseInt(val[0]) > 7 || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Day");
          return false;
        } else if (parseInt(val[1]) > this.lastWeeksInYear(val[2]) || parseInt(val[1]) < 1) {
          this.toastyService.warning("Invalid Week");
          return false;
        } else if (val[2].length !== 4 || parseInt(val[2]) > curYearVal || parseInt(val[2]) < (curYearVal - 50)) {
          this.toastyService.warning("Invalid Year");
          return false;
        }
      } else if (this.selectOptIndex === 7) {
        let val = this.dateTextVal;
        if (val.length !== 4 || parseInt(val) > curYearVal || parseInt(val) < (curYearVal - 50)) {
          this.toastyService.warning("Invalid Year");
          return false;
        }
      }
    } else {
      return true;
    }

    if (this.dateTextVal2) {
      if (this.dateTextVal2.replace('/', '').replace('/', '').length < 4) {
        this.toastyService.warning("Invalid Format");
        return;
      }
      if (this.selectOptIndex === 2) {
        if (this.dateTextVal2.replace('/', '').replace('/', '').length < 5) {
          this.toastyService.warning("Invalid Format");
          return;
        }
        let val = this.dateTextVal2.split('/');
        const dateVal = new Date(`${val[2]}/${val[1]}/01`);
        const lastDayOfMonth = new Date(dateVal.getFullYear(), dateVal.getMonth() + 1, 0);
        if (parseInt(val[0]) > lastDayOfMonth.getDate() || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Date");
          return false;
        } else if (parseInt(val[1]) > 12 || parseInt(val[1]) < 1) {
          this.toastyService.warning("Invalid Month");
          return false;
        } else if (val[2].length !== 4 || parseInt(val[2]) > curYearVal || parseInt(val[2]) < (curYearVal - 50)) {
          this.toastyService.warning("Invalid Year");
          return false;
        }
      } else if (this.selectOptIndex === 5 || this.selectOptIndex === 6) {
        let val = this.dateTextVal2.split('/');
        let dateVal = new Date(`${val[2]}/${val[1]}/${val[0]}`);
        if (parseInt(val[0]) > 12 || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Month");
          return false;
        } else if (val[1].length !== 4 || parseInt(val[1]) > curYearVal || parseInt(val[1]) < (curYearVal - 50)) {
          this.toastyService.warning("Invalid Year");
          return false;
        }
      } else if (this.selectOptIndex === 4) {
        let val = this.dateTextVal2.split('/');
        if (parseInt(val[0]) > this.lastWeeksInYear(val[1]) || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Week");
          return false;
        } else if (val[1].length !== 4 || parseInt(val[1]) > curYearVal || parseInt(val[1]) < (curYearVal - 50)) {
          this.toastyService.warning("Invalid Year");
          return false;
        }
      } else if (this.selectOptIndex === 3) {
        let val = this.dateTextVal2.split('/');
        if (parseInt(val[0]) > 7 || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Day");
          return false;
        } else if (parseInt(val[1]) > this.lastWeeksInYear(val[2]) || parseInt(val[1]) < 1) {
          this.toastyService.warning("Invalid Week");
          return false;
        } else if (val[2].length !== 4 || parseInt(val[2]) > curYearVal || parseInt(val[2]) < (curYearVal - 50)) {
          this.toastyService.warning("Invalid Year");
          return false;
        }
      } else if (this.selectOptIndex === 7) {
        let val = this.dateTextVal2;
        if (val.length !== 4 || parseInt(val) > curYearVal || parseInt(val) < (curYearVal - 50)) {
          this.toastyService.warning("Invalid Year");
          return false;
        }
      }

      return true;
    } else {
      return true;
    }
  }

}
