import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { MaxLengthValidator } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastyService } from 'ng2-toasty';
import { MatomoTracker } from 'ngx-matomo';
import { BackendApiService } from 'src/app/services/backend-api/backend-api.service';
import { RequestService } from 'src/app/services/request/request.service';
import { UtilService } from 'src/app/services/util/util.service';
import { AlertConfirmationComponent } from '../alert-confirmation/alert-confirmation.component';
import { UserMessageSendinstantlyComponent } from './user-message-sendinstantly/user-message-sendinstantly.component';

@Component({
  selector: 'app-user-message',
  templateUrl: './user-message.component.html',
  styleUrls: ['./user-message.component.scss']
})
export class UserMessageComponent implements OnInit {

  alertMsg = [{ 'EUROPE': [] },
  { 'MERCOSUR': [] }];
  userValues = {
    "ipn": undefined, "region": undefined, "responseData": undefined,
    "tableData": undefined, "selectedRegion": undefined, "selectedTable": undefined, "selectedTableDetails": undefined,
    "dateDiff": undefined, "requestDatas": undefined, "roleBasedTableList": undefined
  };

  profile = this.utilService.getUserProfile();
  isAdmin = false;
  roleBasedGroupList;

  messageTypes = [];
  selectedMsgType;
  isPriority = false;

  blnQuickLink: boolean;
  textMsg: string;


  constructor(readonly router: Router, readonly activatedRoute: ActivatedRoute, public dialog: MatDialog,
    readonly apiService: BackendApiService, readonly utilService: UtilService,
    private translateService: TranslateService,
    readonly requestService: RequestService, readonly toastyService: ToastyService, private matomoTracker: MatomoTracker) {
    this.utilService.$showIcon$.next(false);
  }

  ngOnInit() {
    // alert("user Managemenat component")
    // const role = this.utilService.getUserProfile()['role-acc-pil-irn6804'];

    // let regionRole = role.replace('[', '').replace(']', '');
    // let isEuroAdmin = false;
    // let isMercAdmin = false;
    // if (this.profile['role-pil-irn6804'] || this.profile['role-pim-irn9207']) {
    //   if (this.profile['role-pil-irn6804'].indexOf('pil_admin') > -1) {
    //     isEuroAdmin = true;
    //   }
    //   if (this.profile['role-pim-irn9207'].indexOf('pim_admin') > -1) {
    //     isMercAdmin = true;
    //   }
    // }

    // regionRole = regionRole.split(',');
    // if (regionRole.length > 1 && isMercAdmin && isEuroAdmin) {
    //   this.userValues.region = ['Europe', 'Mercosur'];
    //   this.userValues.selectedRegion = "Europe";
    // } else {
    //   if (regionRole[0] === 'acc_pil_europe' && isEuroAdmin) {
    //     this.userValues.region = ['Europe'];
    //     this.userValues.selectedRegion = "Europe";
    //   } else {
    //     if (isMercAdmin) {
    //       this.userValues.region = ['Mercosur'];
    //       this.userValues.selectedRegion = "Mercosur";
    //     }
    //   }
    // }

    // okta
    let regionRole = this.utilService.getUserProfile()['roles'].filter(item => item.indexOf('ACC_PI') > -1);
    if (regionRole.length > 1) {
      this.userValues.region = ['Europe', 'Mercosur'];
      this.userValues.selectedRegion = "Europe";
    } else {
      let region: string;
      regionRole[0] === 'ACC_PIL_EUROPE' ? region = 'Europe' : region = 'Mercosur';
      this.userValues.region = [region];
      this.userValues.selectedRegion = region;
    }

    this.getMsg(this.userValues.selectedRegion);
    this.matomoTracker.trackPageView(`Management of user message`);
  }

  getMsg(region) {
    this.isPriority = false;
    this.userValues.selectedRegion = region;
    this.textMsg = "";
    const queryParam = { "input": "MESS@system@Message@@0@1@", "region": this.getFavoriteRegion() };
    this.apiService.getMessageObjectList(queryParam).subscribe(res => {
      // console.log("res", res);
      this.selectedMsgType = "none";
      let queryMsgType;
      if (res.lists.length > 0) {
        queryMsgType = res.lists[0];
        if (res.lists[0].split("_").length > 1) {
          this.selectedMsgType = res.lists[0].split("_")[0];
          this.isPriority = true;
        } else {
          this.selectedMsgType = res.lists[0];
        }
      }
      const queryParam1 = { "input": `MESS@system@Message@${queryMsgType}@`, "region": this.getFavoriteRegion() };
      this.apiService.getMessageObject(queryParam1).subscribe(res1 => {
        // console.log("res1", res1);
        if (res1.data) {
          const splitVal = res1.data.split("<BR>");
          this.textMsg = "";
          for (const item of splitVal) {
            this.textMsg = this.textMsg + item + "\n";
          }
          this.textMsg = this.textMsg.toString().substr(0, this.textMsg.length - 1);
        }
      },
        error => {
          // this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
          console.log("error", error.message);
        });

    },
      error => {
        // this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
        console.log("error", error.message);
      });
  }

  getFavoriteRegion() {
    if (this.userValues.selectedRegion === "Europe") {
      return "PIL";
    } else {
      return "PIM";
    }
  }

  clickClose() {
    const pathName = window.location.pathname;
    this.utilService.removeOpenedWindowName(pathName);
    this.utilService.$showIcon$.next(true);
    const openedWindowNameList = this.utilService.getOpenedWindowNameList()
    if (openedWindowNameList.length > 0) {
      this.router.navigate([openedWindowNameList[openedWindowNameList.length - 1]]);
    } else {
      this.router.navigate(['home']);
    }
  }

  alertPopup(msg, isAlert) {
    const dialogRef = this.dialog.open(AlertConfirmationComponent, {
      disableClose: true,
      width: '550px',
      data: { 'display': msg, isAlert: isAlert }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (!isAlert) {

          if (this.selectedMsgType === "perm") {
            if (this.isPriority === true) {
              this.deleteMessage("perm_prio");
            }
            else {
              this.deleteMessage("perm");
            }
          }
          else if (this.selectedMsgType === "tmp") {
            if (this.isPriority === true) {
              this.deleteMessage("tmp_prio");
            }
            else {
              this.deleteMessage("tmp");
            }
          }
          else {
            this.deleteMessage("none");
          }
        }
      }
    })
  }

  onClickValid() {
    if (this.textMsg.toString().trim() === "") {
      this.alertPopup(this.utilService.getTranslateName("USER_MESSAGE.MESSAGE_EMPTY"), true);
      this.textMsg = "";
      return;
    }
    if (this.textMsg.length > 500) {
      this.alertPopup(this.utilService.getTranslateName("USER_MESSAGE.ATTENTION_MESSAGE"), true);
      return;
    }
    if (this.selectedMsgType === "perm") {
      if (this.isPriority === true) {
        this.alertPopup(`${this.utilService.getTranslateName("USER_MESSAGE.ATTENTION_PRIORITY")} ${this.utilService.getTranslateName("USER_MESSAGE.ATTENTION_PERMANENT")}`, false);
        // this.deleteMessage();
      }
      else {
        this.alertPopup(this.utilService.getTranslateName("USER_MESSAGE.ATTENTION_PERMANENT"), false);
      }
    }
    else if (this.selectedMsgType === "tmp") {
      if (this.isPriority === true) {
        this.alertPopup(`${this.utilService.getTranslateName("USER_MESSAGE.ATTENTION_PRIORITY")} ${this.utilService.getTranslateName("USER_MESSAGE.ATTENTION_TEMPORARY")}`, false);
      }
      else {
        this.alertPopup(this.utilService.getTranslateName("USER_MESSAGE.ATTENTION_TEMPORARY"), false);
      }
    }
    else {
      this.alertPopup(this.utilService.getTranslateName("USER_MESSAGE.ATTENTION_NONE"), false);
    }
  }
  onClickSend() {
    const dialogRef = this.dialog.open(UserMessageSendinstantlyComponent, {
      disableClose: true,
      width: '550px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
      }
    })
  }
  deleteMessage(type) {
    const queryParam = { "input": "MESS@system@Message@@0@4@", "region": this.getFavoriteRegion() };
    this.apiService.getMessageObjectList(queryParam).subscribe(res1 => {
      // console.log("res1", res1);
      if (res1) {
        if (res1.noOfLists === 0) {
          this.addMessage(type);
        } else {
          for (let index = 0; index < res1.noOfLists; index++) {
            const element = res1.lists[index];
            const queryParam1 = { "input": `MESS@system@Message@${element}@`, "region": this.getFavoriteRegion() };
            this.apiService.deleteObject(queryParam1).subscribe(resDel => {
              // console.log("resDel", resDel);
              if (index === res1.noOfLists - 1 && this.selectedMsgType !== "none") {
                this.addMessage(type);
              }
              if (this.selectedMsgType === "none") {
                this.textMsg = "";
              }
            },
              error => {
                // this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
                console.log("error", error.message);
              });
            // console.log(this.textMsg.replace("\n", "<BR>"));
          }
        }
      }
    },
      error => {
        // this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
        console.log("error", error.message);
      });
  }

  addMessage(type) {

    const splitVal = this.textMsg.split('\n');
    let textVal = "";
    for (let index = 0; index < splitVal.length; index++) {
      const element = splitVal[index];
      textVal = textVal + element + "<BR>";

      if (index === splitVal.length - 1) {
        textVal = textVal.substr(0, textVal.length - 4);
        const queryParam1 = { "input": `MESS@system@Message@${type}@${textVal}`, "region": this.getFavoriteRegion() };
        this.apiService.saveObject(queryParam1).subscribe(resAdd => {
          // console.log("resAdd", resAdd);
          this.matomoTracker.trackPageView(`Management of user message`);
          this.matomoTracker.trackEvent("Management of user message", `Create new user message`, this.profile['login']);
        },
          error => {
            // this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
            console.log("error", error.message);
          });
      }
    }

  }
}