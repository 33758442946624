import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { UtilService } from '../../../../services/util/util.service';

@Component({
  selector: 'app-grouping-list',
  templateUrl: './grouping-list.component.html',
  styleUrls: ['./grouping-list.component.scss']
})

export class GoupingListComponent {
  @Input() title: string;
  @Input() selectedListItem: string;
  @Input() displayGroupingForm: string;
  @Input() isEnableAllTextBox: boolean;
  @Input() screenName: boolean;
  @Output() setGrouping = new EventEmitter();
  wording: string;
  allText: string = '0';
  selectedItem: string;
  groupingText: string[] = [];
  selectedCriterias: string[] = [];
  isSubmitted: boolean = false;
  criteria: string;
  criteriaOptions = [
    { name: 'COMPACT', value: 'Code Compact', selected: false },
    { name: 'ALL', value: 'Tous', selected: false },
    { name: 'INDEX', value: 'Indice', selected: false }]

  constructor(readonly utilService: UtilService) { }

  ngOnChanges() {
    this.groupingText = [];
    let freeFormat: boolean = false;
    if (!this.isEnableAllTextBox) {
      this.allText = '10';
    }
    if (this.displayGroupingForm) {
      for (const item of this.criteriaOptions) {
        // if (item.value === this.displayGroupingForm.split(',')[0]) {
        if (this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.${item.name}`) === this.displayGroupingForm.split(',')[0]) {
          item.selected = true;
          // this.criteria = item.value
          this.criteria = this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.${item.name}`);
          this.isSubmitted = true;
          freeFormat = true;
          if (this.displayGroupingForm.split(',')[1]) {
            this.allText = this.displayGroupingForm.split(',')[1].trim();
          }
        }
      }
      if (!freeFormat) {
        for (const item of this.displayGroupingForm.split(',')) {
          this.groupingText.push(item);
          this.isSubmitted = true;
        }
      }
    }
  }

  moveRight() {
    if (this.wording && !this.groupingText.includes(this.wording.toLocaleUpperCase())) {
      this.groupingText.push(this.wording.toLocaleUpperCase());
      this.selectedCriterias.push(this.wording);
      this.isSubmitted = true;
      this.wording = '';
    }
  }
  moveLeft() {
    this.groupingText = this.groupingText.filter(item => this.selectedItem !== item);
    this.wording = this.selectedItem;
    // this.selectedItem = '';
  }

  moveUp(id) {
    document.getElementById(id).scrollBy(0, -20);
    this.array_move(-1);
  }

  moveDown(id) {
    document.getElementById(id).scrollBy(0, 20);
    this.array_move(1);
  }

  array_move(list) {
    let clonedGroupingText = [...this.groupingText];
    for (let item of this.selectedCriterias) {
      let old_index = clonedGroupingText.indexOf(item);
      let new_index = old_index + list;
      if (new_index <= -1 || new_index >= this.groupingText.length) {
        return;
      }
      this.groupingText.splice(new_index, 0, this.groupingText.splice(old_index, 1)[0]);
    }
    return this.groupingText;
  }

  checkSelectedListItem(value) {
    this.selectedCriterias = [];
    this.selectedCriterias.push(value);
    this.selectedItem = value;
  }

  maxLengthCheck(object) {
    object.target.value = Math.abs(object.target.value);
    if (object.target.value.length > object.target.maxLength)
      object.target.value = object.target.value.slice(0, object.target.maxLength)
  }

  checkSelectedCheckboxOptions(list) {
    if (list.selected) {
      this.isSubmitted = true;
      // this.criteria = list.value;
      this.criteria = this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.${list.name}`);
    } else {
      if (this.groupingText.length === 0) {
        this.isSubmitted = false;
      }
      this.allText = '0';
      if (!this.isEnableAllTextBox) {
        this.allText = '10';
      }
      this.criteria = '';
    }
    this.criteriaOptions.some(criteria => {
      if (criteria.name !== list.name) {
        criteria.selected = false;
      }
    });
  }

  submit() {
    let allTextValue: string = '';
    if (Number(this.allText) !== 0 && this.allText !== null) {
      allTextValue = ',' + ((Number(this.allText) < 0) ? (-Number(this.allText)).toString() : ((Number(this.allText) > 0) ? this.allText : ''));
    }
    if (this.groupingText.length || this.criteria) {
      this.isSubmitted = true;
      // this.criteria = this.criteria === 'Tous' ? this.criteria + allTextValue : this.criteria;
      this.criteria = this.criteria === this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.ALL`) ? this.criteria + allTextValue : this.criteria;
      let value = {
        value: this.criteria ? this.criteria : this.groupingText,
        displayGroupingForm: false
      };
      this.setGrouping.emit(value);
      this.groupingText = [];
      this.selectedCriterias = [];
      this.criteria = '';
    }
  }

  cancel() {
    let value = {
      value: '',
      displayGroupingForm: false
    };
    this.setGrouping.emit(value);
  }
}