import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BackendApiService } from 'src/app/services/backend-api/backend-api.service';
import { UtilService } from 'src/app/services/util/util.service';
import { RealignmentDataComponent } from './realignment-data/realignment-data.component';
import { ToastyService } from 'ng2-toasty';
import { DatePipe } from '@angular/common';
import { AlertConfirmationComponent } from '../alert-confirmation/alert-confirmation.component';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-realignment-main',
  templateUrl: './realignment-main.component.html',
  styleUrls: ['./realignment-main.component.scss'],
  providers: [DatePipe]
})
export class RealignmentMainComponent implements OnInit {
  profile: any;
  region: string[];
  selectedRegion: string;
  lostLogDetails: any;
  data1: any;
  tabData: any;
  tableData: [];
  selectedRow: any;
  modData: any;
  rawData: any;
  criteriaList: any;
  dataList: any;
  modData1: any;
  criteriaData: any;
  selectedCriteria: any;
  selectedData: any;
  runData: any;
  getRun: any;


  constructor(readonly utilService: UtilService, readonly router: Router, readonly apiService: BackendApiService, public dialog: MatDialog,
    readonly toastyService: ToastyService, public datepipe: DatePipe) { }

  ngOnInit() {
    this.utilService.$showIcon$.next(false);
    this.profile = this.utilService.getUserProfile();
    // const role = this.profile['roles'];
    //console.log(this.profile['roles']);
    //console.log(this.region)
    let regionRole =  this.utilService.getUserProfile()['roles'].filter(item => item.includes('ACC_PIL'));
    regionRole = { ...regionRole};
    if (regionRole.length > 1 && this.profile['roles'].includes('PIL_ADMIN') && this.profile['roles'].includes('PIM_ADMIN')) {
  
      this.region = [];
      if(this.profile['roles'].includes('GR_PIL_TOUT'))
        this.region.push('Europe');
      if(this.profile['roles'].includes('GR_PIM_TOUT'))
        this.region.push('Mercosur');
      
      this.selectedRegion = this.profile['roles'].includes('GR_PIL_TOUT') ? "Europe" : "Mercosur";
    } else {
     
      let reg  = (this.profile['roles'].includes('ACC_PIL_EUROPE') && this.profile['roles'].includes('PIL_ADMIN') && this.profile['roles'].includes('GR_PIL_TOUT')) ? 'Europe' : 'Mercosur';
      this.region = [reg];
      this.selectedRegion = reg;
    }
    this.getLostLog();
    this.getRealignmentData();
  }

  getFavoriteRegion() {
    if (this.selectedRegion === "Europe") {
      return "PIL";
    } else {
      return "PIM";
    }
  }

  getLostLog() {
    this.lostLogDetails = undefined;
    const queryParam = { "input": `REALIGNEMENT@system@rea@info@0@55@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectGet(queryParam).subscribe(resData => {
      // if(resData.data){
      this.lostLogDetails = resData.data.replace('§', '  ').replace('&', ' ');
      console.log("lostlogin", this.lostLogDetails);
      // } 
    })
  }

  getRealignmentData() {
    this.lostLogDetails = undefined;
    let test1;
    this.tabData = [];
    const queryParam = { "input": `REALIGNEMENT@system@rea@info@0@55@`, "region": this.getFavoriteRegion() };
    this.apiService.getChoiceTableDetails(queryParam).subscribe(resData1 => {
      console.log("data0", resData1.lists)
      this.data1 = resData1.lists;

      for (var i = 0; i < this.data1.length; i++) {
        if (this.data1[i].split("§")[2] === "C") {
          console.log("check", this.data1[i])
          this.rawData = resData1.lists[i];
          console.log("try", this.rawData)
          test1 = resData1.lists[i].split('§');
          console.log("tabledata", test1)
          // test1.push(this.data1[i]);
          // if (test1[3] !== "OK") {
          this.tabData.push({ "rawdata": this.rawData, "table": test1[0], "demande": test1[1], "etat": test1[3] === 'p' ? 'PENDING' : test1[3] === 's' ? 'OK' : test1[3] === 'c' ? 'CREATE' : test1[3] });
          // }
          // this.selectedRow = this.tabData;
          console.log("dataa", this.tabData)
        }
      }

    })
  }

  onRefresh() {
    this.getLostLog();
    this.getRealignmentData();
  }

  onChangedRegion(item) {
    this.selectedRegion = item;
    this.getLostLog();
    this.getRealignmentData();
  }

  openHelpFile() {
    window.open('https://grouperenault.sharepoint.com/sites/Doc_Pilotage/en-US/Pages/Aide%20en%20ligne/maj_libelle.aspx', '_blank');
  }

  clickClose() {
    const pathName = window.location.pathname;
    this.utilService.removeOpenedWindowName(pathName);
    this.utilService.$showIcon$.next(true);
    const openedWindowNameList = this.utilService.getOpenedWindowNameList()
    if (openedWindowNameList.length > 0) {
      this.router.navigate([openedWindowNameList[openedWindowNameList.length - 1]]);
    } else {
      this.router.navigate(['home']);
    }
  }

  onAdd() {
    const dialogRef = this.dialog.open(RealignmentDataComponent, {
      disableClose: true,
      width: '900px',
      data: { 'title': this.utilService.getTranslateName(`REALIGNMENT.REALIGNMENT_DATA_TITLE`), 'region': this.selectedRegion, 'ipn': this.profile['login'] }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // const queryParam = { "input": `REALIGNEMENT@system@rea@@0@55@`, "region": this.getFavoriteRegion() };
        // this.apiService.getChoiceTableDetails(queryParam).subscribe(res => {
        //   console.log("data", res);

        // })
        this.onRefresh()
      }
    })
  }

  onModify() {
    this.criteriaList = [];
    console.log("rowData", this.selectedRow)
    let queryParam = { "input": `REALIGNEMENT@system@rea@${this.rawData}@`, "region": this.getFavoriteRegion(), 'ipn': this.profile['login'] };
    this.apiService.getObjectGet(queryParam).subscribe(res1 => {
      this.modData = res1.data;
      console.log("modData", this.modData);
      let selectedCriteriaList = [], selectedDataList = [], isData: boolean;
      this.modData1 = this.modData.split("@");
      console.log("modData1", this.modData1);
      for (var i = 2; i < this.modData1.length - 2; i++) {
        if (this.modData1[i] === "DATA") {
          isData = true;
        } else {
          if (!isData) {
            selectedCriteriaList.push(this.modData1[i]);
          } else {
            selectedDataList.push(this.modData1[i]);
          }
        }
        this.selectedCriteria = selectedCriteriaList;
        console.log("criterialist", selectedCriteriaList);
        this.selectedData = selectedDataList;
        console.log("datalist", selectedDataList);
      }
      // console.log("modData1",this.modData1);
      // this.criteriaData=this.modData1[1].split("@");
      // console.log("criteria",this.criteriaData);

      // for(var i=0; i<this.criteriaData.length; i++){
      //   if(this.criteriaData[i]!==""){
      //     alert("working");
      //     // this.criteriaList[i]=criteria[i];
      //     alert("valCheck"+this.criteriaData[i]);
      //     this.criteriaList.push(this.criteriaData[i]);
      //     console.log("listCriteria",this.criteriaList);
      //   }
      // }
      // this.dataList=this.criteriaData.toString().split("DATA");
      // console.log("ListData",this.dataList);
      const dialogRef = this.dialog.open(RealignmentDataComponent, {
        disableClose: true,
        width: '900px',
        data: { 'title': this.utilService.getTranslateName(`REALIGNMENT.REALIGNMENT_DATA_TITLE`), 'region': this.selectedRegion, 'ipn': this.profile['login'], 'criterialist': this.selectedCriteria, 'datalist': this.selectedData }
      })
    });


  }
  rowData(data, i) {
    this.selectedRow = data;
    // document.getElementById("modify").disabled=false
  }

  onRun() {
    // debugger
    if (this.selectedRow) {



      for (let index = 0; index < this.tabData.length; index++) {
        const element = this.tabData[index];
        if (element.etat === "RUNNING") {
          this.toastyService.warning(this.utilService.getTranslateName(`ERROR.RUN_RUNNING`));
          return;
        }
        if (index === this.tabData.length - 1) {
          const queryParam1 = {
            "input": "REALIGNEMENT@system@rea@lock_recopie@0@55@", "region": this.getFavoriteRegion()
          };
          this.apiService.getObjectGet(queryParam1).subscribe(res1 => {
            console.log("lock_recopie", res1);
            const currentDate = this.datepipe.transform(new Date(), 'yyyyMMdd');
            if (currentDate !== res1.data) {
              this.toastyService.warning(this.utilService.getTranslateName(`ERROR.RUNNING_DATE`));
              return;
            } else {
              this.runPopUp();
            }
          },

            error => {
              console.log("rundataErr", error);
              // this.toastyService.warning()
            })
          return of(false);
        }
      }

      // if (!this.checkCondRunBackup()) {
      //   const queryParam1 = { "input": `${this.profile['uid']}@LIB@REA_RUN@${this.selectedRow.table}§${this.selectedRow.demande}@@`, "region": this.getFavoriteRegion() };
      //   console.log("rundata queryParam1", queryParam1);
      //   this.apiService.specialBases(queryParam1).subscribe(res1 => {
      //     this.runData = res1;
      //     console.log("rundata", this.runData);
      //   },

      //     error => {
      //       console.log("rundataErr", error);
      //     })
      // }
    }
  }


  runPopUp() {
    const dialogRef = this.dialog.open(AlertConfirmationComponent, {
      disableClose: true,
      width: '550px',
      data: { 'display': this.utilService.getTranslateName(`REALIGNMENT.RUN_CONF`) }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.selectedRow.etat === "OK" || this.selectedRow.etat === "RUNNING" || this.selectedRow.etat === "KO") {
          this.toastyService.warning(this.utilService.getTranslateName(`ERROR.RUN_STATUS`));
          return;
        } else {

          const queryParam1 = { "input": `${this.profile['login']}@LIB@REA_RUN@${this.selectedRow.table}§${this.selectedRow.demande}@@`, "region": this.getFavoriteRegion() };
          console.log("rundata queryParam1", queryParam1);
          this.apiService.specialBases(queryParam1).subscribe(res1 => {
            this.runData = res1;
            console.log("rundata", this.runData);
          },

            error => {
              console.log("rundataErr", error);
            });
        }
        this.onRefresh()
      }
    })
  }

  onBackup() {
    if (this.selectedRow) {

      for (let index = 0; index < this.tabData.length; index++) {
        const element = this.tabData[index];
        if (element.etat === "RUNNING") {
          this.toastyService.warning(this.utilService.getTranslateName(`ERROR.RUN_RUNNING`));
          return;
        }
        if (index === this.tabData.length - 1) {
          const queryParam1 = {
            "input": "REALIGNEMENT@system@rea@lock_recopie@0@55@", "region": this.getFavoriteRegion()
          };
          this.apiService.getObjectGet(queryParam1).subscribe(res1 => {
            console.log("lock_recopie", res1);
            const currentDate = this.datepipe.transform(new Date(), 'yyyyMMdd');
            if (currentDate !== res1.data) {
              this.toastyService.warning(this.utilService.getTranslateName(`ERROR.RUNNING_DATE`));
              return;
            } else {
              this.backupPopUp();
            }
          },

            error => {
              console.log("rundataErr", error);
              // this.toastyService.warning()
            })
          return of(false);
        }
      }
    }
  }


  backupPopUp() {
    const dialogRef = this.dialog.open(AlertConfirmationComponent, {
      disableClose: true,
      width: '550px',
      data: { 'display': this.utilService.getTranslateName(`REALIGNMENT.BACKUP_ALERT`) }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const queryParam1 = { "input": `${this.profile['login']}@LIB@REA_BACKUP@@@`, "region": this.getFavoriteRegion() };
        console.log("backupata queryParam1", queryParam1);
        this.apiService.specialBases(queryParam1).subscribe(res1 => {
          this.runData = res1;
          console.log("backupata", this.runData);
        },

          error => {
            console.log("backupataErr", error);
          });
        this.onRefresh()
      }
    })
  }

  onDelete() {
    const dialogRef = this.dialog.open(AlertConfirmationComponent, {
      disableClose: true,
      width: '550px',
      data: { 'display': this.utilService.getTranslateName(`REALIGNMENT.DELETE_CONF`) }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        if (this.selectedRow.etat.toLocaleUpperCase() !== "PENDING") {
          this.toastyService.warning(this.utilService.getTranslateName(`ERROR.DELETE_ALERT`));
          return;
        } else {
          const queryParam1 = { "input": `${this.profile['login']}@LIB@REA_DEL@${this.selectedRow.table}§${this.selectedRow.demande}@@`, "region": this.getFavoriteRegion() };

          this.apiService.specialBases(queryParam1).subscribe(res1 => {
            this.runData = res1;
            console.log("deletedata", this.runData);
            this.onRefresh();
          },

            error => {
              console.log("deletedataErr", error);
            });
        }
      }
    })
  }

  onLog() {
    // debugger
    if (this.selectedRow) {
      if (this.selectedRow.etat !== "OK" && this.selectedRow.etat !== "KO") {
        this.toastyService.warning(this.utilService.getTranslateName(`ERROR.LOG_ERROR`));
        return;
      }
      const queryParam2 = {
        "inputLists": [
          `FileName@${this.selectedRow.table}§${this.selectedRow.demande}.log`,
          `IPN@${this.profile['login']}`,
          `Table@${this.selectedRow.table}`,
          "Action@DOWNLOAD_FILE",
          "FileType@REA_LOG"
        ],
        // "input": "REALIGNMENT - Download log file",
        "region": this.getFavoriteRegion()
      }

      this.apiService.writingLogs(queryParam2).subscribe(res => {
        // this.fileData = res;
        console.log("writingLogssss", res);
        if (res.status === 200) {

          var tempFile = window.location.protocol + '//' + window.location.hostname + "/pil-api-s3/downloadfile/" + res.fileName;
          // this.downloadFileNew(res.fileName, window.location.protocol + '//' + window.location.hostname + "/pil-api-s3/downloadfile/")
          window.open(tempFile);
        }
        // error => {
        //   console.log("savedata", error);
        // }
      })
    }

  }
}
